var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.create)?_c('h3',[_vm._v(" "+_vm._s(_vm.$t("settings_new_verification_flow_basic_info_title"))+" ")]):_vm._e(),_c('div',{staticClass:"section__wrapper"},[_c('div',{staticClass:"fields-group auto-width vertical vertical"},[(!_vm.create && _vm.companyApp.flow_type !== 'CDP')?_c('div',{staticClass:"app-overview-img__wrapper"},[_c('div',{staticClass:"fields-group__header"},[_vm._v(" "+_vm._s(_vm.$t("verification_flow_settings_verification_flow_info_app_logo"))+" ")]),_c('v-image',{staticClass:"logo",class:{
            'view-field disabled': _vm.$store.state.userRole === 'REVIEWER',
          },attrs:{"loading":_vm.loadingLogo,"placeholder":_vm.$t('settings_company_info_load_logo'),"details":_vm.$t(
              'verification_flow_settings_verification_flow_info_logo_description'
            )},on:{"changeFile":function($event){return _vm.$emit('updateCompanyAppLogo', $event.file)}},model:{value:(_vm.localLogoUrl),callback:function ($$v) {_vm.localLogoUrl=$$v},expression:"localLogoUrl"}})],1):_vm._e(),_c('div',[_c('v-text-field',{class:{
            'view-field': _vm.$store.state.userRole === 'REVIEWER',
          },attrs:{"label":_vm.$t('verification_flow_settings_verification_flow_info_name'),"placeholder":_vm.$store.state.company && _vm.$store.state.company.legal_name},model:{value:(_vm.nameComputed),callback:function ($$v) {_vm.nameComputed=$$v},expression:"nameComputed"}})],1),_c('div',[_c('v-text-field',{class:{
            'view-field': _vm.$store.state.userRole === 'REVIEWER',
          },attrs:{"label":_vm.$t('verification_flow_settings_verification_flow_info_alias'),"placeholder":_vm.verificationFlowType +
            ' ' +
            _vm.$t(
              'verification_flow_settings_verification_flow_info_alias_example'
            )},model:{value:(_vm.aliasComputed),callback:function ($$v) {_vm.aliasComputed=$$v},expression:"aliasComputed"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }