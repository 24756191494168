<template>
  <div
    class="tab outlined progress-tab"
    @click="$emit('onActiveTabChange', activeTabName)"
    :class="[{ active: activeTabName === checkIfIsSelected }, status]"
  >
    <slot />
    <div class="progress-tab__bar">
      <div class="progress-tab__bar__rail" :class="status" />
      <div
        class="progress-tab__bar__fill"
        :style="progressBarStyle"
        :class="status"
      />
    </div>
  </div>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  name: "VProgressTab",
  props: {
    activeTabName: { required: true, type: String },
    checkIfIsSelected: { required: true, type: String },
    status: { required: true, type: String },
    percentage: { required: true, type: Number },
  },
  computed: {
    progressBarStyle() {
      return {
        width: `${this.percentage}%`,
      };
    },
  },
});
</script>

<style lang="stylus" scoped>
@import '~@/styles/global/theme.styl';

.progress-tab__bar {
  height: 5px;
  position: relative;
  margin: auto;
  margin-top: 5px;

  .progress-tab__bar__rail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    border-radius: 5px;
    background-color: $color-grey--lighten-3;
    color: #fff;

    &.completed {
      border-color: $color-success;
    }

    &.manual-review {
      border-color: $color-warning;
    }

    &.error {
      border-color: $color-error;
    }

    &.in-progress {
      border-color: $color-primary;
    }

    &.blocked {
      border-color: $color-grey--lighten-1;
    }
  }

  .progress-tab__bar__fill {
    float: left;
    height: 100%;
    border-radius: 5px;
    transition: width 0.6s ease-out;
    box-shadow: 0px 0px 1px 0px;
    color: #fff;

    &.completed {
      background-color: $color-success;
      border-color: $color-success;
    }

    &.manual-review {
      background-color: $color-warning;
      border-color: $color-warning;
    }

    &.error {
      background-color: $color-error;
      border-color: $color-error;
    }

    &.in-progress {
      background-color: $color-primary;
      border-color: $color-primary;
    }

    &.blocked {
      background-color: $color-grey--lighten-1;
      border-color: $color-grey--lighten-1;
    }
  }
}
</style>

