











































































































import KYBService from "@/api/kyb/kybService";
import Vue from "vue";

export default Vue.extend({
  components: {},
  name: "KYBSanctionsChecks",
  props: {
    sanctions: { required: true, type: Object },
    kybCompanyId: { required: true, type: String },
  },
  data() {
    return {};
  },
  computed: {
    bannerClass() {
      return {
        error: this.sanctions.status === "CONFIRMED",
        success: this.sanctions.status === "IGNORED",
      };
    },
    bannerMessage() {
      const manualReviewBannerMessage =
        "companies_detail_sanctions_check_description_manual_review";
      const confirmedBannerMessage =
        "companies_detail_sanctions_check_description_confirmed";
      const ignoredBannerMessage =
        "companies_detail_sanctions_check_description_ignored";
      return (
        (["PENDING_MANUAL_REVIEW"].includes(this.sanctions.status)
          ? manualReviewBannerMessage
          : ["CONFIRMED"].includes(this.sanctions.status)
          ? confirmedBannerMessage
          : ignoredBannerMessage) || ""
      );
    },
  },
  methods: {
    async ignoreOrConfirmSanction(action: string) {
      try {
        await KYBService.ignoreOrConfirmSanction(
          this.kybCompanyId,
          this.sanctions.id,
          action
        );

        this.$emit("getKYBCompanyDetail");
      } catch {
        this.$root.$emit("v-toast", {
          localizedKey: "unsucessful_feedback",
          type: "error-toast",
        });
      }
    },
  },
});
