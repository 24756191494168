
































































































































































































































import Vue from "vue";
import CompaniesService from "@/api/companies/companiesService";
import { mutationsT } from "@/state/mutations";
import AdminsTable from "@/components/admins/AdminsTable.vue";
import SettingsLanguages from "./components/SettingsLanguages.vue";
import AppDetail from "../verification-flow-settings/AppDetail/AppDetail.vue";
import DeleteVerificationFlowModal from "../verification-flow-settings/DeleteVerificationFlowModal.vue";
import DuplicateVerificationFlowModal from "./components/DuplicateVerificationFlowModal.vue";
import VTab from "@/components/vuemarc-ui-kit/tabs/VTab.vue";
import SettingsVerificationFlowsList from "./components/SettingsVerificationFlowsList.vue";
import { Admin } from "@/components/admins/components/AdminDetail.vue";

export default Vue.extend({
  name: "Settings",
  components: {
    AdminsTable,
    SettingsLanguages,
    AppDetail,
    DeleteVerificationFlowModal,
    DuplicateVerificationFlowModal,
    VTab,
    SettingsVerificationFlowsList,
  },
  props: {},
  watch: {},
  computed: {
    companyLogoUrl: {
      get(): string {
        return this.$store.state.company.logo_url;
      },
      set() {},
    },
  },
  data() {
    return {
      loading: false,
      loadingName: false,
      loadingLogo: false,
      activeTabName:
        this.$store.state.userRole !== "REVIEWER"
          ? "company-info"
          : "languages",
      activeCreatingVerificationFlowTab: "verification-flow-info",
      companyEdited: {
        name: "",
        legalName: "",
      },
      companyOwnersList: this.$store.state.company?.owners || [],
      companyPlanName: "",
      pricingOptions: [
        {
          value: "free_tier",
          text: this.$t("settings_pricing_type_free_tier"),
        },
        {
          value: "business",
          text: this.$t("settings_pricing_type_business"),
        },
      ],
    };
  },
  created() {
    this.companyEdited.name = this.$store.state.company?.name;
    this.companyEdited.legalName = this.$store.state.company?.legal_name;
    this.companyPlanName = this.$store.state.company?.plan_name;
  },
  methods: {
    async updateCompany(data: any, loading?: boolean) {
      loading = true;
      await CompaniesService.patchCompany(this.$store.state.company.id, data);
      await this.loadCompany();
      this.$root.$emit("v-toast", {
        localizedKey: "successful_changes_saved_feedback",
        type: "success-toast",
      });
      loading = false;
    },

    async loadCompany() {
      this.loading = true;
      this.companyEdited = {
        name: this.$store.state.company?.name,
        legalName: this.$store.state.company?.legal_name,
      };
      await this.getCompanyInfo();
      this.loading = false;
    },

    async updateCompanyLogo(file_url: any) {
      this.loadingLogo = true;
      var formdata = new FormData();
      formdata.append(
        "logo",
        file_url,
        `logo_c_${this.$store.state.company.id}.jpg`
      );
      await CompaniesService.patchCompanyLogo(
        this.$store.state.company.id,
        formdata
      );
      const companies = await CompaniesService.getCompanies();
      this.$store.commit(mutationsT.UPDATE_COMPANIES, companies.data.results);
      this.loadingLogo = false;
    },

    async getCompanyInfo() {
      this.loading = true;
      const res = await CompaniesService.getCompany(
        this.$store.state.company.id
      );
      this.companyOwnersList = res.data.owners;
      this.$store.commit(mutationsT.UPDATE_COMPANY, res.data);
      this.loading = false;
    },
  },
  async mounted() {
    await this.loadCompany();
    this.$root.$on("companyIdChanged", this.loadCompany);
  },
});
