export function animateNumber(
  obj: { amount: number },
  target = 0,
  start = 0,
  duration = 700
) {

   // If equal values, stop here.
  const range = target - obj.amount; // Get the range
  let curr = obj.amount; // Set current at start position

  const timeStart = Date.now();

  const loop = () => {
    if (obj.amount === target) return;
    let elaps = Date.now() - timeStart;
    if (elaps > duration) elaps = duration; // Stop the loop
    const frac = elaps / duration; // Get the time fraction
    const step = frac * range; // Calculate the value step
    curr = start + step; // Increment or Decrement current value
    obj.amount = Math.trunc(curr); // Apply to UI as integer
    if (elaps < duration) requestAnimationFrame(loop); // Loop
  };

  requestAnimationFrame(loop); // Start the loop!
  window.requestAnimationFrame(loop);
}
