import { formatUri, poll } from "@/api";
import { HTTP } from "@/lib/http/api";
import { VERIFICATION_STATUS_MAP, VerificationStatusSimple } from "@/models/verificationStatus";

export default class ResourcesService {
  static async postResource(data: any) {
    if (!data) throw { error: "No data Provided" };
    const res = await HTTP.post(formatUri`v1/resources/`, data);
    return res;
  }

  static async getResourcePollForOCR(paId: string) {
    return await poll(
      HTTP.get,
      formatUri`v1/resources/${paId}`,
      (res: any) => {
        return (
          (res.data.ocr_status &&
            res.data.ocr_status !== VERIFICATION_STATUS_MAP.PENDING &&
            res.data.ocr_status !== VERIFICATION_STATUS_MAP.RUNNING) ||
          res.data.verification_status === VERIFICATION_STATUS_MAP.VERIFICATION_ERROR ||
          res.data.verification_status === VERIFICATION_STATUS_MAP.ERROR
        );
      },
      2000,
      15
    );
  }

  static async getResourcePollForVerification(paId: string) {
    return await poll(
      HTTP.get,
      formatUri`v1/resources/${paId}`,
      (res: any) => {
        return (
          res.data.verification_status &&
          res.data.verification_status !== VERIFICATION_STATUS_MAP.PENDING &&
          res.data.verification_status !== VERIFICATION_STATUS_MAP.RUNNING
        );
      },
      2000,
      15
    );
  }

  static async getResourcePollForOCRAndVerification(paId: string) {
    return await poll(
      HTTP.get,
      formatUri`v1/resources/${paId}`,
      (res: any) => {
        if (
          // FINAL STATES:
          res.data.status === VERIFICATION_STATUS_MAP.SUCCESS ||
          res.data.status === VERIFICATION_STATUS_MAP.ERROR ||
          res.data.status === VERIFICATION_STATUS_MAP.VERIFICATION_ERROR ||
          res.data.status === VERIFICATION_STATUS_MAP.MANUAL_REVIEW
        ) {
          return true;
        }
        return false;
      },
      2000,
      15
    );
  }

  static async getResourcesPendingPoll() {
    return await poll(
      HTTP.get,
      formatUri`v1/users/self/resources/`,
      (res: any) => {
        for (const r of res.data) {
          if (r.status === VERIFICATION_STATUS_MAP.PENDING) {
            return false;
          }
        }
        return true;
      },
      2000,
      40
    );
  }

  static async getResources(userId = "self") {
    const res = await HTTP.get(formatUri`v1/users/${userId}/resources/`);
    return res;
  }

  static async patchResourceToManualReview(paId: string) {
    const res = await HTTP.patch(formatUri`v1/resources/${paId}`, {
      manual_review_status: "PENDING"
    });
    return res;
  }

  static async patchResourceStatus(paId: string, status: VerificationStatusSimple ) {
    const res = await HTTP.patch(formatUri`v1/processing-attempts/${paId}`, {
      manual_review_status: status
    });
    return res;
  }

  static async getResourceFiles(paId: string) {
    const res = await HTTP.get(formatUri`v1/processing-attempts/${paId}/files/`);
    return res;
  }
}
