






































































































































import {
  VerificationAPIType,
  KYC_VERIFICATIONS_TYPES_VIEW_MAP,
} from "@/models/verificationsTypes";
import Vue from "vue";

export default Vue.extend({
  components: {},
  name: "KYCDocumentTypes",
  props: {
    cappFilteredSyncVerifications: {
      required: true,
      type: Array as () => Array<any>,
    },
    companyAppPermissions: { required: true, type: Array as () => Array<any> },
    cappFilteredSyncVerificationsInput: {
      required: true,
      type: Array as () => Array<any>,
    },
  },
  data() {
    return {
      modifyVerifications: false,
      KYC_VERIFICATIONS_TYPES_VIEW_MAP: KYC_VERIFICATIONS_TYPES_VIEW_MAP,
    };
  },
  computed: {
    verificationOptions(): Array<any> {
      const verifications = [] as any;
      outerloop: for (let key of Object.keys(
        KYC_VERIFICATIONS_TYPES_VIEW_MAP
      )) {
        if (key === "UPOA") continue outerloop;
        for (let cappVerifGroup of this.cappFilteredSyncVerifications) {
          if (cappVerifGroup.includes(key as VerificationAPIType))
            continue outerloop;
        }
        verifications.push({
          value: key,
          text: this.$t(key.toLowerCase()),
        });
      }
      return verifications;
    },
    areAllOptionsSelected(): boolean {
      let totalOptionsSelected = 0;
      for (const verificationGroup of this.cappFilteredSyncVerifications) {
        totalOptionsSelected += verificationGroup.length;
      }
      if (
        Object.keys(KYC_VERIFICATIONS_TYPES_VIEW_MAP).length ===
        totalOptionsSelected
      )
        return true;
      return false;
    },
    option(): Array<string> {
      const hasUPOA = this.cappFilteredSyncVerifications.some(
        (permissionsGroup: Array<string>) => permissionsGroup.includes("UPOA")
      );
      return hasUPOA ? [this.verificationOptions[0].value] : ["UPOA"];
    },
  },
});
