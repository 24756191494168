

































import { Component, Mixins, Prop } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";

@Component({
  inheritAttrs: false,
})
export default class VImage extends Mixins(VInputMixin) {
  @Prop() loading?: boolean;
  @Prop() placeholder?: string;
  file?: Blob;

  set inputValue(val: string | number | undefined) {
    this.$emit("change", val);
  }

  get inputValue() {
    return this.value;
  }

  onFileChange(e: any) {
    if (!e) return;
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.file = files[0];
    this.createImage(files[0]);
  }

  createImage(file: File) {
    // let image = new Image();
    const reader = new FileReader();

    reader.onload = (e: any) => {
      // this.base64 = e.target.result;
      this.inputValue = e.target.result;
      this.$emit("changeFile", { file });
    };
    reader.readAsDataURL(file);
  }

  get listeners() {
    return Object.assign({}, this.$listeners, {
      // This ensures that the component works with v-model
      change: (event: any) => {
        this.onFileChange(event);
        this.$emit("change", event.target.value);
      },
    });
  }
}
