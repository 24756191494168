







































import FilesService from "@/api/files/filesService";
import Vue from "vue";

export default Vue.extend({
  name: "KYBDashboardUploadModal",
  props: {
    companyHasLegalAcceptance: { required: true, type: Boolean },
    companyHasExtraDocuments: { required: true, type: Boolean },
    processExtraDocuments: { required: true, type: Function },
    handleError: { required: true, type: Function },
    showUploadDocumentModal: { required: true, type: Boolean },
    loadingProp: { required: true, type: Boolean },
    extraDocuments: { required: true, type: Array },
  },
  data() {
    return {
      extraDocumentNameValue: "",
      verificationFlowExtraDocumentsParsed: [] as any,
      loading: false,
    };
  },
  methods: {
    onCloseKYBDashboardUploadModal() {
      this.$emit("showeDashboadUploadModal", false);
    },
    async postFile(file: any) {
      const data = {
        type: "DYNAMIC",
        file_name: file.name,
        file: file,
        dynamic_file_type_id: this.getIdOfExtraDocumentsOptions(
          this.extraDocumentNameValue
        ),
      };
      return await FilesService.postFile(data);
    },
    getIdOfExtraDocumentsOptions(val: string) {
      const selectedOption = this.verificationFlowExtraDocumentsParsed.filter(
        (option: any) => option.value === val
      );
      return selectedOption[0].id;
    },
    getCompanyExtraDocuments() {
      this.verificationFlowExtraDocumentsParsed =
        this.$store.state.verificationFlow.extra_documents.map(
          (extraDocument: any) => ({
            value: extraDocument.name,
            text: this.$t(extraDocument.name),
            id: extraDocument.id,
          })
        );

      this.extraDocumentNameValue =
        this.verificationFlowExtraDocumentsParsed[0]?.value || "";
    },
    async uploadFiles(files: any) {
      try {
        const extraDocumentsFiles = {
          files: this.extraDocuments || [],
        };

        this.loading = true;

        if (files.length) {
          for (const file of files) {
            const postFileResponse = await this.postFile(file);
            const resGet = await FilesService.getFile(
              postFileResponse.data.file_id
            );

            if (resGet.data) {
              extraDocumentsFiles.files.push(resGet.data);
            }
          }
        }
        this.onCloseKYBDashboardUploadModal();

        await this.processExtraDocuments(extraDocumentsFiles.files);
      } catch (error) {
        this.handleError();
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getCompanyExtraDocuments();
  },
});
