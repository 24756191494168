var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details__wrapper"},[_c('div',{staticClass:"info__wrapper"},[_c('PADetailVerificationInfo',{attrs:{"resourceDetail":_vm.resourceDetail,"statusOptions":_vm.statusOptions},on:{"onUpdateResource":function($event){return _vm.$emit('onUpdateResource', _vm.resourceDetail)}}}),_c('h3',[_vm._v(_vm._s(_vm.$t("users_detail_checks_hits_title")))]),(_vm.hits && _vm.hits.length > 0)?_c('div',{staticClass:"aml-results"},_vm._l((_vm.hits),function(result,i){return _c('div',{key:((result.entity_number) + "-" + i),staticClass:"aml-results__result context"},[_c('h5',{staticClass:"aml-results__result__header"},[_vm._v(" "+_vm._s(_vm.$t("users_detail_checks_hits_number", { number: i + 1 }))+" "),(result['score'])?_c('span',{staticClass:"capitalized"},[_c('span',{staticClass:"key"},[_vm._v(" "+_vm._s(_vm.$t("users_detail_checks_hits_detail_score"))+": ")]),_vm._v(" "+_vm._s(_vm._f("decimals")(result["score"],2))+" ")]):(result['confidence_score'])?_c('span',{staticClass:"capitalized"},[_c('span',{staticClass:"key"},[_vm._v(" "+_vm._s(_vm.$t("users_detail_checks_hits_detail_score"))+": ")]),_vm._v(" "+_vm._s(_vm._f("decimals")(result["confidence_score"],2))+" ")]):_vm._e()]),_c('table',_vm._l((Object.keys(result)),function(key,j){return _c('tr',{key:(key + "-" + i + "-" + j),class:{
              'no-show':
                !result[key] ||
                key === 'score' ||
                key === 'confidence_score' ||
                key.toLocaleLowerCase().indexOf('hash') > -1 ||
                key.toLocaleLowerCase().indexOf('identifier') > -1,
            }},[(result[key] && key === 'data_source')?[(result[key].name)?_c('th',{staticClass:"key"},[_vm._v(" "+_vm._s(_vm.$t(key))+" ")]):_vm._e(),_c('td',{staticClass:"capitalized"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("clean_cap")(_vm._f("clean_array")(result[key].name)))+" ")])])]:(
                result[key] && key.toLocaleLowerCase().indexOf('url') > -1
              )?[_c('th',{staticClass:"key"},[_vm._v(_vm._s(_vm.$t(key)))]),_c('td',{staticClass:"capitalized"},[_c('a',{attrs:{"href":result[key],"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("link")))])])]:(typeof result[key] === 'object')?[_c('th',{staticClass:"key"},[_vm._v(_vm._s(_vm.$t(key)))]),_c('td',{staticClass:"capitalized"},[_c('ul',{staticClass:"results-list"},_vm._l((result[key]),function(value,subKey){return _c('li',{key:subKey},[(subKey)?_c('span',{staticClass:"key"},[_vm._v(" "+_vm._s(_vm._f("clean_cap")(subKey))+": ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("clean_cap")(_vm._f("clean_array")(value))))])])}),0)])]:(result[key])?[_c('th',{staticClass:"key"},[_vm._v(_vm._s(_vm.$t(key)))]),_c('td',{staticClass:"capitalized"},[_c('span',[_vm._v(_vm._s(_vm._f("clean_cap")(_vm._f("clean_array")(result[key]))))])])]:_vm._e()],2)}),0)])}),0):(
        _vm.resourceDetail.status === 'PENDING' ||
        _vm.resourceDetail.status === 'BLOCKED'
      )?_c('div',{staticClass:"context"},[_vm._v(" "+_vm._s(_vm.$t("not_processed_default"))+" ")]):_c('div',{staticClass:"context"},[_vm._v(" "+_vm._s(_vm.$t("users_detail_checks_hits_none"))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }