import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import i18n from "./i18n";
import { store } from "./state/store";
import * as VueGoogleMaps from "vue2-google-maps";

import "./registerServiceWorker";
import "./styles/index.styl";

// Font awesome styles
import "./styles/fa-scss/fontawesome.scss";
import "./styles/fa-scss/solid.scss";
import "./styles/fa-scss/regular.scss";
import "./styles/fa-scss/duotone.scss";

import "./components";
import "./filters";

import "./firebase.config";
import "./plugins/gtag.ts";
import "./sentry.config";

// TODO: Vue3 Migration
// https://stackoverflow.com/questions/60491988/how-to-access-vue-instance-in-vuex/67229658#67229658

Date.prototype.addDays = function(days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Vue.directive("click-outside", {
  bind: function(el: any, binding: any, vnode: any) {
    el.clickOutsideEvent = function(event: any) {
      // here I check that click was outside the el and his children
      if (
        !(
          el == event.target ||
          el.contains(event.target) ||
          event.target.closest(".tooltip.popover")
        )
      ) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function(el: any) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  data: () => ({
    target: process.env.VUE_APP_TARGET,
  }),
  store,
  i18n,
}).$mount("#app");

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_API_KEY,
    },
})