























import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";

@Component({
  inheritAttrs: false,
})
export default class VInputNumber extends Mixins(VInputMixin) {
  @Prop({ default: 0, type: Number })
  fixedDecimals?: number;

  numberRegex = /^[-+]*[0-9]+\.?[0-9]*$/;

  formattedValue = this.inputValue;

  set inputValue(val: string | number | undefined) {
    if (this.isValidNumber(val)) {
      this.debounceInput(() => {
        this.$emit("change", val);
      });
    } else {
      this.$emit("change", this.inputValue);
    }
  }

  get inputValue() {
    return this.value;
  }

  @Watch("inputValue", { immediate: true })
  onInputValueChanged(newValue: any) {
    if (!!this.fixedDecimals) {
      if (newValue && newValue !== undefined && newValue !== null) {
        this.formattedValue = parseFloat(newValue.toString()).toFixed(
          this.fixedDecimals
        );
      } else {
        this.formattedValue = newValue;
      }
    } else {
      this.formattedValue = newValue;
    }
  }

  get listeners() {
    return Object.assign({}, this.$listeners, {
      change: (event: any) => {
        const value = event.target.value;
        if (this.isValidNumber(value)) {
          this.$emit("change", parseFloat(value));
        } else if (value || value === 0) {
          this.$emit("change", this.inputValue);
          event.target.value = this.inputValue;
        } else {
          this.$emit("change", null);
        }
      },
    });
  }

  onInput(event: any) {
    const value = event.target.value;
    if (this.isValidNumber(value)) {
      this.formattedValue = value;
      this.$emit("input", value);
    } else {
      if (value.startsWith("-") || value.startsWith("+")) {
        const cleanValue = value.substring(1).replace(/[^0-9.]/g, "");
        this.formattedValue = value[0] + "" + cleanValue;
        this.$emit("input", value[0] + "" + cleanValue);
      } else {
        const cleanValue = value.replace(/[^0-9.]/g, "");
        this.formattedValue = cleanValue;
        this.$emit("input", cleanValue);
      }
    }
  }

  isValidNumber(val: string | number | undefined): boolean {
    return (
      typeof val === "number" ||
      (typeof val === "string" && this.numberRegex.test(val))
    );
  }
}
