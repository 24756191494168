















































































































import Vue from "vue";
import CompaniesService from "@/api/companies/companiesService";
import { VERIFICATIONS_TYPES_VIEW_MAP } from "@/models/verificationsTypes";
import { mutationsT } from "@/state/mutations";
import DeleteVerificationFlowModal from "../DeleteVerificationFlowModal.vue";
import VerificationFlowType from "./components/VerificationFlowType.vue";
import AppDetailKYC from "./components/KYC/AppDetailKYC.vue";
import AppDetailKYB from "./components/KYB/AppDetailKYB.vue";
import { sendSentryError } from "@/lib/utils";

export default Vue.extend({
  name: "AppDetail",
  components: {
    DeleteVerificationFlowModal,
    VerificationFlowType,
    AppDetailKYC,
    AppDetailKYB,
  },
  props: {
    verificationFlowProp: { required: false, type: Object },
    activeTabName: { required: false, type: String, default: "" },
    create: { required: false, type: Boolean, default: false },
    step: { required: false, type: String, default: "" },
  },
  data() {
    return {
      VERIFICATIONS_TYPES_VIEW_MAP: VERIFICATIONS_TYPES_VIEW_MAP,
      capp: null as any,
      loading: false,
      showDeleteDetail: false,
      logoFileUrl: null,
      primaryColorHexFeedback: "",
      recurrentAMLOptions: [
        {
          text: this.$t(
            "verification_flow_settings_verification_requirements_document_types_AML_frequency_type_2"
          ),
          value: "expires15d",
        },
        {
          text: this.$t(
            "verification_flow_settings_verification_requirements_document_types_AML_frequency_type_3"
          ),
          value: "expires30d",
        },
        {
          text: this.$t(
            "verification_flow_settings_verification_requirements_document_types_AML_frequency_type_4"
          ),
          value: "expires1y",
        },
        {
          text: this.$t(
            "verification_flow_settings_verification_requirements_document_types_AML_frequency_type_1"
          ),
          value: "Not Recurrent",
        },
      ],
      stepValue: this.step,
      verificationFlowType:
        (this.verificationFlowProp && this.verificationFlowProp.flow_type) ||
        "",
      verificationFlowKYBData: {} as any,
      verificationFlowKYCData: {} as any,
    };
  },
  watch: {
    verificationFlowProp() {
      if (
        this.verificationFlowProp &&
        this.verificationFlowProp.app_id !== this.capp.app_id
      )
        this.updateCappFromProp();
    },
    verificationFlowType() {
      this.updateCappFromProp();
    },
    verificationFlowKYBData: {
      handler() {
        this.$emit("onUpdateVerificationFlow", this.verificationFlowKYBData);
      },
      deep: true,
    },
    verificationFlowKYCData: {
      handler() {
        this.$emit("onUpdateVerificationFlow", this.verificationFlowKYCData);
      },
      deep: true,
    },
  },
  methods: {
    setStepValue(value: string) {
      this.stepValue = value;
    },
    companyAppLogoFormDataObject(file_url: any) {
      const formdata = new FormData();
      const cappId = this.capp.app_id;
      formdata.append("logo", file_url, `logo_capp_${cappId}.jpg`);

      return formdata;
    },
    pushRequirement(array: any[], condition: boolean, value: any): void {
      if (condition) {
        array.push(value);
      }
    },
    async updateCompanyAppLogo(file_url: any) {
      this.loading = true;
      const cappId = this.capp.app_id;
      const formDataLogo = this.companyAppLogoFormDataObject(file_url);
      await CompaniesService.patchCompanyAppLogo(cappId, formDataLogo);
      const companies = await CompaniesService.getCompanies();
      this.$store.commit(mutationsT.UPDATE_COMPANIES, companies.data.results);
      this.$emit("onSaved", {
        loading: this.loading,
        capp: this.capp,
      });
      this.loading = false;
    },
    async updateCapp() {
      if (!this.capp) return;
      this.loading = true;
      try {
        const cappData =
          this.verificationFlowType === "KYB"
            ? this.verificationFlowKYBData
            : this.verificationFlowKYCData;
        if (this.create) {
          const created_capp = await CompaniesService.postCompanyApp(cappData);
          this.$root.$emit(
            "amplitude",
            "AddANewVerificationFlow - CreateFlow Clicked"
          );
          this.capp = created_capp.data;
          if (this.logoFileUrl) this.updateCompanyAppLogo(this.logoFileUrl);
          this.$root.$emit("v-toast", {
            text: this.$t("successful_create_verification_flow_feedback", {
              verificationFlowName: this.capp.name,
            }),
            type: "success-toast",
          });
          const companies = await CompaniesService.getCompanies();
          this.$store.commit(
            mutationsT.UPDATE_COMPANIES,
            companies.data.results
          );
          this.$emit("onCreatingCompany", "verification-flows");
        } else {
          try {
            const updatedVerificationFlow =
              await CompaniesService.patchCompanyApp(
                this.capp.app_id,
                cappData
              );
            this.$store.commit(
              mutationsT.UPDATE_VERIFICATION_FLOW,
              updatedVerificationFlow.data
            );
            this.$emit(
              "onUpdateVerificationFlow",
              updatedVerificationFlow.data
            );
            this.$root.$emit(
              "amplitude",
              "VerificationFlowSettings - SaveFlow Clicked"
            );
            this.$root.$emit("v-toast", {
              localizedKey: "successful_changes_saved_feedback",
              type: "success-toast",
            });
          } catch (error) {
            this.$root.$emit("v-toast", {
              text:
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message,
              type: "error-toast",
            });
            this.$emit("resetDifferences");
          }
        }
      } finally {
        const company = await CompaniesService.getCompany(
          this.$store.state.company.id
        );
        this.$store.commit(mutationsT.UPDATE_COMPANY, company.data);
        const companies = await CompaniesService.getCompanies();
        this.$store.commit(mutationsT.UPDATE_COMPANIES, companies.data.results);
        this.$emit("onSaved", {
          loading: this.loading,
          capp: this.capp,
        });
        this.$emit("resetDifferences");
        this.loading = false;
      }
    },
    async updateCappFromProp() {
      if (this.verificationFlowProp) {
        if (!this.verificationFlowProp.app_id) {
          sendSentryError("AppDetail: VerificationFlowProp without app_id");
          return;
        }
        this.capp = this.verificationFlowProp;
        try {
          const res = await CompaniesService.getCompanyApp(
            this.verificationFlowProp.app_id
          );
          this.$store.commit(mutationsT.UPDATE_VERIFICATION_FLOW, res.data);
          const tempCapp: any = res.data;
          if (this.capp && this.capp.style && this.capp.style.font_url) {
            tempCapp.style.font_url_titles =
              this.capp.style.font_url.split("+")[0];
            tempCapp.style.font_url_body =
              this.capp.style.font_url.split("+")[1];
          }
          this.capp = JSON.parse(JSON.stringify(tempCapp));
        } catch (error) {
          this.$root.$emit("v-toast", {
            localizedKey: "unsuccessful_get_company_app_feedback",
            type: "error-toast",
          });
          sendSentryError(error);
        }
      } else {
        this.capp = {
          name: "",
          alias: "",
          webhook_url: "",
          custom_css: "",
          style: {
            body_font_url: "",
            title_font_url: "",
            primary_color: "",
          },
          ...(this.verificationFlowType === "KYB"
            ? {
                config: {
                  admin_permissions: [
                    ["NATIONAL_ID", "PASSPORT", "DRIVING_LICENSE"],
                  ],
                  ubo_permissions: [
                    ["NATIONAL_ID", "PASSPORT", "DRIVING_LICENSE"],
                  ],
                  ubo_discovery_enabled: false,
                  ubo_prefill_enabled: false,
                  ubo_verification_enabled: false,
                  ubo_tree_depth: 100,
                  sanctions_screening_enabled: false,
                  monitoring_notifications_enabled: false,
                  ubo_min_ownership_percentage: 0,
                },
                email_reminders_frequency: "3",
                permissions: [],
              }
            : {
                permissions: [["NATIONAL_ID", "PASSPORT", "DRIVING_LICENSE"]],
              }),
        };
      }
    },
  },
  async created() {
    await this.updateCappFromProp();
  },
});
