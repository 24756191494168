



















































































import Vue from "vue";
import VTabRadioButton from "@/components/vuemarc-ui-kit/tabs/VTabRadioButton.vue";

export default Vue.extend({
  name: "VerificationFlowType",
  components: { VTabRadioButton },
  data() {
    return {
      activeTabName: "KYC",
    };
  },
  watch: {
    activeTabName: {
      handler(newValue) {
        this.$emit("onVerificationFlowTypeChange", newValue);
      },
      immediate: true,
    },
  },
});
