





import VPlaceholder from "@/components/vuemarc-ui-kit/placeholder/VPlaceholder.vue";
import Vue from "vue";

export default Vue.extend({
  components: { VPlaceholder },
  name: "ChartSkeleton",
});
