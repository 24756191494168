





































































import RulesService from "@/api/rules/rulesService";
import VRuleOneLiner from "@/components/rules/VRuleOneLiner.vue";
import { getCountriesList } from "@/lib/utils";
import Vue from "vue";

interface IOneLinerUpdated {
  field: {
    name: string;
  };
  operator: string;
  operator_value: string;
  action: string;
  action_value: string;
}

export default Vue.extend({
  components: { VRuleOneLiner },
  name: "CreateRules",
  props: {
    companyAppId: { required: true, type: String },
  },
  data() {
    return {
      ruleOptions: {
        conditions: [],
        actions: [],
      } as any,
      loading: false,
      rule: {
        field: "COUNTRY",
      } as any,
      oneLinerUpdated: {} as IOneLinerUpdated,
    };
  },
  watch: {
    rule: {
      handler() {
        this.getOneLiner();
      },
      deep: true,
    },
  },
  methods: {
    setRulesForm() {
      this.rule = {
        field: "COUNTRY",
      };
      this.ruleOptions = {
        conditions: [],
        actions: [],
      };

      this.getRuleOptions();
    },
    getOneLiner() {
      this.oneLinerUpdated = {
        field: {
          name: this.rule.field,
        },
        operator: this.rule.operator,
        operator_value: this.rule.operator_value,
        action: this.rule.action,
        action_value: this.rule.action_value,
      };
    },
    async getRuleOptions() {
      if (this.rule.field === "COUNTRY") {
        const ruleSessionCountryValues = [
          {
            label: this.$t("rules_create_new_rule_field_title"),
            key: "field",
            value: [
              {
                text: this.$t(
                  "rules_create_new_rule_field_type_session_country"
                ),
                value: "COUNTRY",
              },
            ],
          },
          {
            label: this.$t("rules_create_new_rule_operator"),
            key: "operator",
            value: [
              {
                value: "NOT_IN_LIST",
                text: this.$t(
                  "rules_create_new_rule_operator_type_not_in_list"
                ),
              },
              {
                value: "IN_LIST",
                text: this.$t("rules_create_new_rule_operator_type_in_list"),
              },
            ],
          },
          {
            label: this.$t("rules_create_new_rule_operator_value"),
            key: "operator_value",
            value: await getCountriesList({ iso: "iso3" }),
          },
          {
            label: this.$t("rules_create_new_rule_action"),
            key: "action",
            value: [
              {
                text: this.$t(
                  "rules_create_new_rule_action_type_change_status"
                ),
                value: "SET_PA_STATUS",
              },
            ],
          },
          {
            label: this.$t("rules_create_new_rule_action_value"),
            key: "action_value",
            value: [
              {
                text: this.$t(
                  "rules_create_new_rule_action_value_type_manual_review"
                ),
                value: "VE",
              },
            ],
          },
        ];
        for (const ruleValue of ruleSessionCountryValues) {
          if (ruleValue.key.includes("action")) {
            this.ruleOptions.actions.push(ruleValue);
          } else {
            this.ruleOptions.conditions.push(ruleValue);
          }
        }
        this.rule.field_entity = "SESSION";
      }
    },
    async createRule() {
      this.loading = true;
      try {
        await RulesService.postRule(
          this.companyAppId,
          this.rule.field,
          this.rule.field_entity,
          this.rule.operator,
          this.rule.operator_value,
          this.rule.action,
          this.rule.action_value
        );
      } catch {
        this.$root.$emit("v-toast", {
          localizedKey: "unsucessful_feedback",
          type: "error-toast",
        });
      } finally {
        this.loading = false;

        this.$emit("closeCreateRulesForm");

        this.$root.$emit("v-toast", {
          localizedKey: "successful_rule_created_feedback",
          type: "success-toast",
        });

        this.$emit("getRulesUpdated");
        this.setRulesForm();

        this.$root.$emit("amplitude", "Rules - Create Rule Clicked");
      }
    },
  },
  async mounted() {
    this.getRuleOptions();
  },
  computed: {
    ruleOneLiner: function () {
      const rule = {
        field: {
          name: "COUNTRY",
        },
        operator: "NOT_IN_LIST",
        operator_value: "BRB",
        action: "SET_PA_STATUS",
        action_value: "VE",
      };
      return rule;
    },
  },
});
