











































import Vue from "vue";
import KYCRequirements from "../../KYC/KYCRequirements/KYCRequirements.vue";

export default Vue.extend({
  components: { KYCRequirements },
  name: "KYBRequirementsCompanyInfo",
  watch: {
    hasSanctionsCheck() {
      this.$emit("onHasSanctionsCheckChange", this.hasSanctionsCheck);
    },
    hasMonitoringNotifications() {
      this.$emit(
        "onHasMonitoringNotificationsChange",
        this.hasMonitoringNotifications
      );
    },
  },
  props: {
    createMode: { required: true, type: Boolean },
    verificationFlowConfig: { required: true, type: Object },
  },
  data() {
    return {
      hasSanctionsCheck:
        this.verificationFlowConfig.sanctions_screening_enabled,
      hasMonitoringNotifications:
        this.verificationFlowConfig.monitoring_notifications_enabled,
    };
  },
  methods: {},
});
