var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-modal-wrapper"},[(
      (_vm.users && _vm.users.length) ||
      (_vm.processingAttempts && _vm.processingAttempts.length)
    )?_c('v-button',{staticClass:"v-icon material-icons",attrs:{"flat":"","icon":""},nativeOn:{"click":function($event){return _vm.updateUserDetail('previousUser')}}},[_c('i',{staticClass:"fas fa-chevron-left"})]):_vm._e(),_c('div',{staticClass:"user-header-wrapper"},[(
        (_vm.userDetail && _vm.userDetail.status) ||
        (_vm.resourceDetail && _vm.resourceDetail.status)
      )?_c('i',{staticClass:"user-appended-icon",class:_vm.statusClass}):_vm._e(),_c('h1',{staticClass:"capitalized"},[_vm._v(" "+_vm._s(_vm.userName !== " null" ? _vm.userName : "-")+" ")])]),(
      (_vm.users && _vm.users.length) ||
      (_vm.processingAttempts && _vm.processingAttempts.length)
    )?_c('v-button',{staticClass:"v-icon material-icons",attrs:{"flat":"","icon":""},nativeOn:{"click":function($event){return _vm.updateUserDetail('nextUser')}}},[_c('i',{staticClass:"fas fa-chevron-right"})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }