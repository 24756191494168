























import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import {
  fieldOptions,
  LineItemKeys,
  tableValues,
} from "../../models/cdp/cdpModels";
import PADetailCDPTableGeneric from "./components/PADetailCDPTableGeneric.vue";
import PADetailCDPTableLineItems from "./components/PADetailCDPTableLineItems.vue";
import PADetailCDPInput from "./PADetailCDPInput.vue";
const VIEW_ALL_KEY = "documents_details_line_items_view_all";

export default Vue.extend({
  name: "PADetailCDPTable",
  components: {
    PADetailCDPInput,
    PADetailCDPTableGeneric,
    PADetailCDPTableLineItems,
  },
  props: {
    sectionName: { required: true, type: String },
    tableValues: {
      required: true,
      type: Object as () => tableValues,
    },
    fieldOptions: { required: true, type: Object as () => fieldOptions },
    activeLineItemsTabs: {
      required: true,
      type: Array as () => (LineItemKeys | typeof VIEW_ALL_KEY)[],
    },
  },
  data() {
    return {};
  },
  computed: {
    getAddTableRowCopy(): TranslateResult {
      if (this.sectionName === "documents_details_discounts_and_fees_title")
        return this.$t("add_discount_or_fee");
      if (this.sectionName === "documents_details_tax_title")
        return this.$t("add_tax_line");
      return this.$t("add_cpoa_row_general");
    },
    inputPopoverPlacement(): string {
      if (this.tableValues?.rows?.length > 3) return "bottom-start";
      return "right";
    },
  },
  methods: {},
});
