





































































































import Vue from "vue";
import PADetailVerificationInfo from "@/components/processing-attempts/pa-detail/components/components/PADetailVerificationInfo.vue";

export default Vue.extend({
  name: "PADetailAmlPepDetail",
  props: {
    resourceDetail: {
      required: true,
      type: Object as () => {
        cmes?: any[];
        core_module_executions?: any[];
      },
    },
    statusOptions: { required: true },
    user: { required: true },
  },
  components: { PADetailVerificationInfo },
  computed: {
    hits() {
      const cme =
        (this.resourceDetail.cmes && this.resourceDetail.cmes[0]) ||
        (this.resourceDetail.core_module_executions &&
          this.resourceDetail.core_module_executions[0]);
      if (cme && cme.output && cme.output.results) return cme.output.results;
      return [];
    },
  },
});
