var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"show_wrapper"},[_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("settings_new_verification_flow_subtitle"))+" ")]),_c('div',{staticClass:"tabs-wrapper vertical"},[_c('VTabRadioButton',{attrs:{"activeTabName":"KYC","checkIfIsSelected":_vm.activeTabName},on:{"onActiveTabChange":function($event){_vm.activeTabName = $event}}},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("settings_new_verification_flow_type_kyc")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("settings_new_verification_flow_type_kyc_description"))+" ")])])]),_c('VTabRadioButton',{class:{
        disabled:
          _vm.$store.state.company &&
          _vm.$store.state.company.plan_name === 'free_tier',
      },attrs:{"activeTabName":"KYB","checkIfIsSelected":_vm.activeTabName},on:{"onActiveTabChange":function($event){_vm.activeTabName = $event}}},[_c('div',[_c('div',{class:{
            'kyb-wrapper':
              _vm.$store.state.company &&
              _vm.$store.state.company.plan_name === 'free_tier',
          }},[_c('h3',[_vm._v(_vm._s(_vm.$t("settings_new_verification_flow_type_kyb")))]),(
              _vm.$store.state.company &&
              _vm.$store.state.company.plan_name === 'free_tier'
            )?_c('div',{staticClass:"pill-grey"},[_vm._v(" "+_vm._s(_vm.$t("only_business"))+" ")]):_vm._e()]),_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("settings_new_verification_flow_type_kyb_description"))+" ")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }