var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.KYBCompanyPrincipals &&
    _vm.KYBCompanyPrincipals.length
      ? _vm.KYBCompanyPrincipals
      : _vm.cmes),function(principalDetail,index){return _c('div',{key:index,staticClass:"info__container"},[_c('div',{staticClass:"representative-detail-item"},[_c('h4',[_vm._v(_vm._s(_vm.$t("companies_detail_representative_details_name")))]),_c('ul',{staticClass:"representative-detail-list"},[_c('li',{staticClass:"capitalized"},[_vm._v(" "+_vm._s(_vm._f("clean_cap")(_vm.KYBCompanyPrincipals && _vm.KYBCompanyPrincipals.length ? principalDetail.full_name : _vm.cmes && _vm.cmes.length && principalDetail.output && principalDetail.output.fullName ? principalDetail.output.fullName : principalDetail.output.full_name))+" ")])])]),_c('div',{staticClass:"representative-detail-item"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("companies_detail_representative_details_job_titles"))+" ")]),_c('ul',{staticClass:"representative-detail-list"},_vm._l((principalDetail.job_titles &&
          principalDetail.job_titles.length
            ? principalDetail.job_titles
            : _vm.cmes[0].output.jobTitles
            ? _vm.cmes[0].output.jobTitles
            : _vm.cmes[0].output.job_titles),function(jobTitle,i){return _c('li',{key:jobTitle[i]},[_c('span',[_vm._v(_vm._s(jobTitle.title))])])}),0)]),_c('div',{staticClass:"representative-detail-item"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t( "companies_detail_representative_details_management_responsabilites" ))+": ")]),_c('ul',{staticClass:"representative-detail-list"},_vm._l((principalDetail.management_responsibilities &&
          principalDetail.management_responsibilities.length
            ? principalDetail.management_responsibilities
            : _vm.cmes[0].output.management_responsibilities),function(managementResponsibility,i){return _c('li',{key:managementResponsibility[i]},[_c('span',[_vm._v(_vm._s(managementResponsibility.description))])])}),0)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }