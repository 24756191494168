




















































import FilesService from "@/api/files/filesService";
import Vue from "vue";

export default Vue.extend({
  name: "KYBExtraDocumentsListTabs",
  props: {
    extraDocuments: { required: true, type: Array as () => Array<any> },
    companyHasLegalAcceptance: { required: true, type: Boolean },
  },
  data() {
    return {
      activeTabName: "",
      extraDocumentsUpdated: [] as any,
      activeExtraDocument: {} as any,
      activeFile: "",
      activeDocumentType: "",
    };
  },
  watch: {
    extraDocuments: {
      handler() {
        if (this.extraDocuments.length) {
          this.activeTabName = this.extraDocuments[0].type_id;
          this.activeDocumentType = this.extraDocuments[0].dynamicFileTypeName;
          this.unifyS3UrlsAndDisplayNames();
          this.getExtraDocuments();
        }
      },
      immediate: true,
      deep: true,
    },
    activeTabName: {
      handler() {
        this.getExtraDocuments();
      },
      immediate: true,
    },
  },
  methods: {
    unifyS3UrlsAndDisplayNames() {
      this.extraDocumentsUpdated = this.extraDocuments
        .map((extraDocument, index: number) => {
          if (
            extraDocument.s3_urls.length !== extraDocument.display_names.length
          ) {
            return null;
          }

          const unifiedData = extraDocument.s3_urls.map(
            (url: string, index: number) => ({
              url,
              display_name: extraDocument.display_names[index],
              id: extraDocument.extraDocumentIds[index],
            })
          );

          return {
            type_id: extraDocument.type_id,
            unified_data: unifiedData,
          };
        })
        .filter(Boolean);
    },
    getExtraDocuments() {
      this.activeExtraDocument = this.extraDocumentsUpdated.find(
        (extraDocument: any) => extraDocument.type_id === this.activeTabName
      );
    },
    async getPdfDetailInformation(extraDocument: any) {
      this.activeFile = extraDocument.url;
      extraDocument.dynamicFileTypeName = this.activeDocumentType;
      const file = await FilesService.getDashboardFile(extraDocument.id);
      extraDocument.cmes = file.data.cmes.find(
        (cme: any) => cme.type === "FILE_EXTRACTOR"
      );
      this.$emit("openPDFViewer", extraDocument);
    },
  },
  mounted() {},
});
