






import VPlaceholder from "@/components/vuemarc-ui-kit/placeholder/VPlaceholder.vue";
import Vue from "vue";

export default Vue.extend({
  components: { VPlaceholder },
  name: "VTabSkeleton",
  props: {
    height: { required: false, default: "20px", type: String },
    heightSubtitle: { required: false, default: "20px", type: String },
    widthTitle: { required: false, default: "100px", type: String },
    widthSubTitle: { required: false, default: "50px", type: String },
  },
});
