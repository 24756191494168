



















































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";
import ClickOutside from "vue-click-outside";

@Component({
  inheritAttrs: false,
  directives: {
    ClickOutside,
  },
})
export default class VDatePicker extends Mixins(VInputMixin) {
  showCalendar = false;
  today: Date = new Date();
  viewingYear: number = new Date().getFullYear();
  viewingMonth: number = new Date().getMonth();
  selectedDate: Date | null = null;
  inputFocused = false;
  inputValue = "";

  monthsString: string[] = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  daysString: string[] = ["M", "T", "W", "T", "F", "S", "S"];

  @Prop({ default: true }) showIcon?: boolean;

  @Watch("selectedDate")
  onSelectedDateChanged(val: Date | undefined) {
    if (val instanceof Date && !isNaN(val.getTime())) {
      this.$emit("change", val.toUTCString());
    }
  }

  @Watch("inputFocused")
  onInputFocusedChanged(val: boolean) {
    if (val && this.$options.filters && this.$options.filters.date) {
      this.inputValue = this.$options.filters.date(this.value);
    }
  }

  created() {
    this.selectedDate = this.value ? new Date(this.value) : null;
  }

  inputClicked() {
    if (this.$attrs.disabled) return;
    this.showCalendar = !this.showCalendar;
    this.inputFocused = false;
  }

  get listeners() {
    return Object.assign({}, this.$listeners, {
      // This ensures that the component works with v-model
      change: (event: any) => {
        this.$emit("change", event.target.value);
      },
    });
  }

  get formattedValue() {
    return this.value ? this.$options?.filters?.date(this.value) : "-";
  }

  get computedInputValue() {
    return this.inputFocused ? this.inputValue : this.value;
  }

  set computedInputValue(newValue: string) {
    const sanitizedValue = newValue.replace(/[^0-9]/g, "");
    if (newValue.length < this.inputValue.length) {
      this.inputValue = sanitizedValue;
    } else {
      if (this.inputValue.length >= 4) {
        this.inputValue =
          sanitizedValue.slice(0, 2) +
          "/" +
          sanitizedValue.slice(2, 4) +
          "/" +
          sanitizedValue.slice(4);
      } else if (this.inputValue.length >= 2) {
        this.inputValue =
          sanitizedValue.slice(0, 2) + "/" + sanitizedValue.slice(2);
      } else this.inputValue = sanitizedValue;
    }
  }

  isDateSelected(day: number) {
    if (!this.selectedDate) return false;
    if (this.selectedDate.getDate() !== day) {
      return false;
    }
    if (this.selectedDate.getMonth() !== this.viewingMonth) {
      return false;
    }
    if (this.selectedDate.getFullYear() !== this.viewingYear) {
      return false;
    }
    return true;
  }

  get monthData() {
    const firstDay = new Date(this.viewingYear, this.viewingMonth, 1);
    const lastDay = new Date(this.viewingYear, this.viewingMonth + 1, 0);
    return {
      firstDay,
      lastDay,
      offset: firstDay.getDay() === 0 ? 7 : firstDay.getDay(),
      days: Array.from(Array(lastDay.getDate()).keys()).map(
        (d: number) => d + 1
      ),
    };
  }

  selectDate(day: number) {
    this.selectedDate = new Date(
      Date.UTC(this.viewingYear, this.viewingMonth, day)
    );
  }

  hideCalendar() {
    this.inputFocused = false;
    this.showCalendar = false;
  }

  increaseMonth() {
    if (this.viewingMonth < 11) {
      this.viewingMonth++;
    } else {
      this.viewingMonth = 0;
      this.viewingYear++;
    }
  }

  decreaseMonth() {
    if (this.viewingMonth > 0) {
      this.viewingMonth--;
    } else {
      this.viewingMonth = 11;
      this.viewingYear--;
    }
  }
}
