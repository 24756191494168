import { render, staticRenderFns } from "./KYBExtraDocumentsCompanySummary.vue?vue&type=template&id=18dfa74e&scoped=true&"
import script from "./KYBExtraDocumentsCompanySummary.vue?vue&type=script&lang=ts&"
export * from "./KYBExtraDocumentsCompanySummary.vue?vue&type=script&lang=ts&"
import style0 from "./KYBExtraDocumentsCompanySummary.vue?vue&type=style&index=0&id=18dfa74e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18dfa74e",
  null
  
)

export default component.exports