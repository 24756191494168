



























































import KYBProgressTabs from "@/views/kyb/components/KYBProgressTabs.vue";
import Vue from "vue";
import KYBCompanyInfo from "../KYBCompanyInfo.vue";
import KYBExtraDocuments from "../KYBExtraDocuments.vue";
import UBODiagram from "./UBODiagram.vue";
import UBOBasicInfoDetail from "./UBOBasicInfoDetail.vue";
import UserDetail from "@/components/users/UserDetail.vue";
import UsersService from "@/api/users/usersService";
import KYBStatusInfo from "../KYBStatusInfo.vue";

export default Vue.extend({
  name: "KYBUBOCompanyDetail",
  components: {
    KYBCompanyInfo,
    UBODiagram,
    KYBProgressTabs,
    KYBExtraDocuments,
    UserDetail,
    UBOBasicInfoDetail,
    KYBStatusInfo,
  },
  props: {
    KYBCompanyDetail: { required: true, type: Object },
    KYBCompanyDetailPrincipals: { required: true },
    countriesList: {required: true, type: Array},
  },
  data() {
    return {
      activeTabName: "companyInfo",
      showUBODetail: false,
      uboDetail: {} as any,
      loading: false,
    };
  },
  methods: {
    async getUser(userid: string) {
      const user = await UsersService.getUserStatus(userid);

      return user.data;
    },
    async getRepresentative() {
      try {
        this.loading = true;
        if(!this.KYBCompanyDetail.admin || !this.KYBCompanyDetail.admin.user) return;
        this.uboDetail = await this.getUser(
          this.KYBCompanyDetail.admin.user.id
        );
        this.uboDetail.status = this.KYBCompanyDetail.admin.status;
        this.showUBODetail = true;
      } catch (error) {
        this.$root.$emit("v-toast", {
          localizedKey: "unsucessful_feedback",
          type: "error-toast",
        });
      } finally {
        this.loading = false;
      }
    },
  },
});
