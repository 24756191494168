<template id="switch-button">
  <div class="switch-button-control">
    <div
      v-if="disabledText"
      class="switch-button-label left"
      :class="{ 'disabled-text': isEnabled }"
    >
      <slot name="disable-text">{{ $t(disabledText) }}</slot>
    </div>
    <div class="switch-button" :class="{ enabled: isEnabled }" @click="toggle">
      <div class="button" />
    </div>
    <div
      v-if="enableText"
      class="switch-button-label right"
      :class="{ 'disabled-text': !isEnabled }"
    >
      <slot name="enable-text">{{ $t(enableText) }}</slot>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  name: "VSwitch",
  model: {
    prop: "isEnabled",
    event: "toggle",
  },
  props: {
    isEnabled: { type: Boolean },
    disabledText: { type: String },
    enableText: { type: String },
  },
  methods: {
    toggle() {
      this.$emit("toggle", !this.isEnabled);
    },
  },
});
</script>
<style lang="stylus" scoped>
@require '~@/styles/global/theme.styl';

.switch-button-control {
  display: flex;
  flex-direction: row;
  align-items: center;

  .switch-button {
    display: flex;
    align-items: center;
    height: 1.6em;
    width: calc(1.6em * 1.7);
    border: 2px solid $color-grey--lighten-4;
    border-radius: 1.6em;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    background-color: $color-grey--lighten-4;
    overflow: hidden;

    .button {
      height: calc(1.4em - (2 * 2px));
      width: calc(1.4em - (2 * 2px));
      border: 2px solid #fff;
      margin-left: 2px;
      border-radius: 50%;
      background: #fff;
      transition: all 0.3s ease-in-out;
      box-shadow: 5px 0px 5px 1px rgba(0, 0, 0, 0.05);
    }

    &.enabled {
      background-color: var(--primary-color);
      border: 2px solid var(--primary-color);
      box-shadow: none;

      .button {
        background: #fff;
        transform: translateX(calc(100% - (2 * 1px)));
        border: 0;
        margin-left: 3px;
      }
    }
  }

  .switch-button-label.right {
    margin-left: 10px;
  }

  .disabled-text {
    opacity: 0.5;
  }

  .switch-button-label.left {
    margin-right: 10px;
  }
}
</style>