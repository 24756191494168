






































import { Vue } from "vue-property-decorator";
import OnelineVerifications from "@/components/OnelineVerifications.vue";
import PATabs from "@/components/processing-attempts/pa-tabs/PATabs.vue";
import UserStatusInfo from "@/components/users/UserStatusInfo.vue";
import PADetail from "@/components/processing-attempts/pa-detail/PADetail.vue";
import ListModalHeader from "@/components/modals/ListModalHeader.vue";
import UserDetail from "./UserDetail.vue";

export default Vue.extend({
  name: "UserModal",
  props: {
    showResourceDetail: { required: true, type: Boolean },
    resource: { required: false, type: Object },
    user: { required: false, type: Object },
    loadingProp: { required: false, type: Boolean },
    users: { required: true },
  },
  components: {
    OnelineVerifications,
    ListModalHeader,
    PATabs,
    UserStatusInfo,
    PADetail,
    UserDetail,
  },
  watch: {
    "resourceDetail.status"(val) {
      if (this.resourceDetail.status == "MANUAL_REVIEW") {
        this.resourceDetail.status = "PENDING";
      }
    },
    showResourceDetail: {
      async handler(val) {
        if (val) {
          await this.handleResourceDetailChange(val);
        }
      },
      immediate: true,
    },
    user: {
      async handler(val) {
        if (val) {
          await this.handleResourceDetailChange(val);
        }
      },
      immediate: true,
    },
    resource: {
      async handler(val) {
        if (val) {
          await this.handleResourceDetailChange(val);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      resourceDetail: { ocr_data: {} } as any,
      palink: "",
      loading: false,
    };
  },
  methods: {
    handleResourceDetailChange(val: any) {
      if (val) {
        this.loading = true;
        this.palink = window.location.href;
        this.loading = false;
      }
    },
    updateResource(event: Event) {
      this.$emit("onUpdateResource", event);
    },
  },
});
