






























import Vue from "vue";

export default Vue.extend({
  name: "KYBExtraDocumentsCompanySummary",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
});
