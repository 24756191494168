



















































import Vue from "vue";
import PADetailVerificationInfo from "@/components/processing-attempts/pa-detail/components/components/PADetailVerificationInfo.vue";
import KYBRepresentativesList from "@/components/kyb/components/KYBRepresentativesList.vue";

export default Vue.extend({
  name: "PADetailRepresentative",
  props: {
    resourceDetail: { required: true, type: Object as () => any },
    statusOptions: { required: true },
    user: { required: false, type: Object as () => any },
    KYBCompanyPrincipals: {
      required: true,
      validator: function (value) {
        return Array.isArray(value) || value === null;
      },
    },
  },
  components: {
    PADetailVerificationInfo: PADetailVerificationInfo,
    KYBRepresentativesList: KYBRepresentativesList,
  },
  methods: {},
  computed: {
    computedUser(): any {
      return (this.user as any)?.user || this.resourceDetail.owner_user;
    },
    cmes(): any {
      return (
        (this.resourceDetail as any).cmes ||
        (this.resourceDetail as any).core_module_executions
      );
    },
  },
  data() {
    return {};
  },
});
