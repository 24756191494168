






























































































import KYBService from "@/api/kyb/kybService";
import Vue from "vue";
import KYBIgnoreStatus from "./KYBIgnoreStatus.vue";

export default Vue.extend({
  components: { KYBIgnoreStatus },
  name: "UBOBasicInfoDetail",
  props: {
    uboData: { required: true, type: Object },
  },
  data() {
    return {
      showIgnoreModal: false,
      loading: false,
    };
  },
  methods: {
    async ignoreKYBUBO() {
      try {
        this.loading = true;
        if (this.uboData && this.uboData.is_company)
          await KYBService.ignoreKYBCompany(this.uboData.id);
        else await KYBService.ignoreKYBIndividual(this.uboData.id);

        this.$emit("getKYBCompanyDetail");
      } catch {
        this.$root.$emit("v-toast", {
          localizedKey: "unsucessful_feedback",
          type: "error-toast",
        });
      } finally {
        this.loading = false;
        this.showIgnoreModal = false;

        this.$root.$emit("amplitude", "KYBCompany - Ignore KYB UBO Clicked");
      }
    },
  },
});
