






































































































































































import Vue from "vue";
import { IActiveKYBCompany } from "./KYBFormModalSearchCompany.vue";

export default Vue.extend({
  name: "KYBCompanyDetailsInfoSimplified",
  props: {
    companyInfoDetails: {
      required: true,
      default: {} as IActiveKYBCompany,
    },
    simplified: {
      required: false,
      default: false,
    },
  },
  computed: {
    registeredAddress(): string {
      if (
        !this.companyInfoDetails.registered_address ||
        !this.companyInfoDetails.registered_address.street
      )
        return "-";
      return (
        this.companyInfoDetails.registered_address.street +
        ", " +
        this.companyInfoDetails.registered_address.locality +
        ", " +
        this.companyInfoDetails.registered_address.country
      );
    },
    primaryAddress(): string {
      if (
        !this.companyInfoDetails.primary_address ||
        !this.companyInfoDetails.primary_address.street
      )
        return "-";
      return (
        this.companyInfoDetails.primary_address.street +
        ", " +
        this.companyInfoDetails.primary_address.locality +
        ", " +
        this.companyInfoDetails.primary_address.country
      );
    },
  },
  data() {
    return {};
  },
});
