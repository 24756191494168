


















import Vue from "vue";

export default Vue.extend({
  name: "SettingsLanguages",
  props: {},
  data() {
    return {
      localeLanguage: localStorage.getItem("localeLanguage") || "en",
      localeLanguageOptions: [
        { value: "en", text: this.$t("languages_dashboard_select_english") },
        { value: "es", text: this.$t("languages_dashboard_select_spanish") },
        { value: "fr", text: this.$t("languages_dashboard_select_french") },
        { value: "de", text: this.$t("languages_dashboard_select_german") },
        { value: "it", text: this.$t("languages_dashboard_select_italian") },
        { value: "pl", text: this.$t("languages_dashboard_select_polish") },
        { value: "pt", text: this.$t("languages_dashboard_select_portuguese") },
        { value: "ro", text: this.$t("languages_dashboard_select_romanian") },
        { value: "ru", text: this.$t("languages_dashboard_select_russian") },
      ],
    };
  },
  watch: {
    localeLanguage(newValue) {
      this.$i18n.locale = newValue;

      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 10);

      document.cookie = `localeLanguage=${newValue}; expires=${expirationDate.toUTCString()}; path=/`;
      localStorage.setItem("localeLanguage", newValue);
    },
  },
  methods: {},
});
