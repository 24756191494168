









import Vue from "vue";

interface Filter {
  value: string;
  query: string;
}

export default Vue.extend({
  name: "VFiltersInput",
  props: {
    filterOptions: {
      required: true,
      type: Object as () => { [key: string]: string },
    },
    placeholder: { required: false, type: String },
    header: { required: false, type: String, default: "filter_by_status" },
    queryValue: { required: false, type: String, default: "filters" },
  },
  data() {
    return {
      filtersSelected: [] as string[],
      searchParamsProp: "",
    };
  },
  computed: {
    filterOptionsParsed(): Array<Filter> {
      const filterOptionsArray: Array<Filter> = [];

      const filterQueryMap: { [key: string]: string } = {
        IN_PROGRESS: `&${this.queryValue}=PENDING`,
      };

      Object.entries(this.filterOptions).forEach(([key, value]) => {
        const queryString = `&${this.queryValue}=${value}`;
        const filterQuery = filterQueryMap[value] || queryString;

        filterOptionsArray.push({
          value: String(value),
          query: filterQuery,
        });
      });
      return filterOptionsArray;
    },
  },
  methods: {
    applyFilters() {
      const activeFilters = this.filterOptionsParsed.filter((filter) =>
        this.filtersSelected.includes(filter.value)
      );
      const query = activeFilters.flatMap((filter) => filter.query).join("");
      this.$emit("onFiltersSelectedChange", query);
    },
    onFilterSelectionChange(selectedFilters: string[]) {
      this.filtersSelected = selectedFilters;
      this.applyFilters();
    },
    extractURLValuesOfFilter(filterName: string, param: string): string[] {
      let searchParams: URLSearchParams = new URLSearchParams(param);
      if (searchParams.has(filterName)) {
        return searchParams.getAll(filterName);
      }
      return [];
    },
  },
  mounted() {
    const filtersParam = this.$route.query.filters;
    if (typeof filtersParam === "string") {
      this.filtersSelected = this.extractURLValuesOfFilter(
        this.queryValue,
        filtersParam
      );
    } else if (Array.isArray(filtersParam)) {
      this.filtersSelected = filtersParam.reduce<string[]>((acc, param) => {
        if (typeof param === "string") {
          acc.push(...this.extractURLValuesOfFilter(this.queryValue, param));
        }
        return acc;
      }, []);
    }
  },
});
