<template>
  <div>
    <div class="how-to-view-header-wrapper">
      <v-button
        flat
        icon
        class="v-icon material-icons"
        @click.native="$router.push({ name: 'home' })"
      >
        <i class="fad fa-arrow-left" />
      </v-button>
      <h1>{{ $t("overview_how_to_title") }}</h1>
    </div>
    <div class="page-body tabs-grid-wrapper readable">
      <div class="tabs-wrapper vertical">
        <router-link
          v-for="howToTab of howToTabs"
          class="router-link-tab"
          :to="{ name: howToTab.route }"
          :key="howToTab.activeTabName"
        >
          <VTab
            :activeTabName="howToTab.activeTabName"
            @onActiveTabChange="activeTabName = $event"
            :checkIfIsSelected="activeTabName"
          >
            {{ howToTab.title }}
          </VTab>
        </router-link>
      </div>
      <HowToGettingStarted v-if="activeTabName === 'getting-started'" />
      <HowToIntegration v-if="activeTabName === 'integration'" />
      <HowToOverview v-if="activeTabName === 'overview'" />
      <HowToSettings v-if="activeTabName === 'settings-description'" />
      <HowToUsersAndCompanies v-if="activeTabName === 'users-and-companies'" />
      <HowToRules v-if="activeTabName === 'rules-description'" />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VTab from "@/components/vuemarc-ui-kit/tabs/VTab.vue";
import HowToGettingStarted from "./components/HowToGettingStarted.vue";
import HowToIntegration from "./components/HowToIntegration.vue";
import HowToOverview from "./components/HowToOverview.vue";
import HowToRules from "./components/HowToRules.vue";
import HowToUsersAndCompanies from "./components/HowToUsersAndCompanies.vue";
import HowToSettings from "./components/HowToSettings.vue";

export default Vue.extend({
  components: {
    HowToGettingStarted,
    VTab,
    HowToIntegration,
    HowToOverview,
    HowToRules,
    HowToUsersAndCompanies,
    HowToSettings,
  },
  name: "HowTo",
  data() {
    return {
      activeTabName:
        this.$route.name === "how-to" ? "getting-started" : this.$route.name,
      signup_url: process.env.VUE_APP_SILT_SIGNUP_URL,
      howToTabs: [
        {
          activeTabName: "getting-started",
          title: this.$t("overview_how_to_getting_started_title"),
          route: "getting-started",
        },
        {
          activeTabName: "integration",
          title: this.$t("overview_how_to_integration_title"),
          route: "integration",
        },
        {
          activeTabName: "overview",
          title: this.$t("overview_how_to_overview_title"),
          route: "overview",
        },
        {
          activeTabName: "settings-description",
          title: this.$t("overview_how_to_settings_title"),
          route: "settings-description",
        },
        {
          activeTabName: "users-and-companies",
          title: this.$t("overview_how_to_users_and_companies_title"),
          route: "users-and-companies",
        },
        {
          activeTabName: "rules-description",
          title: this.$t("overview_how_to_rules_title"),
          route: "rules-description",
        },
      ],
    };
  },
  methods: {
    openKYCVerificationLink() {
      const url = `${this.signup_url}/?company_app_id=${this.$store.state.verificationFlow.app_id}&hide_redirect_button=true`;
      window.open(url, "_blank");
    },
  },
});
</script>

<style lang="stylus" scoped>
@import '~@/styles/global/theme.styl';

.how-to-view-header-wrapper {
  display: flex;
  align-items: center;
  gap: $spacing-md;
  margin-bottom: 16px;

  h1 {
    margin: 0;
  }
}

.tabs-wrapper {
  &.vertical {
    max-width: 210px;
  }
}
</style>
