


















import Vue from "vue";

export default Vue.extend({
  name: "HowToOverview",
  data() {
    return {};
  },
  methods: {},
});
