



























import {
  Component,
  Vue,
  Model,
  Prop,
  Mixins,
  Watch,
} from "vue-property-decorator";
import VInputMixin from "./VInputMixin";

export interface VSelectOptions {
  text: string;
  value: string;
}

@Component({
  inheritAttrs: false,
})
export default class VCheckbox extends Mixins(VInputMixin) {
  @Prop({ type: Boolean })
  disabled?: "";

  @Prop({ type: String })
  hoverMessage?: null;

  @Prop({ type: String })
  text?: "";

  @Prop({ type: String })
  popoverPlacement?: "";

  set inputValue(val: boolean | undefined) {
    this.$emit("change", val);
  }

  get inputValue() {
    return this.value;
  }
}
