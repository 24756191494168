var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PADetailCDPTableGeneric',{staticClass:"extracted-information-detail__details-values__table-table",attrs:{"tableValues":_vm.lineItemsTableValues,"fieldOptions":_vm.fieldOptions,"inputPopoverPlacement":_vm.inputPopoverPlacement,"colspan":2},on:{"addRow":function($event){return _vm.$emit('addRow')},"deleteRow":function($event){return _vm.$emit('deleteRow', $event)}},scopedSlots:_vm._u([{key:"additional-headers",fn:function(ref){
var header = ref.header;
var index = ref.index;
return [(
        ![
          'documents_details_line_items_product_details_UOM',
          'documents_details_line_items_product_details_product_name',
          'documents_details_line_items_product_details_product_description' ].includes(header)
      )?_c('th',{key:index,staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.$t(header))+" ")]):_vm._e()]}},{key:"index-row",fn:function(ref){
      var indexRow = ref.indexRow;
return [_c('span',{staticClass:"hint"},[_vm._v(" "+_vm._s(indexRow + 1)+" ")])]}},{key:"additional-fixed-column",fn:function(ref){
      var row = ref.row;
      var indexRow = ref.indexRow;
return [_c('td',{staticClass:"extracted-information-detail__details-values__table-table-fixed-column input--width__standard",attrs:{"draggable":"true"},on:{"dragstart":function($event){$event.preventDefault();
        $event.stopPropagation();}}},[_c('PADetailCDPInput',{key:'product_description',attrs:{"draggable":"true","small":"","field":row['product_description'],"index":indexRow,"fieldOptions":_vm.fieldOptions,"popoverPlacement":_vm.inputPopoverPlacement},on:{"dragstart":function($event){$event.preventDefault();
          $event.stopPropagation();},"onChange":function($event){return _vm.$emit('onChange', Object.assign({}, $event,
            {modifiedKey: 'product_description'}))}}})],1)]}}])},[_c('template',{slot:"additional-fixed-header"},[_c('th',{staticClass:"field-label extracted-information-detail__details-values__table-table-fixed-column"},[_vm._v(" "+_vm._s(_vm.$t("documents_details_line_items_product_details_product_description"))+" ")])]),_c('template',{slot:"footer-fixed-button"},[_c('td',{staticClass:"field-label extracted-information-detail__details-values__table-table-fixed-column",attrs:{"colspan":"2"}},[_c('v-button',{staticClass:"transparent small extracted-information-detail__details-values__table-table__body-add-row_button",nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('addRow')}}},[_vm._v(" "+_vm._s(_vm.$t("add_line_item"))+" ")])],1),_c('td',{attrs:{"colspan":_vm.filteredHeaders.length +
        (_vm.fieldOptions.isLastVersionAndNotLocked ? 2 : 1) -
        2}})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }