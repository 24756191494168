

















































import { Component, Vue } from "vue-property-decorator";
import AuthApi from "@/api/auth/authApi";

@Component({})
export default class Login extends Vue {
  token?: string = "";
  email = "";
  password = "";
  loading: boolean = false;
  responseMessage: string = "";

  async login() {
    if (!this.email) {
      this.responseMessage = "The email is missing";
      return;
    }
    this.loading = true;
    try {
      const res = await AuthApi.login({
        email: this.email.toLowerCase(),
        password: this.password,
      });
      await this.$router.push({ name: "home" });
    } catch (error) {
      this.responseMessage =
        (error.response && error.response.data.error_description) ||
        (error.response && error.response.data.detail) ||
        error.description;
    }
    this.loading = false;
  }
}
