































































import VTab from "@/components/vuemarc-ui-kit/tabs/VTab.vue";
import Vue from "vue";
import { PropType } from "vue/types/umd";

export default Vue.extend({
  name: "HomeRecentActivityCard",
  components: { VTab },
  props: {
    highLights: {
      required: true,
      type: [Array, Object] as PropType<any>,
    },
    loading: { required: true, type: Boolean },
  },
  computed: {
    isKYBFlow(): Boolean {
      return (
        this.$store.state.verificationFlow &&
        this.$store.state.verificationFlow.flow_type === "KYB"
      );
    },
    highLightsComputed(): Array<any> {
      if (Array.isArray(this.highLights)) return this.highLights;
      if (typeof this.highLights === "object" && this.highLights !== null) {
        const activeHighlights =
          this.highLights[this.activeTabNameFlowTypeHighlights.toLowerCase()];
        return Array.isArray(activeHighlights) ? activeHighlights : [];
      }
      return [];
    },
  },
  data() {
    return {
      activeTabNameFlowTypeHighlights: "KYC",
    };
  },
  methods: {
    clickAndNavigate(highLight: any) {
      //TODO: Backend doesn't send us verification flow id
      if (this.$store.state.companyAppId === "all") return;
      if (this.isKYBFlow) {
        this.$router.push({
          name: "companies-detail",
          params: {
            companyId: highLight.kyb_individual
              ? highLight.kyb_individual.kyb_company.id
              : highLight.kyb_company.id,
          },
        });
      } else {
        this.$router.push({
          name: "users-detail",
          params: { userid: highLight.company_app_user.public_id },
        });
      }
    },
    getIconFromStatus(highLight: any) {
      const highlightUpdtated = {
        class: "",
        status: "",
        name: "",
      };

      const kybData =
        highLight.kyb_company ||
        highLight.kyb_individual ||
        highLight.company_app_user;

      if (kybData) {
        highlightUpdtated.status = kybData.status;
        highlightUpdtated.name =
          kybData.name || kybData.id || kybData.public_id;

        switch (highlightUpdtated.status) {
          case "SUCCESS":
          case "MANUAL_SUCCESS":
            highlightUpdtated.class = `fa fa-check-circle ${highlightUpdtated.status}`;
            break;
          case "ERROR":
          case "MANUAL_ERROR":
            highlightUpdtated.class = `fa fa-times-circle ${highlightUpdtated.status}`;
            break;
          case "PENDING":
          case "MANUAL_REVIEW":
            highlightUpdtated.class = `fa fa-question-circle ${
              highlightUpdtated.status === "PENDING"
                ? "IN_PROGRESS"
                : highlightUpdtated.status
            }`;
            break;
          default:
            highlightUpdtated.class = `fa fa-question-circle ${highlightUpdtated.status}`;
        }
      }

      return highlightUpdtated;
    },
  },
});
