






































































import { Component, Vue, Watch } from "vue-property-decorator";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { TranslateResult } from "vue-i18n";

@Component({})
export default class CompanyInfo extends Vue {
  loading: boolean = false;
  responseMessage: string = "";
  companySizeOptions = [
    { value: "0-250" },
    { value: "250-1.000" },
    { value: "1.000 - 5.000" },
    { value: "5.000 - 10.000" },
    { value: "> 10.000" },
  ];
  companyData = {
    companyName: "",
    companySize: "",
    countriesOperating: "",
    phoneNumber: "",
    dealId: "",
    foundUs: "",
  };
  phoneNumberErrors = "";

  @Watch("companyData.phoneNumber")
  checkPhoneNumber(phoneNumber: string) {
    if (phoneNumber.length > 5) {
      this.phoneNumberErrors = "";
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

      if (parsedPhoneNumber) {
        if (!parsedPhoneNumber.isValid())
          this.phoneNumberErrors =
            "The phone number is invalid. Please make sure it is a valid number and in the correct format.";
      } else {
        this.phoneNumberErrors =
          "Unable to parse the phone number. Please ensure it is in the correct format.";
      }
    }
  }

  genericError() {
    this.responseMessage =
      "We could not properly create your company. Try again later.";
    this.loading = false;
    return false;
  }

  get foundUsOptions() {
    return [
      {
        value: "Google Ads",
        text: this.$t("create_new_account_source_silt_type_1"),
      },
      {
        value: "Linkedin Ads",
        text: this.$t("create_new_account_source_silt_type_2"),
      },
      {
        value: "Linkedin Post",
        text: this.$t("create_new_account_source_silt_type_3"),
      },
      {
        value: "Google Search",
        text: this.$t("create_new_account_source_silt_type_4"),
      },
      {
        value: "I was told about Silt",
        text: this.$t("create_new_account_source_silt_type_5"),
      },
      {
        value: "Others",
        text: this.$t("create_new_account_source_silt_type_6"),
      },
    ];
  }

  async signupHandler() {
    this.$emit("continue", this.companyData);
  }
}
