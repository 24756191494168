










































































































import Vue from "vue";
import KYCRequirements from "../../KYC/KYCRequirements/KYCRequirements.vue";
import { convertPercentageStringToDecimal } from "@/lib/parsers";

export default Vue.extend({
  components: { KYCRequirements },
  name: "KYBRequirementsUBO",
  watch: {
    hasUBOVerification() {
      this.$emit("onHasUBOVerificationChange", this.hasUBOVerification);
    },
    UBODiscoveryPercentage() {
      const UBODiscoveryPercentageParsed = convertPercentageStringToDecimal(
        this.UBODiscoveryPercentage
      );
      this.$emit(
        "onUBODiscoveryPercentageChange",
        UBODiscoveryPercentageParsed
      );
    },
    hasUBODiscoveryPrefill() {
      if (!this.hasUBODiscoveryPrefill)
        this.UBODiscoveryPercentage = (0 * 100).toFixed(2) + "%";
      this.$emit("onHasUBODiscoveryPrefillChange", this.hasUBODiscoveryPrefill);
    },
    UBODiscoveryDepth() {
      if (this.UBODiscoveryDepth === "all-levels")
        this.UBODiscoveryDepthValue = 100;
    },
    UBODiscoveryDepthValue() {
      if (!this.hasUBODiscoveryPrefill) this.UBODiscoveryDepthValue = 0;
      this.$emit(
        "onUBODiscoveryDepthValueChange",
        parseInt(this.UBODiscoveryDepthValue)
      );
    },
  },
  props: {
    cappFilteredSyncVerificationsInputUBO: {
      required: true,
      type: Array as () => Array<any>,
    },
    cappFilteredSyncVerificationsUBO: {
      required: true,
      type: Array as () => Array<any>,
    },
    recurrentAMLOptions: { required: true, type: Array as () => Array<any> },
    recurrentAMLUBOValue: { required: true, type: String },
    verificationFlowConfig: { required: true, type: Object },
  },
  data() {
    return {
      hasUBODiscovery: this.verificationFlowConfig.ubo_discovery_enabled,
      hasUBOVerification: this.verificationFlowConfig.ubo_verification_enabled,
      UBODiscoveryPercentage:
        (
          this.verificationFlowConfig.ubo_min_ownership_percentage * 100
        ).toFixed(2) + "%",
      hasUBODiscoveryPrefill: this.verificationFlowConfig.ubo_prefill_enabled,
      UBODiscoveryDepth:
        this.verificationFlowConfig.ubo_tree_depth === 100
          ? "all-levels"
          : "custom",
      UBODiscoveryDepthOptions: [
        {
          text: this.$t(
            "verification_requirements_kyb_config_flow_custom_levels_type_all_levels"
          ),
          value: "all-levels",
        },
        {
          text: this.$t(
            "verification_requirements_kyb_config_flow_custom_levels_type_custom"
          ),
          value: "custom",
        },
      ],
      UBODiscoveryDepthValue: this.verificationFlowConfig.ubo_tree_depth,
    };
  },
  methods: {},
});
