





















import Vue from "vue";
import { LocaleMessage, LocaleMessages, TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "v-toast",
  props: {
    type: {
      type: String,
      default: "info-toast",
      validator(value) {
        // The value must match one of these strings
        return ["info-toast", "success-toast", "error-toast"].includes(value);
      },
    },
    text: { type: String, default: "" },
    localizedKey: { type: String, default: "" },
    countdown: { type: Number, default: 3000 },
  },
  data() {
    return {
      mutableType: this.type,
      mutableText: this.text as string | LocaleMessages | TranslateResult,
      countdownInstance: this.countdown,
    };
  },
  created() {
    this.$root.$on("v-toast", (t: any) => {
      if (this.countdownInstance === 0) return;
      if (t && t.error) {
        this.mutableText = t.error;
        this.mutableType = "error-toast";
      } else if (t && t.type) {
        this.mutableText = this.$t(t.localizedKey) || t.text;
        this.mutableType = t.type;
      } else {
        this.mutableText = t;
      }

      if (this.mutableType === "error-toast") {
        this.countdownInstance = 6000;
      }
      setTimeout(() => {
        this.mutableText = "";
      }, this.countdownInstance);
    });
  },
});
