


































































import Vue from "vue";

export default Vue.extend({
  name: "VMultiselectInput",
  props: {
    options: { required: true, type: Object },
    header: { required: false, type: String, default: "" },
    multiSelectClass: { required: false, type: String, default: "" },
    placeholder: { required: false, type: String },
    optionsSelectedProp: {
      required: false,
      type: Array as () => Array<string>,
      default: [],
    },
  },
  watch: {
    optionsSelectedProp: {
      handler() {
        this.optionsSelected = this.optionsSelectedProp;
      },
      immediate: true,
    },
  },
  data() {
    return {
      active: false,
      optionsSelected: [] as string[],
      searchParamsProp: new URLSearchParams(),
    };
  },
  methods: {
    addMultipleValues(option: string) {
      if (this.optionsSelected.some((value) => value === option)) {
        const updatedValues = this.optionsSelected.filter(
          (value) => value !== option
        );
        this.optionsSelected = updatedValues;
      } else {
        this.optionsSelected.push(option);
      }
      this.$emit("selectedOptionsUpdated", this.optionsSelected);
    },
  },
  mounted() {},
});
