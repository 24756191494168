// FIREBASE
import Vue from "vue";
import firebase from "firebase/app";
import "firebase/analytics";

let firebaseConfig = {
  apiKey: "AIzaSyCPePaWpgZIErYRptCFPKW5vz_asb16ce0",
  authDomain: "silt-dashboard.firebaseapp.com",
  projectId: "silt-dashboard",
  storageBucket: "silt-dashboard.appspot.com",
  messagingSenderId: "382454801724",
  appId: "1:382454801724:web:7134f31b7dcb35a2b86a39",
  measurementId: "G-Y0Z50D5EVN"
};

if (process.env.VUE_APP_ENVIRONMENT === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyCPePaWpgZIErYRptCFPKW5vz_asb16ce0",
    authDomain: "silt-dashboard.firebaseapp.com",
    projectId: "silt-dashboard",
    storageBucket: "silt-dashboard.appspot.com",
    messagingSenderId: "382454801724",
    appId: "1:382454801724:web:7134f31b7dcb35a2b86a39",
    measurementId: "G-Y0Z50D5EVN"
  };
}

firebase.initializeApp(firebaseConfig);

Vue.prototype.$analytics = firebase.analytics();

// END FIREBASE
