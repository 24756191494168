var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',[_c('thead',{staticClass:"extracted-information-detail__details-values__table-table__header"},[_c('tr',[_c('th'),_vm._t("additional-fixed-header"),_vm._l((_vm.tableValues.headers),function(header,index){return [_vm._t("additional-headers",[_c('th',{key:index,staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.$t(header))+" ")])],{"header":header,"index":index})]})],2)]),_c('tbody',_vm._l((_vm.tableValues.rows),function(row,indexRow){return _c('tr',{key:indexRow,staticClass:"extracted-information-detail__details-values__table-table__body",attrs:{"draggable":"true"},on:{"dragstart":function($event){return _vm.onDragStart($event, indexRow)},"dragover":function($event){return _vm.onDragOver($event)},"drop":function($event){return _vm.onDrop($event, indexRow)},"dragend":_vm.onDragEnd}},[(_vm.fieldOptions.isLastVersionAndNotLocked)?_c('td',{staticClass:"extracted-information-detail__details-values__table-table__body-drag-wrapper"},[_vm._t("index-row",null,{"indexRow":indexRow}),_vm._m(0,true)],2):_c('td'),_vm._t("additional-fixed-column",null,{"row":row,"indexRow":indexRow}),_vm._l((row),function(value,key){return [(
            ![
              'product_name',
              'uom',
              'id',
              'id_next',
              'product_description' ].includes(String(key))
          )?_c('td',{key:key,class:{
            'input--width__standard': !_vm.isRowKeyANumber(String(key)),
          }},[_c('PADetailCDPInput',{key:key,attrs:{"small":"","field":row[key],"fieldType":_vm.isRowKeyANumber(String(key)) ? 'NUMBER' : 'TEXT',"index":indexRow,"fieldOptions":_vm.fieldOptions,"popoverPlacement":_vm.inputPopoverPlacement},on:{"onChange":function($event){return _vm.$emit('onChange', Object.assign({}, $event, {modifiedKey: key}))}}})],1):_vm._e()]}),(_vm.fieldOptions.isLastVersionAndNotLocked)?_c('td',[_c('v-button',{staticClass:"v-icon small extracted-information-detail__details-values__table-table__body-delete_button",attrs:{"flat":"","icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('deleteRow', indexRow)}}},[_c('i',{staticClass:"fad fa-trash"})])],1):_vm._e()],2)}),0),_c('tfoot',{staticClass:"extracted-information-detail__details-values__table-table__footer"},[(!!_vm.numberSumTotals)?_c('tr',{staticClass:"extracted-information-detail__details-values__table-table__footer-totals"},[_c('td',{staticClass:"extracted-information-detail__details-values__table-table__footer-totals-fixed hint",attrs:{"colspan":_vm.colspan}},[_vm._v(" "+_vm._s(_vm.$t("documents_details_totals_title"))+" ")]),_vm._l((_vm.numberSumTotals),function(value,key){return [(
            ![
              'product_name',
              'uom',
              'id',
              'id_next',
              'product_description' ].includes(String(key))
          )?_c('td',{key:key,staticClass:"extracted-information-detail__details-values__table-table__footer-totals-results",class:{ 'align-left': !_vm.fieldOptions.isLastVersionAndNotLocked }},[_c('span',{staticClass:"hint"},[_vm._v(_vm._s(_vm._f("decimals")(value,2)))])]):_vm._e()]})],2):_vm._e(),(_vm.fieldOptions.isLastVersionAndNotLocked)?_c('tr',[_vm._t("footer-fixed-button",[_c('td',{attrs:{"colspan":"2"}},[_c('v-button',{staticClass:"transparent small extracted-information-detail__details-values__table-table__body-add-row_button",nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('addRow')}}},[_vm._v(" "+_vm._s(_vm.addTableRowCopy)+" ")])],1)])],2):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"extracted-information-detail__details-values__table-table__body-drag-wrapper-drag-button"},[_c('i',{staticClass:"fas fa-grip-vertical"})])}]

export { render, staticRenderFns }