var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('div',{staticClass:"verification-requirements-checks-wrapper",class:{
        'verification-requirements-checks-wrapper__align-left':
          !_vm.resourcesRequirements.length,
      }},[(_vm.resourcesRequirements.length)?_c('div',{staticClass:"attempts-header in-transition"},[_c('h2',{staticClass:"verification-requirements-title"},[_vm._v(" "+_vm._s(_vm.$t("users_detail_verification_requirements_title"))+" ")]),_c('h3',{staticClass:"verification-requirements-title"},[_vm._v(" "+_vm._s(_vm.$t("users_detail_verification_requirements_title"))+" ")]),_c('div',{staticClass:"tabs-wrapper"},_vm._l((_vm.resourcesRequirements),function(requirement){return _c('div',{key:requirement,staticClass:"attempts-card",class:[
              _vm.getTabStatusClass(requirement),
              _vm.activeRequirement === requirement ? 'active' : '' ],on:{"click":function($event){return _vm.getAttemptsOfRequirement(
                requirement,
                _vm.resourcesProcessingAttempts
              )}}},[_c('i',{class:[
                'v-icon',
                'fad',
                _vm.VerificationsMap[_vm.VERIFICATIONS_TYPES_MAP[requirement]].icon,
                'large',
                _vm.getTabStatusClass(requirement),
                {
                  medium:
                    _vm.VerificationsMap[_vm.VERIFICATIONS_TYPES_MAP[requirement]]
                      .key === 'userProofOfAddress',
                } ]}),_c('h4',{staticClass:"attempts-header-type"},[_vm._v(" "+_vm._s(_vm.$t( _vm.VerificationsMap[_vm.VERIFICATIONS_TYPES_MAP[requirement]].label ))+" ")])])}),0)]):_vm._e(),(_vm.checksRequirements.length)?_c('div',{staticClass:"attempts-header in-transition"},[_c('h2',{staticClass:"verification-requirements-title"},[_vm._v(" "+_vm._s(_vm.$t("users_checks_title"))+" ")]),_c('h3',{staticClass:"verification-requirements-title"},[_vm._v(" "+_vm._s(_vm.$t("users_checks_title"))+" ")]),_c('div',{staticClass:"tabs-wrapper"},_vm._l((_vm.checksRequirements),function(requirement){return _c('div',{key:requirement,staticClass:"attempts-card attempts-card-son",class:[
              _vm.getTabStatusClass(requirement),
              _vm.activeRequirement === requirement ? 'active' : '' ],on:{"click":function($event){return _vm.getAttemptsOfRequirement(requirement, _vm.checksProcessingAttempts)}}},[_c('i',{class:[
                'v-icon',
                'fad',
                _vm.VerificationsMap[_vm.VERIFICATIONS_TYPES_MAP[requirement]].icon,
                'large',
                _vm.getTabStatusClass(requirement) ]}),_c('h4',{staticClass:"attempts-header-type"},[_vm._v(" "+_vm._s(_vm.$t( _vm.VerificationsMap[_vm.VERIFICATIONS_TYPES_MAP[requirement]].label ))+" ")])])}),0)]):_vm._e()])],_c('div',{staticClass:"tabs-attempts",class:{
      'tabs-attempts__checks': _vm.checksRequirements.includes(_vm.activeRequirement),
    }},[_c('TabAttempts',{attrs:{"checksAttemptsFiltered":_vm.checksAttemptsFiltered,"resourceDetail":_vm.resourceDetail,"resourcesAttemptsFiltered":_vm.resourcesAttemptsFiltered},on:{"getResourceDetail":function($event){return _vm.$emit('getResourceDetail', $event)}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }