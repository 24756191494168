var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'user-status-wrapper': !(
      _vm.uboData.status === 'SUCCESS' ||
      _vm.uboData.status === 'MANUAL_SUCCESS' ||
      _vm.uboData.ignored ||
      _vm.comesFromParentKYBCompany
    ),
  }},[_c('span',{staticClass:"field-label"},[_vm._v(_vm._s(_vm.$t("status_title"))+": ")]),_c('span',{staticClass:"capitalized status-pill",class:[
      _vm.isIgnored
        ? 'BLOCKED'
        : _vm.uboData.status === 'PENDING'
        ? 'IN_PROGRESS'
        : ("status_" + (_vm.uboData.status)) ]},[_vm._v(" "+_vm._s(_vm._f("status")(_vm.isIgnored ? "Ignored " + "(" + (_vm.uboData.status) + ")" : _vm.uboData.status))+" ")]),(
      !(
        _vm.uboData.status === 'SUCCESS' ||
        _vm.uboData.status === 'MANUAL_SUCCESS' ||
        _vm.uboData.ignored ||
        _vm.comesFromParentKYBCompany
      )
    )?_c('div',{staticClass:"ignore-wrapper"},[_c('v-button',{staticClass:"small appended-icon transparent",nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('showIgnoreModal', true)}}},[_c('i',{staticClass:"fad fa-exclamation-triangle"}),_c('span',[_vm._v(" "+_vm._s(_vm.uboData && _vm.uboData.is_company ? _vm.$t("ignore_company_button_cta") : _vm.$t("ignore_individual_button_cta"))+" ")])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }