import { formatUri, poll } from "@/api";
import { HTTP } from "@/lib/http/api";
import { PA_STATE_MAP } from "@/models/verificationStatus";

export default class ProcessingAttemptsService {
  static async getProcessingAttempts(
    params: URLSearchParams = new URLSearchParams()
  ) {
    const paramsString = !!params.toString() ? "?" + params : "";
    const res = await HTTP.get(
      formatUri`v1/processing-attempts/` + paramsString
    );
    return res.data;
  }
  static async getProcessingAttemptFromId(paId: string) {
    const res = await HTTP.get(formatUri`v1/processing-attempts/${paId}`);
    return res.data;
  }

  static async patchProcessingAttemptToManualReview(paId: string) {
    const res = await HTTP.patch(formatUri`v1/processing-attempts/${paId}`, {
      manual_review_status: "PENDING",
    });
    return res;
  }
  static async reprocessProcessingAttempt(paId: string) {
    const res = await HTTP.post(formatUri`v1/processing-attempts/${paId}/reprocess/`);
    return res;
  }

  static async pollProcessingAttemptCMEStatus(paId: string) {
    return await poll(
      HTTP.get,
      formatUri`v1/processing-attempts/${paId}`,
      (res: any) => {
        for (let cme of res.data.core_module_executions) {
          if (
            cme.status === PA_STATE_MAP.RUNNING ||
            cme.status === PA_STATE_MAP.PENDING
          ) {
            return false;
          }
        }
        return true;
      },
      2000,
      15
    );
  }

  static async pollProcessingAttemptStatus(paId: string) {
    return await poll(
      HTTP.get,
      formatUri`v1/processing-attempts/${paId}`,
      (res: any) => {
          if (
            res.data.status === PA_STATE_MAP.SUCCESS ||
            res.data.status === PA_STATE_MAP.ERROR ||
            res.data.status === PA_STATE_MAP.VERIFICATION_ERROR ||
            res.data.status === PA_STATE_MAP.MANUAL_REVIEW ||
            res.data.status === PA_STATE_MAP.BLOCKED
          ) {
            return true;
          }
        return false;
      },
      2000,
      30
    );
  }
  
  static async postProcessingAttempt(data: any) {
    if (!data) throw { error: "No data Provided" };
    const res = await HTTP.post(formatUri`v1/processing-attempts/`, data);
    return res;
  }
}
