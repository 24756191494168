




































import Vue from "vue";

export default Vue.extend({
  name: "TabAttempts",
  props: {
    resourcesAttemptsFiltered: {
      required: true,
      type: Array as () => Array<any>,
    },
    checksAttemptsFiltered: {
      required: false,
      type: Array as () => Array<any>,
    },
    resourceDetail: { required: true, type: Object },
  },
});
