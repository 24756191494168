


















































































import Vue from "vue";
import {
  VerificationsMap,
  VERIFICATIONS_TYPES_MAP,
} from "@/models/verificationsTypes";
import imageZoom from "vue-image-zoomer";
import VSwitch from "@/components/vuemarc-ui-kit/switch/VSwitch.vue";
import ResourcesService from "@/api/resources/resourcesService";

export default Vue.extend({
  components: { imageZoom, VSwitch },
  name: "PADetailFilesCard",
  props: { resourceDetail: { required: true, type: Object } },
  data() {
    return {
      VerificationsMap: VerificationsMap,
      VERIFICATIONS_TYPES_MAP: VERIFICATIONS_TYPES_MAP,
      files: [],
      loadingFiles: false,
      filesFiltered: [] as any,
      pdfFiles: [] as any,
      rotatedImage: "",
      showCroppedImage: false,
    };
  },
  computed: {
    pictureValidationErrors: function () {
      const pictureValidatorErrors = new Set();
      if (this.resourceDetail && this.files.length > 0) {
        for (const file of this.files) {
          if ((file as any).picture_validation_status !== "SUCCESS") {
            pictureValidatorErrors.add((file as any).picture_validation_status);
          }
        }
      }
      this.$emit("onLoaded", true);
      return pictureValidatorErrors;
    },
    hasHomography() {
      for (const file of this.filesFiltered) {
        if (file.file_homography_url) return true;
      }
      return false;
    },
  },
  watch: {
    async "resourceDetail.id"() {
      this.getFiles();
    },
    async "resourceDetail.processing_attempt_id"() {
      this.getFiles();
    },
  },
  methods: {
    async getFiles() {
      if (!this.resourceDetail) return;
      this.loadingFiles = true;
      this.files =
        (this.resourceDetail as any).files ||
        ((this.resourceDetail as any).id &&
          (
            await ResourcesService.getResourceFiles(
              (this.resourceDetail as any).id
            )
          ).data.files) ||
        [];
      this.filesFiltered = [];
      this.pdfFiles = [];
      if (this.files.length > 0)
        for (const file of this.files) {
          if ((file as any).file_type.includes("MODEL")) {
            this.pdfFiles.push(file);
          } else {
            this.filesFiltered.push(file);
          }
        }
      this.$emit("onLoaded", true);
      this.loadingFiles = false;
    },
    async rotateFile(file: any) {
      const canvas: any = document.createElement("canvas");
      const ctx: any = canvas && canvas.getContext("2d");
      const angleInDegrees = -90;

      const image = document.createElement("img");
      image.onload = () => {
        ctx.save();

        let newWidth = image.width;
        let newHeight = image.height;

        if (canvas.width !== image.height) {
          newWidth = image.height;
          newHeight = image.width;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        ctx.clearRect(0, 0, newWidth, newHeight);

        ctx.translate(newWidth / 2, newHeight / 2);
        ctx.rotate((angleInDegrees * Math.PI) / 180);
        ctx.drawImage(image, -image.width / 2, -image.height / 2);
        ctx.restore();

        const imageUrl = canvas.toDataURL();
        this.rotatedImage = imageUrl;

        if (file.s3_url) file.s3_url = imageUrl;
        else file.file_url = imageUrl;
      };
      image.crossOrigin = "Anonymous";
      let url = file.s3_url || file.file_url;
      if (url.startsWith("https")) {
        url += `&${new Date().getTime()}`;
      }
      image.src = url;
    },
    getFileUrl(file: any) {
      if (this.showCroppedImage && file.file_type !== "VERIFICATION_SELFIE")
        return file.file_homography_url || file.s3_url || file.file_url;
      else return file.s3_url || file.file_url;
    },
  },
  created() {
    this.getFiles();
  },
});
