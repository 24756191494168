

























































































































































import { Vue } from "vue-property-decorator";
import PADetailCDP from "../processing-attempts/pa-detail/components/PADetailCDP.vue";
import ListModalHeader from "../modals/ListModalHeader.vue";
import { CdpDefaultSkeleton, LockedBy, OwnerUser } from "@/models/cdp";
import CDPService, {
  parseDataToKeyValueAndConvertPercentages,
} from "@/api/cdp/cdpService";
import { handleBeforeUnload } from "@/lib/utils";
import { PropType } from "vue";
import VToast from "@/components/vuemarc-ui-kit/toast/VToast.vue";
import PADetailTechDetail from "../processing-attempts/pa-detail/components/components/PADetailTechDetail.vue";

export default Vue.extend({
  components: {
    PADetailCDP,
    ListModalHeader,
    VToast,
    PADetailTechDetail,
  },
  name: "CDPModal",
  props: {
    showResourceDetail: { required: true, type: Boolean },
    CDPDetail: { required: false, type: Object as () => CdpDefaultSkeleton },
    user: { required: false, type: Object as () => OwnerUser },
    loading: { required: false, type: Boolean },
    processingAttempt: { required: true, type: Object },
    resourceFile: { required: true, type: Object },
    changelog: { required: true, type: Array as () => Array<any> },
    lastVersionDocumentProcessProps: {
      required: true,
      type: Object as () => CdpDefaultSkeleton,
    },
    newVersionAvailable: { required: false, type: Boolean },
    isLockedBy: {
      required: true,
      type: null as unknown as PropType<LockedBy>,
    },
    isLastVersion: { required: true, type: Boolean },
    activeCDP: { required: true, type: String },
    loadingChangeLog: { required: true, type: Boolean },
  },
  watch: {
    async showDifferences() {
      if (this.showDifferences && !this.lockedByCurrentUser) {
        await this.lockInvoiceDocument();
      } else window.removeEventListener("beforeunload", handleBeforeUnload);
    },
  },
  data() {
    return {
      localCDPDocument: {} as CdpDefaultSkeleton,
      showDifferences: false,
      loadingLock: false,
    };
  },
  computed: {
    CDPPAStatusClass() {
      const CDPPAStatus: string = this.lastVersionDocumentProcessProps.status;

      return {
        "fa fa-check-circle":
          CDPPAStatus === "SUCCESS" || CDPPAStatus === "APPROVED",
        "fa fa-times-circle":
          CDPPAStatus === "ERROR" ||
          CDPPAStatus === "DISCARDED" ||
          CDPPAStatus === "VERIFICATION_ERROR",
        "fa fa-question-circle":
          CDPPAStatus === "PENDING" ||
          CDPPAStatus === "MANUAL_REVIEW" ||
          CDPPAStatus === "BLOCKED",
        [`status_${CDPPAStatus}`]: true,
      };
    },
    lockedByCurrentUser(): boolean {
      return this.isLockedBy?.id === this.$store.state?.user?.id;
    },
    reviewerEmail(): string {
      if (
        this.lastVersionDocumentProcessProps?.reviewer_user?.email?.includes(
          "siltapp"
        )
      )
        return "Anonymous";
      return (
        this.lastVersionDocumentProcessProps?.reviewer_user?.email?.toLowerCase() ||
        ""
      );
    },
  },
  methods: {
    async lockInvoiceDocument() {
      if (!this.isLastVersion) return;
      try {
        this.loadingLock = true;
        this.$emit("documentLocked", true);
        await CDPService.lockInvoiceDocument(
          this.$store.state.verificationFlow.app_id,
          this.CDPDetail.id
        );
        window.addEventListener("beforeunload", handleBeforeUnload);
      } catch (error) {
        this.handleError();
        this.$emit("documentLocked", false);
      } finally {
        this.loadingLock = false;
      }
    },
    async unlockInvoiceDocument() {
      try {
        this.loadingLock = true;
        this.$emit("documentLocked", false);
        await CDPService.unlockInvoiceDocument(
          this.$store.state.verificationFlow.app_id,
          this.CDPDetail.id
        );
      } catch (error) {
        this.handleError();
        this.$emit("documentLocked", true);
      } finally {
        this.loadingLock = false;
      }
    },
    handleError() {
      this.$root.$emit("v-toast", {
        localizedKey: "unsucessful_feedback",
        type: "error-toast",
      });
    },
    getDifferences(event: CdpDefaultSkeleton) {
      this.localCDPDocument = this.parseCDPDocumentFromFullSchemaToKeyValue(
        event,
        true
      );
      const auxCDPDetail = this.parseCDPDocumentFromFullSchemaToKeyValue(
        this.CDPDetail
      );
      if (
        JSON.stringify(this.localCDPDocument) !== JSON.stringify(auxCDPDetail)
      )
        this.showDifferences = true;
      else this.showDifferences = false;
    },
    parseDataFromFullSchemaToKeyValue(
      object: any,
      convertPercentage: boolean = false
    ) {
      const transform = (obj: any): any => {
        if (Array.isArray(obj)) {
          return obj.map((item) => transform(item));
        } else if (obj && typeof obj === "object") {
          const newObj: any = {};
          Object.keys(obj).forEach((key) => {
            if (Array.isArray(obj[key])) {
              newObj[key] = parseDataToKeyValueAndConvertPercentages(
                obj[key],
                convertPercentage
              );
            } else if (
              obj[key] &&
              typeof obj[key] === "object" &&
              "value" in obj[key]
            ) {
              newObj[key] = obj[key].value;
            } else if (obj[key] && typeof obj[key] === "object") {
              newObj[key] = transform(obj[key]);
            } else {
              newObj[key] = obj[key];
            }
          });
          return newObj;
        }
        return obj;
      };

      return transform(object);
    },
    parseCDPDocumentFromFullSchemaToKeyValue(
      CDPDetail: CdpDefaultSkeleton,
      convertPercentage: boolean = false
    ) {
      const auxCDPDetail = JSON.parse(JSON.stringify(CDPDetail));
      auxCDPDetail.data = this.parseDataFromFullSchemaToKeyValue(
        auxCDPDetail.data,
        convertPercentage
      );
      return auxCDPDetail;
    },
  },
});
