




























































































import Vue from "vue";
import {
  fieldOptions,
  LineItemKeys,
  lineItemsHeaders,
  tableValues,
} from "../../../models/cdp/cdpModels";
import PADetailCDPInput from "../PADetailCDPInput.vue";
import PADetailCDPTableGeneric from "./PADetailCDPTableGeneric.vue";

const VIEW_ALL_KEY = "documents_details_line_items_view_all";

export default Vue.extend({
  name: "PADetailCDPTableLineItems",
  components: { PADetailCDPInput, PADetailCDPTableGeneric },
  props: {
    tableValues: {
      required: true,
      type: Object as () => tableValues,
    },
    fieldOptions: { required: true, type: Object as () => fieldOptions },
    inputPopoverPlacement: { required: true, type: String },
    activeLineItemsTabs: {
      required: true,
      type: Array as () => (LineItemKeys | typeof VIEW_ALL_KEY)[],
    },
  },
  data() {
    return {};
  },
  computed: {
    filteredRows(): Array<any> {
      return this.getFilteredRowsAndHeaders(
        this.tableValues.rows,
        "rows",
        this.activeLineItemsTabs
      );
    },
    filteredHeaders(): Array<string> {
      return this.getFilteredRowsAndHeaders(
        this.tableValues.headers,
        "headers",
        this.activeLineItemsTabs
      );
    },
    lineItemsTableValues(): any {
      return {
        headers: this.filteredHeaders,
        rows: this.filteredRows,
      };
    },
  },
  methods: {
    getFilteredRowsAndHeaders(
      array: any,
      type: string,
      activeLineItemTabs: (LineItemKeys | typeof VIEW_ALL_KEY)[]
    ) {
      if (activeLineItemTabs[0] === VIEW_ALL_KEY && type === "rows")
        return array;

      return type === "headers"
        ? this.processHeaders(array, activeLineItemTabs)
        : this.processRows(array as [], activeLineItemTabs as LineItemKeys[]);
    },
    processHeaders(
      headers: any,
      activeLineItemTabs: (LineItemKeys | typeof VIEW_ALL_KEY)[]
    ) {
      if (!activeLineItemTabs) return;
      if (activeLineItemTabs[0] === VIEW_ALL_KEY)
        return Object.values(headers).flat();

      return activeLineItemTabs.flatMap((tab) => headers[tab] || []);
    },
    processRows(array: any[], activeLineItemTabs: LineItemKeys[]) {
      const lineItemTabs = this.getLineItemTabs();
      return array.map((lineItem) => {
        let updatedRowObject = {};
        activeLineItemTabs.forEach((activeLineItem) => {
          const rowFiltered = this.filterRow(
            lineItem,
            lineItemTabs[activeLineItem]
          );
          updatedRowObject = { ...updatedRowObject, ...rowFiltered };
        });
        return { ...updatedRowObject };
      });
    },
    getLineItemTabs(): lineItemsHeaders {
      return {
        documents_details_line_items_product_details: [
          "bill_of_landing_reference",
          "identifier",
          "uom_description",
        ],
        documents_details_line_items_quantity: ["units", "package", "total"],
        documents_details_line_items_unitary_price: [
          "unit_gross_amount",
          "discount_amount",
          "unit_green_dot_amount",
          "unit_net_amount",
          "unit_total_price",
        ],
        documents_details_line_items_tax: [
          "tax_base_amount",
          "tax_percent",
          "tax_amount",
        ],
        documents_details_line_items_discounts_and_fees: [
          "gross_amount",
          "discount_percent",
          "discount_amount",
          "green_dot",
          "ibee",
          "fees_percent",
          "fees_amount",
        ],
        documents_details_line_items_total_amount: ["total_amount"],
      };
    },
    filterRow(
      lineItem: { [key: string]: any },
      filteredKeys: string[]
    ): { [key: string]: any } {
      filteredKeys.unshift("product_description");
      return filteredKeys
        .filter((key) => key in lineItem)
        .reduce((obj, key) => {
          obj[key] = lineItem[key];
          return obj;
        }, {} as { [key: string]: any });
    },
  },
});
