var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t("mainnav_users")))]),_c('div',[_c('v-table',{ref:"table",staticClass:"clickable",attrs:{"headers":_vm.headers,"getRowsData":_vm.getUsers,"filterOptions":_vm.filterOptions,"hideDetails":true,"showSearchField":true},on:{"onRowClicked":function($event){return _vm.showAndGetResourceDetail($event)}},scopedSlots:_vm._u([{key:"name",fn:function(slotProps){return [(slotProps.item.first_name || slotProps.item.last_name)?_c('span',[_vm._v(" "+_vm._s(( slotProps.item.first_name + " " + slotProps.item.last_name ).toLowerCase() || "-")+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"created_at",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm._f("dateTime")(slotProps.item.created_at))+" ")]}},{key:"status",fn:function(slotProps){return [_c('div',{class:("status_" + (slotProps.item.status))},[_vm._v(" "+_vm._s(_vm._f("clean_cap")(slotProps.item.status))+" ")])]}},{key:"resources",fn:function(slotProps){return _vm._l((_vm.resourcesRequirements),function(requirement,i){return _c('i',{key:((slotProps.item.id) + "-requirement-requirement-" + i),class:[
            'v-icon',
            'fad',
            _vm.VerificationsMap[_vm.VERIFICATIONS_TYPES_MAP[requirement]].icon,
            'large',
            ("status_BLOCKED status_" + (_vm.getPATypeStatusPerUser(slotProps.item)[requirement])),
            {
              medium:
                _vm.VerificationsMap[_vm.VERIFICATIONS_TYPES_MAP[requirement]].key ===
                'userProofOfAddress',
            } ]})})}},{key:"checks",fn:function(slotProps){return [(
            _vm.getStatusPerRequirementGroup(
              _vm.checksRequirements,
              _vm.getPATypeStatusPerUser(slotProps.item)
            ) === 'SUCCESS'
          )?_c('i',{staticClass:"v-icon fad fa-check-circle check-success"}):(
            _vm.getStatusPerRequirementGroup(
              _vm.checksRequirements,
              _vm.getPATypeStatusPerUser(slotProps.item)
            ) === 'BLOCKED'
          )?_c('i',{staticClass:"v-icon fad fa-question-circle check-blocked"}):_vm._l((_vm.checksRequirements),function(check){return [(
              _vm.getPATypeStatusPerUser(slotProps.item)[check] &&
              _vm.getPATypeStatusPerUser(slotProps.item)[check] !== 'SUCCESS' &&
              _vm.getPATypeStatusPerUser(slotProps.item)[check] !== 'BLOCKED'
            )?_c('i',{key:check.id,class:("v-icon fad " + (_vm.VerificationsMap[_vm.VERIFICATIONS_TYPES_MAP[check]].icon) + " large status_BLOCKED status_" + (_vm.getPATypeStatusPerUser(slotProps.item)[check]))}):_vm._e()]})]}}])})],1),_c('UserModal',{attrs:{"loadingProp":_vm.loadingUser,"showResourceDetail":_vm.showResourceDetail,"user":_vm.userDetail,"users":_vm.$refs.table && _vm.$refs.table.rowsData.results,"resource":_vm.resourceDetail},on:{"onUpdateResource":function($event){return _vm.updateResource($event)},"close":function($event){_vm.showResourceDetail = false},"onGoToPreviousOrNextUser":function($event){return _vm.showAndGetResourceDetail($event)},"loadMoreUsers":function($event){return _vm.loadMoreUsers()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }