













































import ProcessingAttemptsService from "@/api/processingAttempts/processingAttemptsService";
import { Vue } from "vue-property-decorator";

export default Vue.extend({
  name: "PADetailTechDetail",
  props: {
    resourceDetail: { required: true, type: Object },
    user: { required: false, type: Object },
    showJson: { required: false, type: Boolean, default: true },
  },
  data() {
    return {
      loadingRerun: false,
      dashboardUrl: process.env.VUE_APP_SILT_DASHBOARD_URL,
      processingAttemptId: "",
    };
  },
  watch: {
    resourceDetail: {
      deep: true,
      handler() {
        const newAttemptId =
          this.resourceDetail.id || this.resourceDetail.processing_attempt_id;

        if (newAttemptId !== this.processingAttemptId) {
          this.stopReprocess();
        }
      },
    },
  },
  methods: {
    stopReprocess() {
      this.processingAttemptId =
        this.resourceDetail.id || this.resourceDetail.processing_attempt_id;
      this.loadingRerun = false;
    },
    async reprocessProcessingAttempt() {
      const attemptId =
        this.resourceDetail.id || this.resourceDetail.processing_attempt_id;
      if (attemptId) {
        this.processingAttemptId = attemptId;
        this.loadingRerun = true;

        try {
          await ProcessingAttemptsService.reprocessProcessingAttempt(attemptId);
          const res =
            await ProcessingAttemptsService.pollProcessingAttemptCMEStatus(
              attemptId
            );

          if (attemptId === this.processingAttemptId) {
            this.$emit("getResourceDetail", res.data);
          }
        } finally {
          this.loadingRerun = false;
        }
      }
    },
  },
});
