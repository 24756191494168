






























































import Vue from "vue";
import KYBService from "@/api/kyb/kybService";
import { getCountriesList } from "@/lib/utils";
import KYBCompanyDetailsInfoSimplified from "./KYBCompanyDetailsInfoSimplified.vue";

export interface IActiveKYBCompany {
  vat_number: string;
  active: boolean;
  id: string;
  country: string;
  legal_name: string;
  activity: string | null;
  duns: string;
  public_charges: string | null;
  admin_name: string;
  creation_date: string | null;
  address: string;
  social_name: string;
  primary_address?: {
    street: string;
    locality: string;
    country: string;
  };
  registered_address?: {
    street: string;
    locality: string;
    country: string;
  };
  principals: [
    {
      full_name: string;
    }
  ];
  incorporation_date: string;
  representative_information?: {
    full_name: string;
  };
}

export default Vue.extend({
  components: { KYBCompanyDetailsInfoSimplified },
  name: "KYBFormModalSearchCompany",
  props: {
    kybCompanyId: { required: true, type: String, default: "" },
    loadingProp: { required: true, type: Boolean },
    fetchCountriesList: { required: true, type: Boolean },
  },
  data() {
    return {
      loading: false,
      companyInfo: {
        name: "",
        country: "",
      },
      countriesList: [] as Array<any>,
      kybCompaniesInfo: [] as Array<any>,
      activeKYBCompany: {} as IActiveKYBCompany | null,
      activeTabName: "",
    };
  },
  created() {
    this.$parent.$on("close", this.handleClose);
  },
  beforeDestroy() {
    this.$parent.$off("close", this.handleClose);
  },
  watch: {
    activeTabName() {
      this.activeKYBCompany = this.kybCompaniesInfo.find((kybCompany) =>
        kybCompany.vat_number
          ? kybCompany.vat_number === this.activeTabName
          : kybCompany.duns === this.activeTabName
      );
      this.$emit("onActiveKYBCompanyChange", this.activeKYBCompany);
    },
    kybCompanyId: {
      handler() {
        if (this.kybCompanyId) this.searchCompanyInfo();
      },
      immediate: true,
    },
    fetchCountriesList: {
      async handler() {
        if (this.fetchCountriesList && !this.countriesList.length)
          this.countriesList = await getCountriesList({ iso: "iso2" });
      },
      immediate: true,
    },
  },
  methods: {
    async searchCompanyInfo() {
      this.loading = true;
      try {
        if (this.companyInfo.name && this.companyInfo.country) {
          const res = await KYBService.searchCompanyInfo(
            this.companyInfo.name,
            this.companyInfo.country,
            this.kybCompanyId
          );
          this.kybCompaniesInfo = res.data;

          this.activeKYBCompany = this.kybCompaniesInfo[0];
          if (this.activeKYBCompany)
            this.activeTabName = this.activeKYBCompany.vat_number
              ? this.activeKYBCompany.vat_number
              : this.activeKYBCompany.duns;
        }
      } catch (error) {
        this.$root.$emit("v-toast", {
          localizedKey: "unsucessful_feedback",
          type: "error-toast",
        });
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.kybCompaniesInfo = [];
      this.activeKYBCompany = null;
    },
  },
});
