

























import Vue, { VueConstructor } from "vue";
import VInputMixin from "./VInputMixin";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof VInputMixin>>
).extend({
  mixins: [VInputMixin],
  name: "v-expand-input",
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      typedValue: this.value,
    };
  },
  computed: {
    listeners(): any {
      return {
        ...this.$listeners,
        input: this.onInput,
        focusin: this.onFocus,
        focusout: this.onBlur,
      };
    },
  },
  mounted() {
    (<HTMLElement>this.$refs.editable).innerText = this.typedValue = this.value;
  },
  watch: {
    value(e) {
      if (!this.focused) {
        (<HTMLElement>this.$refs.editable).innerText = e;
        this.typedValue = e;
      }
    },
  },
  methods: {
    onInput(e: Event) {
      if (!e || !e.target) return;
      this.typedValue = (<HTMLElement>e.target).innerText;
      this.debounceInput(() => {
        this.$emit("change", (<HTMLElement>e.target).innerText);
      });
    },
  },
});
