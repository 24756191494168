import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import * as Sentry from "@sentry/vue";

import { mutations, mutationsT } from "./mutations";
import { getters } from "./getters";
import { actions, actionsT } from "./actions";
import CompaniesService from "@/api/companies/companiesService";
import UsersService from "@/api/users/usersService";
import { setCompanyAppHeader } from "@/lib/http/api";

const vuexPersist = new VuexPersist({
  key: "silt-vuex",
  storage: window.sessionStorage,
});

Vue.use(Vuex);

export type State = any;

export const initialState = {
  companyAppId: "",
  companyAppTempToken: "",
  company: undefined as any,
  companies: [] as Array<any>,
  user: null,
  userIsStaff: false,
  onboardingInfo: {},
  userRole: "",
  verificationFlow: {},
  loading: false,
} as State;

export const store = new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: initialState,

  mutations,

  actions: {
    ...actions,
    async FETCH_COMPANY({ commit, state }: any) {
      if (!state.companies.length) {
        const companies = await CompaniesService.getCompanies();
        commit(mutationsT.UPDATE_COMPANIES, companies.data.results);
      }
      if (!state.company) {
        const hasOnlyOneCompany =
          state.companies && state.companies.length === 1;
        commit(
          mutationsT.UPDATE_COMPANY,
          hasOnlyOneCompany ? state.companies[0] : "all-companies"
        );
      }
      return Promise.resolve(state.companies);
    },
    async FETCH_VERIFICATION_FLOW({ commit, state }: any) {
      if (state.company && state.company === "all-companies")
        commit(mutationsT.UPDATE_COMPANY_APP_ID, "all");
      else {
        if (!state.companyAppId) {
          const hasOnlyOneVerificationFlow =
            state.company &&
            state.company.company_apps &&
            state.company.company_apps.length === 1;
          commit(
            mutationsT.UPDATE_COMPANY_APP_ID,
            hasOnlyOneVerificationFlow
              ? state.company.company_apps[0].app_id
              : "all"
          );
        }
        setCompanyAppHeader();
        await store.dispatch(actionsT.FETCH_USER);
        if (
          state.companyAppId &&
          state.companyAppId !== "all" &&
          state.verificationFlow?.app_id !== state.companyAppId
        ) {
          try {
            const activeVerificationFlow = await CompaniesService.getCompanyApp(
              state.companyAppId
            );
            commit(
              mutationsT.UPDATE_VERIFICATION_FLOW,
              activeVerificationFlow.data
            );
          } catch (error) {
            Sentry.captureException(error);
            (this as any)._vm.$root.$emit("v-toast", {
              localizedKey: "unsucessful_feedback",
              type: "error-toast",
            });
          }
        }
      }
      return Promise.resolve(state.verificationFlow);
    },
    async FETCH_USER({ commit, state }: any) {
      let user = null;
      if (!state.user || state.verificationFlow?.app_id !== state.companyAppId) {
        const res = await UsersService.getUser();
        user = res.data;
        commit(mutationsT.UPDATE_LOADING, false);
      } else {
        user = state.user;
      }
      await commit(mutationsT.UPDATE_USER, user);
    },
  },

  getters,
});

export default store as any;
