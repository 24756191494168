var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t( "verification_flow_settings_verification_requirements_kyb_UBO_discovery_description", { UBODiscoveryPercentage: _vm.UBODiscoveryPercentage } ))+" ")]),_c('h4',[_vm._v(" "+_vm._s(_vm.$t( "verification_flow_settings_verification_requirements_kyb_UBO_discovery_title" ))+" ")]),(_vm.hasUBODiscovery)?_c('div',{staticClass:"automatic-list-prefill-wrapper context"},[_c('VSwitch',{attrs:{"enableText":"verification_flow_settings_verification_requirements_kyb_UBO_discovery_customize_title"},model:{value:(_vm.hasUBODiscoveryPrefill),callback:function ($$v) {_vm.hasUBODiscoveryPrefill=$$v},expression:"hasUBODiscoveryPrefill"}},[_c('h4',{staticClass:"switch-header-slot",attrs:{"slot":"enable-text"},slot:"enable-text"},[_vm._v(" "+_vm._s(_vm.$t( "verification_flow_settings_verification_requirements_kyb_UBO_discovery_customize_title" ))+" ")])]),_c('div',{class:{ disabled: !_vm.hasUBODiscoveryPrefill }},[_c('div',{staticClass:"automatic-list-prefill-wrapper-depth__wrapper"},[_c('v-select',{attrs:{"label":_vm.$t(
              'verification_requirements_kyb_config_flow_custom_levels_title'
            ),"options":_vm.UBODiscoveryDepthOptions},model:{value:(_vm.UBODiscoveryDepth),callback:function ($$v) {_vm.UBODiscoveryDepth=$$v},expression:"UBODiscoveryDepth"}}),(_vm.UBODiscoveryDepth === 'custom')?_c('v-text-field',{attrs:{"label":_vm.$t(
              'verification_requirements_kyb_config_flow_custom_levels_number_of_ñevels'
            )},model:{value:(_vm.UBODiscoveryDepthValue),callback:function ($$v) {_vm.UBODiscoveryDepthValue=$$v},expression:"UBODiscoveryDepthValue"}}):_vm._e()],1),_c('p',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(
          _vm.$t(
            'verification_flow_settings_verification_requirements_kyb_UBO_discovery_customize'
          )
        )}}),_c('v-text-field',{staticClass:"ubo-percentage",attrs:{"label":_vm.$t('ubo_percentage')},model:{value:(_vm.UBODiscoveryPercentage),callback:function ($$v) {_vm.UBODiscoveryPercentage=$$v},expression:"UBODiscoveryPercentage"}})],1)],1):_vm._e(),_c('div',{class:{ disabled: !_vm.hasUBODiscovery }},[_c('VSwitch',{attrs:{"enableText":"verification_flow_settings_verification_requirements_kyb_UBO_verification_title"},model:{value:(_vm.hasUBOVerification),callback:function ($$v) {_vm.hasUBOVerification=$$v},expression:"hasUBOVerification"}},[_c('h4',{staticClass:"switch-header-slot",attrs:{"slot":"enable-text"},slot:"enable-text"},[_vm._v(" "+_vm._s(_vm.$t( "verification_flow_settings_verification_requirements_kyb_UBO_verification_title" ))+" ")])]),_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t( "verification_flow_settings_verification_requirements_kyb_UBO_verification_description" ))+" ")]),(_vm.hasUBOVerification)?_c('KYCRequirements',{staticClass:"context",attrs:{"cappFilteredSyncVerifications":_vm.cappFilteredSyncVerificationsUBO,"cappFilteredSyncVerificationsInput":_vm.cappFilteredSyncVerificationsInputUBO,"companyAppPermissions":_vm.verificationFlowConfig.ubo_permissions,"recurrentAMLOptions":_vm.recurrentAMLOptions,"recurrentAMLValue":_vm.recurrentAMLUBOValue},on:{"updateConfigurationFlow":function($event){return _vm.$emit('updateConfigurationKYCUBOFlow', $event)},"onRecurrentAMLValueChange":function($event){return _vm.$emit('onRecurrentAMLUBOValueChange', $event)}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }