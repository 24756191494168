<template>
  <div v-if="verifications" class="oneline-verifications__wrapper">
    <span
      v-for="(mv_group, i) of verifications"
      :key="`${mv_group}-${i}`"
      class="listed-item hint"
    >
      <span v-if="i > 0" class="listed-item">{{ $t("and") }} </span>
      (
      <span
        v-for="(mv, j) of mv_group"
        :key="`${mv}-${j}`"
        class="verification_type__name"
      >
        <span v-if="j > 0" class="hint">{{ $t("or") }} </span>
        <span v-if="!VERIFICATIONS_TYPES_VIEW_MAP[mv].includes('expires')">
          {{ $t(VERIFICATIONS_TYPES_VIEW_MAP[mv]) }}
        </span>
        <span v-else>
          {{
            parseAMLRecurrency(
              VERIFICATIONS_TYPES_VIEW_MAP[mv].replace(/AML/g, "")
            )
          }}
        </span>
      </span>
      )
    </span>
  </div>
</template>

<script>
import {
  AML_RECURRENCY_VERIFICATIONS_TYPES_VIEW_MAP,
  AML_AND_PEP_VERIFICATIONS_TYPES_VIEW_MAP,
  VERIFICATIONS_TYPES_VIEW_MAP,
} from "@/models/verificationsTypes";
import Vue from "vue";

export default Vue.extend({
  name: "oneline-verifications",
  props: {
    verifications: { required: true },
    showAllVerifications: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      VERIFICATIONS_TYPES_VIEW_MAP: this.showAllVerifications
        ? {
            ...VERIFICATIONS_TYPES_VIEW_MAP,
            ...AML_AND_PEP_VERIFICATIONS_TYPES_VIEW_MAP,
            ...AML_RECURRENCY_VERIFICATIONS_TYPES_VIEW_MAP,
          }
        : VERIFICATIONS_TYPES_VIEW_MAP,
      recurrentAMLOptions: {
        expires15d: this.$t(
          "verification_flow_settings_verification_requirements_document_types_AML_frequency_type_2"
        ),
        expires30d: this.$t(
          "verification_flow_settings_verification_requirements_document_types_AML_frequency_type_3"
        ),
        expires1y: this.$t(
          "verification_flow_settings_verification_requirements_document_types_AML_frequency_type_4"
        ),
      },
    };
  },
  methods: {
    parseAMLRecurrency(value) {
      return `AML (${this.recurrentAMLOptions[value]})`;
    },
  },
});
</script>

<style lang="sass" scoped>
@import '~@/styles/global/theme.sass'

.verification_type__name
  color: $color-grey--darken-3

.oneline-verifications__wrapper
  display: inline-block
  &.light
    .hint
      color: rgba(255, 255, 255, 0.6)
    .verification_type__name
      color: #fff
</style>