









import Vue from "vue";

export default Vue.extend({
  components: {},
  name: "KYBRequirementStep",
  props: {
    stepTitle: { required: true, type: String },
    stepSubtitle: { required: true, type: String },
  },
  data() {
    return {};
  },
  methods: {},
});
