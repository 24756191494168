














































import Vue from "vue";
import { VTableHeader } from "@/components/vuemarc-ui-kit/tables/VTable.vue";
import UserModal from "@/components/users/UserModal.vue";
import ResourcesService from "@/api/resources/resourcesService";
import UsersService from "@/api/users/usersService";
import VRuleOneLiner from "@/components/rules/VRuleOneLiner.vue";

interface IUserDetail {
  id: string;
  processing_attempts: Array<any>;
}

interface IUserNextAndPrevious {
  user: {
    id: string;
  };
}

export default Vue.extend({
  name: "rule-hits-list",
  components: { VRuleOneLiner, UserModal },
  props: {
    getRuleHits: { required: true },
    users: { required: true, default: () => Array<IUserNextAndPrevious>() },
  },
  data() {
    return {
      headers: [
        // TODO: Backend will send us rule pa status
        // {
        //   name: "status",
        //   text: "User Status",
        // },
        {
          name: "user_first_name_and_last_name",
          text: this.$t("rules_list_name_and_surname"),
        },
        { name: "date", text: this.$t("rules_list_hit_date") },
      ] as VTableHeader[],

      userDetail: {} as IUserDetail,
      resourceDetail: {},
      showResourceDetail: false,
      popoverPlacement: "top-start",
      loadingUser: false,
    };
  },
  methods: {
    onClosePADetail() {
      this.showResourceDetail = false;
    },
    async getUser(userid: string) {
      const user = await UsersService.getUserStatus(userid);
      return user.data;
    },

    async updateResource(resource: any) {
      const res = await ResourcesService.patchResourceStatus(
        resource.id,
        resource.status
      );

      resource.status = res.data.status;
      resource.manual_review_status = res.data.manual_review_status;

      return res;
    },

    async showAndGetResourceDetail(userId: string) {
      if (this.userDetail.id === userId) {
        this.showResourceDetail = true;
        return;
      }
      this.loadingUser = true;

      this.userDetail = await this.getUser(userId);

      this.resourceDetail = this.resourceDetail =
        this.userDetail.processing_attempts &&
        this.userDetail.processing_attempts[0];

      this.showResourceDetail = true;
      this.loadingUser = false;

      if (userId && userId != this.$route.params.userId) {
        this.$router.push({
          name: "rule-hits-list-user-detail",
          params: { userId },
        });
      }
    },
  },
  mounted: async function () {
    if (this.$route.params.userId) {
      this.showAndGetResourceDetail(this.$route.params.userId);
    }
  },
});
