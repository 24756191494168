






import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Chart, { TooltipModel } from "chart.js/auto";

Chart.defaults.color = "rgba(255,255,255,0.9)";
Chart.defaults.set("font", {
  family: "'Futura-Medium', 'Arial Narrow', Arial, sans-serif",
  size: 14,
});

@Component({})
export default class VChart extends Vue {
  @Prop({
    default() {
      return [
        "18/09/2018",
        "25/09/2018",
        "31/09/2018",
        "7/10/2018",
        "14/10/2018",
        "21/10/2018",
      ];
    },
  })
  labels?: Array<string>;
  @Prop({
    default() {
      return [126997, 86235, 76373, 106368, 146479, 106015];
    },
  })
  values?: Array<number>;
  myChart: any = null;

  @Prop({ type: String, default: "Users " })
  nodeText?: string;

  @Prop({ type: [Date, String] })
  startDate?: string | Date;

  @Prop({ type: [Date, String] })
  endDate?: string | Date;

  get labelsFromStartDateAndEndDate(): string[] {
    const startDate = new Date(this.startDate as string | Date);
    const endDate = new Date(this.endDate as string | Date);

    const addDays = (date: Date, days: number): Date => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };

    const labels: string[] = [];

    for (
      let currentDate = startDate;
      currentDate <= endDate;
      currentDate = addDays(currentDate, 1)
    ) {
      const formattedDate = this.$options?.filters?.date(currentDate) as string;
      labels.push(formattedDate);
    }

    return labels;
  }

  get valuesFromStartDateAndEndDate(): number[] {
    const values: number[] = [];

    const labelsFromStart = this.labelsFromStartDateAndEndDate ?? [];
    const labels = this.labels ?? [];
    const valuesArray = this.values ?? [];

    labelsFromStart.forEach((label) => {
      const formattedLabels = labels.map((label) =>
        this.$options?.filters?.date(label)
      );
      const labelIndex = formattedLabels.indexOf(label);

      if (labelIndex !== -1) {
        values.push(valuesArray[labelIndex]);
      } else {
        values.push(0);
      }
    });

    return values;
  }

  @Watch("values")
  onValuesChange() {
    if (this.myChart) {
      this.myChart.destroy();
    }
    this.loadChard();
  }

  loadChard() {
    const ctx = (
      document.getElementById("myChart") as HTMLCanvasElement
    ).getContext("2d");
    if (!ctx) return;
    var gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(41, 99, 255, 0.3)");
    gradient.addColorStop(0.8, "rgba(41, 99, 255, 0)");

    this.myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: this.labelsFromStartDateAndEndDate,
        datasets: [
          {
            data: this.valuesFromStartDateAndEndDate,
            backgroundColor: gradient,
            borderColor: "rgba(41, 99, 255, 0.8)",
            pointBackgroundColor: ["rgba(41, 99, 255, 0)"],
            pointBorderColor: ["rgba(41, 99, 255, 0)"],
            pointBorderWidth: 2,
            pointHoverBorderWidth: 3,
            pointHoverBorderColor: ["rgba(255, 255, 255, 1)"],
            pointHoverBackgroundColor: ["rgba(41, 99, 255, 1)"],
            pointHoverRadius: 5,
            borderWidth: 2.5,
            tension: 0.4,
            fill: true,
          },
        ],
      },
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          mode: "nearest",
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            bodyColor: "rgba(41, 99, 254, 1)",
            titleColor: "rgba(41, 99, 254, 1)",
            cornerRadius: 13,
            enabled: false,
            caretPadding: 20,
            external: createExternalTooltipFunction(this, this.nodeText),
            xAlign: "left",
          },
          title: {
            color: "rgba(41, 99, 254, 1)",
          },
        },

        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
            ticks: {
              color: "rgba(0,0,0,0.5)",
            },
            grid: {
              drawBorder: false,
              display: false,
            },
          },
          y: {
            min: 0,
            ticks: {
              stepSize: 1,
              autoSkipPadding: 10,
              autoSkip: true,
              color: "rgba(0,0,0,0.5)",
            },
            grid: {
              drawBorder: false,
              color: "rgba(0,0,0,0)",
            },
          },
        },
      },
    });
  }

  mounted() {
    this.loadChard();
  }
}

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(250, 250, 250, 0.5)";
    tooltipEl.style.borderRadius = "13px";
    tooltipEl.style.border = "2px solid rgba(247, 247, 247, 0.9)";
    tooltipEl.style.color = "rgba(0,0,0,0.7)";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .3s ease";
    tooltipEl.style.backdropFilter = "blur(7px)";
    const table = document.createElement("table");
    table.style.margin = "0px";
    table.style.borderSpacing = "10px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const createExternalTooltipFunction = (
  vueInstance: Vue,
  nodeText: String | undefined
) => {
  return function (this: TooltipModel<any>, args: any) {
    externalTooltipHandler.call(vueInstance, { ...args, nodeText });
  };
};

const externalTooltipHandler = (context: any) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b: any) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title: any) => {
      const tr: any = document.createElement("tr");
      tr.style.borderWidth = 0;

      const th: any = document.createElement("th");
      th.style.borderWidth = 0;
      th.style.paddingBottom = 20;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body: any, i: any) => {
      const colors = tooltip.labelColors[i];

      const tr: any = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td: any = document.createElement("td");
      const td2: any = document.createElement("td");
      td.style.borderWidth = 0;

      const text = document.createTextNode(body);
      const texttitle = document.createTextNode(context.nodeText);

      // td.appendChild(span);
      td.appendChild(texttitle);
      td2.appendChild(text);
      tr.appendChild(td);
      tr.appendChild(td2);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  let xPositionTooltip = tooltip.caretX - tooltipEl.offsetWidth / 2 - 10;
  if (tooltip.caretX < 100)
    xPositionTooltip = tooltip.caretX + tooltipEl.offsetWidth / 2 + 30;
  tooltipEl.style.left = positionX + xPositionTooltip + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
};
