




































































import Vue from "vue";

interface VerificationFlowInfo {
  alias: string;
  name: string;
}

export default Vue.extend({
  name: "AppDetailVerificationFlowInfo",
  props: {
    companyApp: { required: true, type: Object },
    create: { required: true, type: Boolean },
    loadingLogo: { required: true, type: Boolean },
    verificationFlowType: { required: true, type: String },
    logoUrl: { required: false, type: String },
  },
  computed: {
    nameComputed: {
      get(): string {
        return (this.companyApp as VerificationFlowInfo).name;
      },
      set(value: string) {
        this.updateVerificationFlow({ name: value });
      },
    },
    aliasComputed: {
      get(): string {
        return (this.companyApp as VerificationFlowInfo).alias;
      },
      set(value: string) {
        this.updateVerificationFlow({ alias: value });
      },
    },
    localLogoUrl: {
      get(): string {
        return this.logoUrl;
      },
      set() {},
    },
  },
  data() {
    return {
      verificationFlow: {
        alias: this.companyApp.alias,
        name: this.companyApp.name,
      },
    };
  },
  methods: {
    updateVerificationFlow(update: Partial<VerificationFlowInfo>) {
      const newVerificationFlow = {
        ...this.verificationFlow,
        ...update,
      };
      this.verificationFlow = newVerificationFlow;
      this.$emit("updateVerificationFlowInfo", newVerificationFlow);
    },
  },
});
