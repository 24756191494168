/* eslint-disable @typescript-eslint/camelcase */
import { formatUri } from "@/api";
import { HTTP, setToken, getToken, removeToken } from "@/lib/http/api";
import { actionsT } from "@/state/actions";
import { mutationsT } from "@/state/mutations";
import store from "@/state/store";
import Axios from "axios";

export default class AuthApi {

  static async login(data: { email: string; password: string }) {
    if (!data) throw { error: "No data provided" };

    const params = new URLSearchParams();
    params.append("username", data.email);
    params.append("password", data.password);
    params.append("grant_type", "password");
    params.append("client_id", process.env.VUE_APP_CLIENT_ID || "");
    params.append("client_secret", process.env.VUE_APP_CLIENT_SECRET || "");
    let response = null;
    try {
      response = await Axios.post(formatUri`v1/auth/token/`, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response.status === 200) {
        setToken(response.data.access_token, response.data.expires_in);
        await store.dispatch(actionsT.FETCH_USER);
        if (store.state.userIsStaff) {
          store.state.companyAppId = "all";
        } else if (
          store.state.companies.length > 0 &&
          store.state.companies[0].company_apps.length > 0 &&
          store.state.companies[0].company_apps[0].app_id
        ) {
          store.commit(
            mutationsT.UPDATE_COMPANY_APP_ID,
            store.state.companies[0].company_apps[0].app_id
          );
        }
      }
    } catch (error) {
      await removeToken();
      throw error;
    }
    if (!store.user) return;
    await store.dispatch(actionsT.FETCH_COMPANY);
    await store.dispatch(actionsT.FETCH_VERIFICATION_FLOW);
    return response;
  }

  static async signup(data?: { email: string; password?: string }) {
    //if (!data) throw { error: "No data Provided" };
    const res = await HTTP.post(formatUri`v1/auth/register/`, data);
    if (res.status === 201 || res.status === 200) {
      setToken(res.data.access_token);
      await store.dispatch(actionsT.FETCH_USER);
    } else {
      await removeToken();
    }
    return res;
  }

  static async updateUserAuth(data: { email?: string; password?: string }) {
    //if (!data) throw { error: "No data Provided" };
    const res = await HTTP.patch(formatUri`v1/users/self/`, data);
    return res;
  }

  static async recoverPassword(data: { email: string; redirect_url: string }) {
    const res = await HTTP.post(formatUri`v1/auth/recover-token/`, data);
    return res;
  }

  static async createPassword(data: { password: string }, token: string) {
    const res = await Axios.patch(formatUri`v1/users/self/`, data, {
      headers: {
        "X-Recover-Token": token,
      },
    });
    return res;
  }
}
