var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-input',_vm._b({staticClass:"v-select",class:{
    'v-input--dirty': _vm.dirty,
    'v-input--typed': _vm.focused || !_vm.isEmpty,
    error: _vm.required && _vm.dirty && !_vm.value,
    active: _vm.showOptions,
    'v-input-appended-icon': true,
  }},'v-input',_vm.$options.propsData,false),[_c('v-popover',{attrs:{"open":_vm.showOptions,"placement":"bottom-start","trigger":"manual","delay":0},on:{"auto-hide":function($event){_vm.showOptions = false}}},[_c('div',{staticClass:"v-select__selected v-input__slot__input capitalized",class:_vm.inputClass,on:{"click":function($event){return _vm.toggleOptions()}}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("clipped")(_vm.$t(_vm.valueText || (_vm.inputValue && _vm.inputValue.toLowerCase())),_vm.clip))+" ")])]),_c('i',{staticClass:"appended-icon fa fa-chevron-down"}),_c('template',{slot:"popover"},[_c('div',{staticClass:"v-select__options-list tooltip-content",class:{ active: _vm.showOptions }},[_c('div',{staticClass:"v-select__options-list__container"},[_vm._l((_vm.options),function(option,index){return [_c('div',{key:option.value,staticClass:"v-select__options-list__option",class:{ active: _vm.inputValue === option.value },on:{"click":function($event){_vm.inputValue = option.value;
                _vm.hideOptions();}}},[_c('div',{class:("option-" + index + " " + (option.value) + " capitalized " + (option.class))},[_vm._t("optionListItem",[_vm._v(" "+_vm._s(_vm._f("clean_cap")((option.text || option.value)))+" ")],{"option":option})],2)])]}),_vm._t("footer")],2)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }