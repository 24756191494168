import Vue from 'vue';

Vue.filter('clean_cap', (value: string) => {
  if(value && typeof value == 'string') {
    return value.replace(/(_)/g, " ").toLowerCase()
  }
  return value
})

Vue.filter('clean_cap_error', (value: string) => {
  if(value && typeof value == 'string') {
    if(value.startsWith("E_")) value = value.substring(2)
    return value.replace(/(_)/g, " ").toLowerCase()
  }
  return value
})

Vue.filter('removeUnderscores', function (value: string) {
  if (value && typeof value == 'string') return value.replace(/_/g, ' ');
  return value
});

Vue.filter('clipped', (value: string, chars: number = 13) => {
  if(value && chars && typeof value == 'string' && value.length > chars) {
    return `${value.substring(0, chars - 3)}...`
  }
  return value
})

Vue.filter('capitalized', (value: string) => {
  if(value && typeof value == 'string') {
    return `${value[0].toUpperCase()}${value.substring(1)}`
  }
  return value
})

Vue.filter('clean_array', (value: Array<string>) => {
  if(value && typeof Array.isArray(value)) {
    return value.toString().replace(/\[|\]|\"/g, "")
  }
  return value
})

Vue.filter('status', (value: string) => {
  if (typeof value === "string" && value.toLowerCase() === "pending") {
    return "In progress";
  } else if (value && typeof value == 'string') {
    return value.replace(/(_)/g, " ").toLowerCase()
  }
  return value;
});

Vue.filter('email', (value: string) => {
  if (typeof value === "string" && value && value.includes('siltapp')) {
    return "-";
  }
  return value;
});
