






































import VRuleOneLiner from "@/components/rules/VRuleOneLiner.vue";
import Vue from "vue";
import PADetailVerificationInfo from "@/components/processing-attempts/pa-detail/components/components/PADetailVerificationInfo.vue";

export default Vue.extend({
  name: "PADetailRuleDetail",
  props: {
    resourceDetail: { required: true, type: Object },
    statusOptions: { required: true },
  },
  components: { PADetailVerificationInfo, VRuleOneLiner },
  data() {
    return {
      ruleHeaders: [
        {
          name: "date",
          text: this.$t("rules_list_hit_date"),
          class: "capitalized",
        },
        {
          name: "one-liner",
          text: this.$t("users_detail_checks_type_rules"),
        },
      ],
    };
  },
  computed: {
    cmeOutput(): Array<any> {
      return (
        (this.resourceDetail.cmes && this.resourceDetail.cmes[0].output) ||
        (this.resourceDetail.core_module_executions &&
          this.resourceDetail.core_module_executions[0].output)
      );
    },
  },
});
