



















import { Component, Prop, Vue } from "vue-property-decorator";
import { actionsT } from "@/state/actions";

@Component({})
export default class CompanyInfo extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) subtitle!: string;

  countdown = 5;
  active = false;
  mounted() {
    setTimeout(async () => {
      this.active = true;
      await this.$store.dispatch(actionsT.FETCH_COMPANY);
      await this.$store.dispatch(actionsT.FETCH_USER);
      const downloadTimer = setInterval(() => {
        if (this.countdown <= 1) {
          clearInterval(downloadTimer);
          this.$emit("finishedTimeout");
        }
        this.countdown -= 1;
      }, 1000);
    }, 100);
  }
}
