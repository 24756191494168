






import Vue from "vue";

export default Vue.extend({
  name: "VPlaceholder",
  props: {
    height: { required: false, type: String, default: "10px" },
    width: { required: false, type: String, default: "100%" },
    borderRadius: { required: false, type: String, default: "5px" },
  },
});
