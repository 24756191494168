






























































import { animateNumber } from "@/lib/animators";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "HomeAnalyticsCard",
  props: {
    startDate: { required: true, type: [String, Date] },
    endDate: { required: true, type: [String, Date] },
    verificationFlows: { required: true, type: Array as () => Array<any> },
    showFilters: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      resultsSorted: [] as Array<any>,
      animateNumber: animateNumber,
      incrementedUsersAmount: { amount: 0 },
      incrementedCompaniesAmount: { amount: 0 },
      incrementedComplexDocsAmount: { amount: 0 },
    };
  },
  computed: {
    computedTitle(): TranslateResult {
      if (this.isAllCompanies) {
        return this.getVerificationFlowName;
      } else if (this.isAllCompanyAppsSelected) {
        return this.$t("all_company_apps");
      } else if (this.$store.state.verificationFlow.flow_type === "KYB") {
        return this.$t("overview_total_new_companies_title");
      } else if (this.$store.state.verificationFlow.flow_type === "CDP") {
        return this.$t("overview_usage_this_month_title");
      } else {
        return this.$t("overview_total_new_users_title");
      }
    },
    getVerificationFlowName(): string {
      return this.verificationFlows[0].name;
    },
    totalUsersAmount(): number {
      return this.sumTotal(this.verificationFlows, "totalUsersVerified");
    },
    totalCompaniesAmount(): number {
      return this.sumTotal(
        this.verificationFlows,
        "totalCountOfCompaniesVerified"
      );
    },
    totalComplexDocsAmount(): number {
      return this.sumTotal(
        this.verificationFlows,
        "totalCountOfComplexDocsVerified"
      );
    },
    isAllCompanies(): boolean {
      return this.$store.state.company === "all-companies";
    },
    isAllCompanyAppsSelected(): boolean {
      return this.$store.state.companyAppId === "all";
    },
    analyticsInformationOverview(): Array<any> {
      const totalCountByType = [];
      if (this.$store.state.verificationFlow.flow_type === "CDP") return [];
      for (const verificationFlow of this.verificationFlows) {
        for (const verification of verificationFlow.checks) {
          const { type, totalCount } = verification;
          const typeIndex = totalCountByType
            .map((item) => item.type)
            .indexOf(type);

          if (typeIndex > -1) {
            totalCountByType[typeIndex].totalCount += totalCount;
          } else {
            totalCountByType.push({ type, totalCount });
          }
        }
      }

      return totalCountByType;
    },
  },
  watch: {
    totalUsersAmount: {
      handler(value) {
        animateNumber(this.incrementedUsersAmount, value);
      },
      immediate: true,
    },
    totalCompaniesAmount: {
      handler(value) {
        animateNumber(this.incrementedCompaniesAmount, value);
      },
      immediate: true,
    },
    totalComplexDocsAmount: {
      handler(value) {
        animateNumber(this.incrementedComplexDocsAmount, value);
      },
      immediate: true,
    },
  },
  methods: {
    sumTotal(arr: any[], prop: string): number {
      return arr.reduce((total, item) => total + (item[prop] || 0), 0);
    },
  },
});
