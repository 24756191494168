






















import { Component, Mixins } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";

@Component({
  inheritAttrs: false,
})
export default class VTextField extends Mixins(VInputMixin) {
  set inputValue(val: string | number | undefined) {
    this.debounceInput(()=>{
      this.$emit("change", val);
    })
  }

  get inputValue() {
    return this.value;
  }

  get listeners() {
    return Object.assign({}, this.$listeners, {
      // This ensures that the component works with v-model
      change: (event: any) => {
        this.$emit("change", event.target.value);
      },
    });
  }

  focus() {
    const input = this.$refs.input as HTMLElement;

    this.$nextTick(() => {
      setTimeout(() => input.focus(), 100);
    });
  }
}
