




























import Vue, { VueConstructor } from "vue";
import VInputMixin from "./VInputMixin";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof VInputMixin>>
).extend({
  mixins: [VInputMixin],
  name: "VInputFIle",
  props: {
    preview: {
      type: Boolean,
      default: false,
      required: false,
    },
    title: {
      type: String,
      default: "Upload file",
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    outline: {
      type: Boolean,
      default: false,
      required: false,
    },
    buttonClass: {
      type: String,
      default: "",
      required: false,
    },
  },
  computed: {
    listeners(): any {
      return Object.assign({}, this.$listeners, {
        change: (event: any) => {
          this.onFileChange(event);
        },
      });
    },
  },
  data() {
    return {
      inputValue: this.value,
      fileRef: null,
    };
  },
  methods: {
    onFileChange(e: Event) {
      if (!e) return;
      const files = (e.target as HTMLInputElement).files;
      if (!files?.length) return;
      this.createImage(files[0]);
    },
    createImage(file: File) {
      this.$emit("changeExtended", { file });
    },
    handleButtonClick() {
      if (this.$refs.file) {
        (this.$refs.file as HTMLInputElement).click();
      }
    },
  },
});
