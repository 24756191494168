




















































import MisconductListService from "@/api/misconductList/misconductListService";
import OnelineVerifications from "@/components/OnelineVerifications.vue";
import Vue from "vue";

export default Vue.extend({
  name: "MisconductModalAdd",
  components: { OnelineVerifications },
  props: {
    user: {
      required: true,
      type: Object,
    },
    resourceDetail: { required: true, type: Object },
    misconductOptions: { required: true, type: Array as () => Array<any> },
    showMisconductDetail: { required: true, type: Boolean },
  },
  data() {
    return {
      infringementDescription: "",
      misconductOptionValue: "",
      severityOptionsValue: "",
      loading: false,
      severityOptions: [
        {
          paramsValue: 1,
          value: "Minor",
          text: this.$t("misconduct_list_misconduct_severity_type_minor"),
        },
        {
          paramsValue: 2,
          value: "Medium",
          text: this.$t("misconduct_list_misconduct_severity_type_medium"),
        },
        {
          paramsValue: 3,
          value: "Major",
          text: this.$t("misconduct_list_misconduct_severity_type_major"),
        },
      ],
    };
  },
  methods: {
    onCloseMisconductModal() {
      this.$emit("showMisconductDetail", false);
      this.misconductOptionValue = "";
      this.severityOptionsValue = "";
      this.infringementDescription = "";
    },
    getIdOfMisconductOptions(val: string) {
      const selectedOption = this.misconductOptions.filter(
        (option: any) => option.name === val
      );

      return selectedOption[0].id;
    },
    getValueOfMisconductSeverities(val: string) {
      const selectedSeverity = this.severityOptions.filter(
        (option: any) => option.value === val
      );

      return selectedSeverity[0].paramsValue;
    },
    async postMisconductOfUser() {
      this.loading = true;

      const companyId = this.$store.state.company.id;

      const typeId = this.getIdOfMisconductOptions(this.misconductOptionValue);

      const severity = this.getValueOfMisconductSeverities(
        this.severityOptionsValue
      );

      const userId = this.user.id;

      const description = this.infringementDescription;

      try {
        await MisconductListService.postMisconduct(
          companyId,
          typeId,
          userId,
          severity as any,
          description
        );
        this.$root.$emit("v-toast", {
          localizedKey: "successful_misconduct_user_added_feedback",
          type: "success-toast",
        });
      } catch (error) {
        this.$root.$emit("v-toast", {
          localizedKey: "unsuccessful_misconduct_user_added_feedback",
          type: "error-toast",
        });
      } finally {
        this.loading = false;

        this.onCloseMisconductModal();

        this.$root.$emit(
          "amplitude",
          "Misconduct - Add to our Misconduct List Clicked"
        );
      }
    },
  },
});
