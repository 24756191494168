import { render, staticRenderFns } from "./UserDetail.vue?vue&type=template&id=6415003a&scoped=true&"
import script from "./UserDetail.vue?vue&type=script&lang=ts&"
export * from "./UserDetail.vue?vue&type=script&lang=ts&"
import style0 from "./UserDetail.vue?vue&type=style&index=0&id=6415003a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6415003a",
  null
  
)

export default component.exports