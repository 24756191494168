var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"v-button",class:{
    loading: _vm.loading,
    'v-button-icon v-icon': _vm.icon,
    'v-button-flat': _vm.flat,
    'v-button-outline': _vm.outline,
    'v-button-link': _vm.link,
    small: _vm.small,
    large: _vm.large,
  },attrs:{"disabled":_vm.loading || _vm.disabled}},[_c('div',{staticClass:"v-button__slot"},[_vm._t("default"),(_vm.loading)?_c('div',{staticClass:"v-spinner v-spinner-button",class:{ loading: _vm.loading }}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }