var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-card"},[_c('h2',[_vm._v(_vm._s(_vm.$t("overview_recent_activity_title")))]),(_vm.$store.state.companyAppId === 'all')?_c('div',{staticClass:"tabs-wrapper"},[_c('VTab',{attrs:{"activeTabName":"KYC","checkIfIsSelected":_vm.activeTabNameFlowTypeHighlights},on:{"onActiveTabChange":function($event){_vm.activeTabNameFlowTypeHighlights = $event}}},[_c('span',[_vm._v(_vm._s(_vm.$t("mainnav_users")))])]),_c('VTab',{attrs:{"activeTabName":"KYB","checkIfIsSelected":_vm.activeTabNameFlowTypeHighlights},on:{"onActiveTabChange":function($event){_vm.activeTabNameFlowTypeHighlights = $event}}},[_c('span',[_vm._v(_vm._s(_vm.$t("mainnav_companies")))])])],1):_vm._e(),(_vm.highLightsComputed && _vm.highLightsComputed.length)?[_c('ul',{staticClass:"highlights-list-wrapper"},_vm._l((_vm.highLightsComputed),function(highLight){return _c('li',{key:highLight.id,staticClass:"highlights-list-item",on:{"click":function($event){return _vm.clickAndNavigate(highLight)}}},[_c('i',{staticClass:"fad circle_status",class:_vm.getIconFromStatus(highLight).class}),_c('div',{staticClass:"highlights-list-item-name-date-wrapper"},[_c('span',{staticClass:"capitalized",domProps:{"innerHTML":_vm._s(
              _vm.$options &&
              _vm.$options.filters &&
              _vm.$options.filters.clean_cap_error(
                _vm.$t(highLight.name, {
                  name: _vm.getIconFromStatus(highLight).name,
                  status: _vm.getIconFromStatus(highLight).status,
                })
              )
            )}}),_c('span',{staticClass:"hint"},[_vm._v(_vm._s(_vm._f("date")(highLight.updated_at)))])])])}),0),_c('v-button',{staticClass:"transparent small",attrs:{"loading":_vm.loading},nativeOn:{"click":function($event){return _vm.$emit('loadMoreHighLigths')}}},[_vm._v(" "+_vm._s(_vm.$t("load_more_items_button"))+" ")])]:_c('span',[_vm._v(" "+_vm._s(_vm.$t("no_analytics_found"))+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }