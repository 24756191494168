import { formatUri } from "@/api";
import { HTTP } from "@/lib/http/api";

export default class CompaniesService {
  static async getCompany(companyId: string) {
    const res = await HTTP.get(formatUri`v1/companies/${companyId}`);
    return res;
  }

  static async getCompanyApp(companyAppId: string) {
    const res = await HTTP.get(formatUri`v1/company-apps/${companyAppId}`);
    return res;
  }

  static async deleteCompanyApp(companyAppId: string) {
    const res = await HTTP.delete(formatUri`v1/company-apps/${companyAppId}`, {
      headers: { "X-Company-App-Id": companyAppId },
    });
    return res;
  }

  static async getCompanies() {
  const res = await HTTP.get(formatUri`v1/companies/basic-info/`);
  return res;
}

  static async postCompany(data: any) {
    if (!data) throw { error: "No data Provided" };
    const res = await HTTP.post(formatUri`v1/companies/`, data);
    return res;
  }

  static async patchCompany(companyId: string, data: any) {
    if (!data) throw { error: "No data Provided" };
    const res = await HTTP.patch(formatUri`v1/companies/${companyId}`, data);
    return res;
  }

  static async patchCompanyLogo(companyId: string, data: any) {
    if (!data) throw { error: "No data Provided" };
    const res = await HTTP.patch(formatUri`v1/companies/${companyId}`, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return res;
  }

  static async postPermissionsToCompany(companyId: string, data: any) {
    if (!data) throw { error: "No data Provided" };
    const res = await HTTP.post(
      formatUri`v1/company-apps/${companyId}/users/`,
      data
    );
    return res;
  }

  static async getCompanyHighlights(companyId: string) {
    const res = await HTTP.get(formatUri`dashboard/companies/${companyId}/highlights/`);
    return res;
  }

  // APPS

  static async getFileTypes(type: string) {
    const res = await HTTP.get(formatUri`v2/files/types/${type}/`,);
    return res;
  }

  static async postCompanyApp(data: any) {
    if (!data) throw { error: "No data Provided" };
    const res = await HTTP.post(formatUri`v1/company-apps/`, data);
    return res;
  }

  static async patchCompanyApp(cappId: string, data: any) {
    if (!data) throw { error: "No data Provided" };
    const res = await HTTP.patch(formatUri`v1/company-apps/${cappId}`, data, {
      headers: { "X-Company-App-Id": cappId },
    });
    return res;
  }

  static async patchCompanyAppLogo(cappId: string, data: any) {
    if (!data) throw { error: "No data Provided" };
    const res = await HTTP.patch(formatUri`v1/company-apps/${cappId}`, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return res;
  }

  static async patchIdentityDocument(identityDocumentId: string, data: any) {
    if (!data) throw { error: "No data Provided" };
    const res = await HTTP.patch(
      formatUri`dashboard/users/identity-document/${identityDocumentId}/`,
      data
    );
    return res;
  }

  static async getCompanyAppHighlights(companyAppId: string) {
    const res = await HTTP.get(formatUri`dashboard/company-apps/${companyAppId}/highlights/`);
    return res;
  }
}
