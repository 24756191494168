

















































import Vue from "vue";
import PADetailVerificationInfo from "@/components/processing-attempts/pa-detail/components/components/PADetailVerificationInfo.vue";

export default Vue.extend({
  name: "PADetailMisconductDetail",
  props: {
    resourceDetail: { required: true, type: Object },
    statusOptions: { required: true },
  },
  components: { PADetailVerificationInfo: PADetailVerificationInfo },
  methods: {
    getMisconductSeverityNameFromValue(misconductSeverityValue: string) {
      if (misconductSeverityValue === "1") {
        return this.$t("misconduct_list_misconduct_severity_type_minor");
      } else if (misconductSeverityValue === "2") {
        return this.$t("misconduct_list_misconduct_severity_type_medium");
      } else if (misconductSeverityValue === "3") {
        return this.$t("misconduct_list_misconduct_severity_type_major");
      }
    },
    getSeverityClass(severityValue: string) {
      if (severityValue === "2") {
        return "pill-warning";
      } else if (severityValue === "3") {
        return "pill-error";
      } else {
        return "pill-grey";
      }
    },
  },
  data() {
    return {
      misconductHeaders: [
        {
          name: "date",
          text: this.$t("rules_list_hit_date"),
          class: "capitalized",
        },
        {
          name: "severity",
          text: this.$t("misconduct_list_misconduct_severity_title"),
          class: "capitalized",
        },
        {
          name: "type",
          text: this.$t(
            "misconduct_list_add_new_user_infringement_description"
          ),
          class: "capitalized",
        },
        {
          name: "description",
          text: this.$t("misconduct_list_description_title"),
          class: "capitalized",
        },
      ],
    };
  },
  computed: {
    cmeOutput(): Array<any> {
      return (
        (this.resourceDetail.cmes && this.resourceDetail.cmes[0].output) ||
        (this.resourceDetail.core_module_executions &&
          this.resourceDetail.core_module_executions[0].output)
      );
    },
  },
});
