import Vue from 'vue';
import moment from 'moment';

Vue.filter('date', (value: string | Date) => {
  const isDDMMYYYY = (val: string) => /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(val);
  if (value) {
    let formattedValue = value;

    if (typeof value === "string" && isDDMMYYYY(value)) {
      const [day, month, year] = value.split("/");
      formattedValue = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day)));
    }

    return moment(String(formattedValue)).format('DD/MM/YYYY');
  }
});

Vue.filter('dateTime', (value: string | Date) => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm');
  }
});

Vue.filter('time', (value: string | Date) => {
  if (value) {
    return moment(String(value)).format('HH:mm');
  }
});