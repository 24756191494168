import { render, staticRenderFns } from "./VDragAndDropDocument.vue?vue&type=template&id=3be52062&scoped=true&"
import script from "./VDragAndDropDocument.vue?vue&type=script&lang=ts&"
export * from "./VDragAndDropDocument.vue?vue&type=script&lang=ts&"
import style0 from "./VDragAndDropDocument.vue?vue&type=style&index=0&id=3be52062&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be52062",
  null
  
)

export default component.exports