













































import KYBFormModal from "@/components/kyb/KYBFormModal.vue";
import Vue from "vue";

export default Vue.extend({
  name: "HowToGettingStarted",
  components: { KYBFormModal },
  data() {
    return {
      signup_url: process.env.VUE_APP_SILT_SIGNUP_URL,
      isKYC: this.$store.state.verificationFlow.flow_type === "UDV",
      showKYBFormModal: false,
    };
  },
  methods: {
    openKYBModal() {
      this.showKYBFormModal = true;
    },
    openKYCVerificationLink() {
      const url = `${this.signup_url}/?company_app_id=${this.$store.state.verificationFlow.app_id}&hide_redirect_button=true`;
      window.open(url, "_blank");
    },
  },
});
