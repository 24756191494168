var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Processing Attempts")]),_c('div',[_c('v-table',{staticClass:"clickable",attrs:{"headers":_vm.headers,"getRowsData":_vm.getProcessingAttempts,"searchParamsProp":_vm.searchParamsPropString,"hideDetails":true},on:{"onRowClicked":function($event){return _vm.showAndGetResourceDetail($event)}},scopedSlots:_vm._u([{key:"owner_company_app_id",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm._f("clipped")(slotProps.item.owner_company_app_id,7))+" ")]}},{key:"document_type",fn:function(slotProps){return (slotProps.item.permission_type || slotProps.item.type)?[_c('i',{key:slotProps.item.id,class:("v-icon fad " + (_vm.VerificationsMap[
              _vm.VERIFICATIONS_TYPES_MAP[
                slotProps.item.permission_type || slotProps.item.type
              ]
            ].icon) + " large status_" + (slotProps.item.status))}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(slotProps.item.owner_user.country))])]:undefined}},{key:"manual_review",fn:function(slotProps){return [_c('span',{class:("small " + (slotProps.item.manual_review_status))},[_vm._v(_vm._s(_vm._f("clean_cap")(slotProps.item.manual_review_status || "-")))])]}},{key:"errors",fn:function(slotProps){return _vm._l((_vm.getUniqueErrorsArrayFromCoreModules(
            slotProps.item.core_module_executions
          )),function(error,i){return _c('span',{key:i,staticClass:"ERROR",staticStyle:{"margin-right":"2px"}},[_vm._v(_vm._s(_vm._f("clean_cap")(error))+" ")])})}},{key:"name",fn:function(slotProps){return [(
            slotProps.item &&
            slotProps.item.owner_user &&
            slotProps.item.owner_user.first_name &&
            slotProps.item.owner_user.last_name
          )?_c('span',[_vm._v(" "+_vm._s(slotProps.item.owner_user.first_name.toLowerCase())+" "+_vm._s(slotProps.item.owner_user.last_name.toLowerCase())+" ")]):_c('span',[_vm._v("-")])]}},{key:"updated_at",fn:function(slotProps){return [_vm._v(_vm._s(_vm._f("dateTime")(slotProps.item.updated_at)))]}}],null,true)},[_c('v-button',{staticClass:"light",class:{ active: _vm.only_manual_review == '1' },attrs:{"slot":"v-table__options","small":""},nativeOn:{"click":function($event){return _vm.switchManualReview()}},slot:"v-table__options"},[_vm._v(" Pending Manual Review ")])],1)],1),_c('PAModal',{attrs:{"loadingProp":_vm.loadingResourcePictures,"processingAttempts":_vm.processingAttempts,"resource":_vm.resourceDetail,"user":_vm.userDetail,"showResourceDetail":_vm.showResourceDetail},on:{"onUpdateResource":function($event){return _vm.updateResource($event)},"close":function($event){_vm.showResourceDetail = false},"onGoToPreviousOrNextUser":function($event){return _vm.showAndGetResourceDetail($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }