


































































import {
  Component,
  Vue,
  Model,
  Prop,
  Mixins,
  Watch,
} from "vue-property-decorator";
import VInputMixin from "./VInputMixin";
import ClickOutside from "vue-click-outside";
import { TranslateResult } from "vue-i18n";

export interface VSelectOptions {
  text: string;
  value: string;
}

@Component({
  inheritAttrs: false,
  directives: {
    ClickOutside,
  },
})
export default class VSelect extends Mixins(VInputMixin) {
  @Prop()
  options!: VSelectOptions[];
  @Prop()
  inputClass?: "";
  @Prop({ default: 0, type: Number })
  clip?: Number;
  showOptions: boolean = false;

  set inputValue(val: string | number | undefined) {
    this.$emit("change", val);
  }

  get inputValue() {
    return this.value;
  }

  get valueText(): string | TranslateResult {
    for (let option of this.options) {
      if (option.value === this.inputValue) {
        if (!option.value) {
          return "";
        } else return option.text;
      }
    }
    return "";
  }

  hideOptions() {
    this.showOptions = false;
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }
}
