
































import Vue from "vue";
import VTabSkeleton from "../components/VTabSkeleton.vue";

export default Vue.extend({
  components: { VTabSkeleton },
  name: "AnalyticsAllVerificationFlowsSkeleton",
});
