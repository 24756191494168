


































































































































































































































































































import Vue from "vue";
import KYBService from "@/api/kyb/kybService";
import KYBFormModalSearchCompany from "./components/KYBFormModalSearchCompany.vue";
import { gmapApi } from "vue2-google-maps";
import KYBCompanyDetailsInfoSimplified from "./components/KYBCompanyDetailsInfoSimplified.vue";

export default Vue.extend({
  components: { KYBFormModalSearchCompany, KYBCompanyDetailsInfoSimplified },
  name: "KYBCompanyInfo",
  props: {
    companyInfoDetails: {
      required: true,
      default: () => ({} as any),
    },
    simplified: { required: false, default: false },
    kybCompanyId: { required: false, type: String, default: "" },
    hasContact: { required: true, type: Boolean },
  },
  computed: {
    hasGmaps(): Boolean {
      return (
        !!this.companyInfoDetails.latitude &&
        !!this.companyInfoDetails.longitude
      );
    },
    google: gmapApi,
    registeredAddress(): string {
      const { registered_address } = this.companyInfoDetails;

      if (!registered_address || !registered_address.street) {
        return "-";
      }

      const street =
        typeof registered_address.street === "string"
          ? registered_address.street.toLowerCase()
          : [
              registered_address.street.line1,
              registered_address.street.line2,
              registered_address.street.line3,
              registered_address.street.line4,
            ]
              .find((line) => line)
              ?.toLowerCase() || "";

      const { locality = "", country = "" } = registered_address;
      return `${street}, ${locality}, ${country}`.trim();
    },
    primaryAddress(): string {
      if (
        !this.companyInfoDetails.primary_address ||
        !this.companyInfoDetails.primary_address.street
      )
        return "-";
      return (
        this.companyInfoDetails.primary_address.street.toLowerCase() +
        ", " +
        this.companyInfoDetails.primary_address.locality +
        ", " +
        this.companyInfoDetails.primary_address.country
      );
    },
  },
  data() {
    return {
      activeCurrency: "EUR",
      activeKYBCompany: null as any,
      loading: false,
    };
  },
  methods: {
    async patchKYBCompany() {
      try {
        this.loading = true;
        await KYBService.patchKYBCompany(this.kybCompanyId, {
          company_info_id: this.activeKYBCompany.id,
        });
        this.$emit("getKYBCompanyDetail");
      } catch (error) {
        this.$root.$emit("v-toast", {
          localizedKey: "unsuccessful_create_kyb_company_feedback",
          type: "error-toast",
        });
      } finally {
        this.loading = false;
        this.$root.$emit("amplitude", "KYBModal - Set KYBCompany Clicked");
      }
    },
  },
});
