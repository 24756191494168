import { formatUri } from "@/api";
import { HTTP } from "@/lib/http/api";

export default class MisconductListService {

  static async getMisconductTypes() {
    const res = await HTTP.get(formatUri`dashboard/misconduct/types/`);
    return res;
  }
  
  static async postMisconduct(
    companyId: string,
    typeId: string,
    userId: string,
    severity: 1 | 2 | 3,
    description: string
  ) {
    const res = await HTTP.post(
      formatUri`dashboard/companies/${companyId}/misconduct/?type_id=${typeId}&user_id=${userId}&severity=${severity}`,
      {
        description: description,
      }
    );
    return res;
  }
  
  static async getMisconducts(companyId: string) {
    const res = await HTTP.get(
      formatUri`dashboard/companies/${companyId}/misconduct/`
    );
    return res;
  }
  
}
