












import Vue from "vue";

export default Vue.extend({
  name: "HowToUsersAndCompanies",
  data() {
    return {};
  },
  methods: {},
});
