




































import { Vue } from "vue-property-decorator";
import {
  getUniqueErrorsArrayFromCoreModules,
  toLowerCase,
} from "@/lib/parsers";
import {
  VerificationsMap,
  VERIFICATIONS_TYPES_MAP,
  VERIFICATIONS_TYPES_VIEW_MAP,
} from "@/models/verificationsTypes";
import ListModalHeader from "@/components/modals/ListModalHeader.vue";
import PADetail from "@/components/processing-attempts/pa-detail/PADetail.vue";
import UserStatusInfo from "@/components/users/UserStatusInfo.vue";

export default Vue.extend({
  name: "pa-modal",
  props: {
    showResourceDetail: { required: true, type: Boolean },
    resource: { required: false, type: Object },
    user: { required: false, type: Object as () => any },
    loadingProp: { required: false, type: Boolean },
    processingAttempts: { required: true, type: Array as () => Array<any> },
  },
  components: {
    PADetail,
    ListModalHeader,
    UserStatusInfo,
  },
  watch: {
    resourceDetail: {
      handler(val) {
        if (val && val.status == "MANUAL_REVIEW") {
          this.resourceDetail.status = "PENDING";
        }
      },
      deep: true,
      immediate: true,
    },
    showResourceDetail: {
      async handler(val) {
        if (val) {
          await this.handleResourceDetailChange(val);
        }
      },
      immediate: true,
    },
    user: {
      async handler(val) {
        if (val) {
          await this.handleResourceDetailChange(val);
        }
      },
      immediate: true,
    },
    resource: {
      async handler(val) {
        if (val) {
          await this.handleResourceDetailChange(val);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      dashboardUrl: process.env.VUE_APP_SILT_DASHBOARD_URL,
      resourceDetail: { ocr_data: {} } as any,
      palink: "",
      VerificationsMap: VerificationsMap,
      VERIFICATIONS_TYPES_MAP: VERIFICATIONS_TYPES_MAP,
      VERIFICATIONS_TYPES_VIEW_MAP: VERIFICATIONS_TYPES_VIEW_MAP,
      loading: false,
      editing: false,
      loadingRerun: false,
      statusOptions: [
        { value: "SUCCESS" },
        { value: "ERROR" },
        { value: "PENDING" },
      ],

      companyAppSelectedPermissions: [] as any[],
      checksRequirements: [] as any[],
      resourcesRequirements: [] as any[],
      resourcesProcessingAttempts: [] as any[],
      checksProcessingAttempts: [] as any[],
    };
  },
  methods: {
    async handleResourceDetailChange(val: any) {
      if (val) {
        this.loading = true;
        await this.getResourceDetail();
        this.palink = window.location.href;
        this.loading = false;
      }
    },
    updateResource(event: Event) {
      this.$emit("onUpdateResource", event);
    },

    getUniqueErrorsArrayFromCoreModules(cm_exec: Array<string>) {
      return getUniqueErrorsArrayFromCoreModules(cm_exec);
    },

    // TODO: Delete after backend unifies naming for surname and last_name
    cleanOCRData(ocr_data: any) {
      if (!ocr_data) return;
      if (ocr_data.name) ocr_data.first_name = ocr_data.name;
      if (ocr_data.surname) ocr_data.last_name = ocr_data.surname;
      if (ocr_data.number) ocr_data.document_number = ocr_data.number;
    },

    async getResourceDetail(resourceClicked?: any) {
      let sourceResource = resourceClicked || this.resource;
      if (
        sourceResource &&
        this.resourceDetail &&
        ((sourceResource.id && sourceResource.id == this.resourceDetail.id) ||
          (sourceResource.processing_attempt_id &&
            sourceResource.processing_attempt_id ==
              this.resourceDetail.processing_attempt_id))
      ) {
        return;
      }
      this.resourceDetail = sourceResource;
      if (this.resourceDetail) {
        const cmes =
          this.resourceDetail.cmes ||
          this.resourceDetail.core_module_executions;
        if (cmes && cmes.length) {
          for (const cm_exec of cmes) {
            if (cm_exec.type === "OCR") {
              this.resourceDetail.ocr_data = cm_exec.output || {};
              this.resourceDetail.ocr_raw = cm_exec.raw || {};
              this.resourceDetail.ocr_status = cm_exec.status;
            }
            if (cm_exec.type === "FACE_MATCHER") {
              this.resourceDetail.verification_status = cm_exec.status;
            }
          }
          this.cleanOCRData(this.resourceDetail.ocr_data);
          this.resourceDetail.errors =
            this.getUniqueErrorsArrayFromCoreModules(cmes);
        }
        this.resourceDetail = toLowerCase(this.resourceDetail, {
          include: ["name", "address", "city", "province"],
        });
      }
    },
  },
});
