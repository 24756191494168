var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.companyHasExtraDocuments)?_c('div',{staticClass:"upload-document-button-wrapper"},[_c('v-button',{staticClass:"small",class:{ disabled: _vm.KYBCompanyDetail.legal_acceptance_date },nativeOn:{"click":function($event){$event.stopPropagation();_vm.showUploadDocumentModal = true}}},[_c('span',[_vm._v(_vm._s(_vm.$t("companies_detail_files_upload_documents_button")))])])],1):_vm._e(),(_vm.loadingProp || _vm.loading)?_c('div',{staticClass:"v-spinner",class:{ loading: _vm.loadingProp || _vm.loading }}):[_c('div',{staticClass:"extra-documents-tabs"},[(_vm.extraDocuments.length)?_c('div',{staticClass:"tabs-wrapper"}):_vm._e()]),(_vm.extraDocuments.length)?_c('div',[(_vm.activeTabName === 'companySummary')?[_c('KYBExtraDocumentsCompanySummary')]:_vm._e(),(_vm.activeTabName === 'perDocument')?[_c('KYBExtraDocumentsListTabs',{attrs:{"extraDocuments":_vm.extraDocumentsFiltered,"companyHasLegalAcceptance":!!_vm.KYBCompanyDetail.legal_acceptance_date},on:{"openPDFViewer":function($event){(_vm.extraDocumentSelected = $event), (_vm.showPDF = true)},"showDeleteModal":function($event){(_vm.showDeleteExtraDocumentModal = true),
              (_vm.extraDocumentSelected = $event),
              (_vm.showPDF = false)}}}),(
            _vm.extraDocumentSelected &&
            _vm.extraDocumentSelected.dynamicFileTypeName &&
            _vm.extraDocumentSelected.display_name
          )?_c('div',{staticClass:"extra-document-header-wrapper"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t(_vm.extraDocumentSelected.dynamicFileTypeName))+" - "+_vm._s(_vm.extraDocumentSelected.display_name)+" ")])]):_vm._e(),(
            _vm.extraDocumentSelected.cmes &&
            _vm.extraDocumentSelected.cmes.output &&
            _vm.extraDocumentSelected.cmes.output.data &&
            _vm.extraDocumentSelected.cmes.output.data.document &&
            _vm.extraDocumentSelected.cmes.output.data.document.abstract
          )?_c('div',{staticClass:"context abstract-wrapper"},[_c('h4',[_vm._v(_vm._s(_vm.$t("companies_detail_files_document_description")))]),_c('p',[_vm._v(" "+_vm._s(_vm.extraDocumentSelected.cmes.output.data.document.abstract)+" ")])]):_vm._e(),(_vm.showPDF && _vm.extraDocumentSelected)?_c('KYBExtraDocumentsPDFDetail',{attrs:{"pdfUrl":_vm.extraDocumentSelected.url,"extraDocument":_vm.extraDocumentSelected}}):_vm._e()]:_vm._e()],2):_c('div',{staticClass:"context abstract-wrapper"},[_c('span',[_vm._v(_vm._s(_vm.$t("companies_detail_files_no_documents_uploaded")))])]),_c('KYBDashboardUploadModal',{attrs:{"companyHasExtraDocuments":_vm.companyHasExtraDocuments,"companyHasLegalAcceptance":!!_vm.KYBCompanyDetail.legal_acceptance_date,"loadingProp":_vm.loadingProp,"showUploadDocumentModal":_vm.showUploadDocumentModal,"handleError":_vm.handleError,"processExtraDocuments":_vm.processExtraDocuments,"extraDocuments":_vm.extraDocuments},on:{"showeDashboadUploadModal":function($event){_vm.showUploadDocumentModal = $event}}}),_c('KYBDeleteExtraDocumentModal',{attrs:{"extraDocuments":_vm.extraDocuments,"extraDocumentSelected":_vm.extraDocumentSelected,"handleError":_vm.handleError,"processExtraDocuments":_vm.processExtraDocuments,"showDeleteExtraDocumentModal":_vm.showDeleteExtraDocumentModal},on:{"showeDeleteExtraDocumentModal":function($event){_vm.showDeleteExtraDocumentModal = $event}}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }