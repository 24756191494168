














































































import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";
import ClickOutside from "vue-click-outside";

export interface VSelectOptions {
  text: string;
  value: string;
}

@Component({
  inheritAttrs: false,
  directives: {
    ClickOutside,
  },
})
export default class VSelectSearch extends Mixins(VInputMixin) {
  @Prop()
  options!: VSelectOptions[];
  @Prop({ default: false })
  multipleValues?: boolean;
  @Prop({ default: "bottom-start", type: String })
  popoverPlacement?: string;
  @Prop({ default: 0, type: Number })
  clip?: Number;
  showOptions: boolean = false;
  filterSearchOptions: string = "";
  values: any[] = [];

  get valueText() {
    for (let option of this.options) {
      if (option.value === this.value) {
        return option.text;
      }
    }
  }

  set inputValue(val: string | number | undefined | string[]) {
    this.$emit("change", val);
  }

  get inputValue() {
    return this.value;
  }

  addMultipleValues(option: string) {
    if (this.values.some((value) => value === option)) {
      const updatedValues = this.values.filter((value) => value !== option);
      this.values = updatedValues;
    } else {
      this.values.push(option);
    }

    this.inputValue = this.values;
  }

  filterOptions() {
    const filterResult = this.options.filter((filterWord) =>
      filterWord.text
        .toLowerCase()
        .includes(this.filterSearchOptions.toLowerCase())
    );

    return filterResult;
  }

  hideOptions() {
    this.showOptions = false;
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;

    const searchField = this.$refs.searchField as HTMLElement;

    if (this.showOptions) {
      this.$nextTick(() => {
        if (searchField) searchField.focus();
      });
    }
  }
}
