import { getToken } from "@/lib/http/api";
import { State } from "./store";
export const getters = {
    isVerificationFlowRemovable(state: State) {
        return (
            state.company &&
            state.company.company_apps &&
            state.company.company_apps.length > 1
        );
    },
    isDesktop() {
        return !/Mobi|iPad/.test(window.navigator.userAgent);
    },
    isLoggedIn() {
        return !!getToken()
    },
    isReadOnlyReviewer(state: State) {
        const readOnlyReviewersCompaniesIds = ["13fd7594-3de3-418e-8899-849a89b64b5b", "7ec31798-3924-4203-96f8-7f083ecbe68e"]
        return state.userRole === 'REVIEWER' && readOnlyReviewersCompaniesIds.includes(state.company.id)
    }
};
