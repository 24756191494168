import { render, staticRenderFns } from "./RepresentativeStatusInfo.vue?vue&type=template&id=2e4948b6&scoped=true&"
import script from "./RepresentativeStatusInfo.vue?vue&type=script&lang=ts&"
export * from "./RepresentativeStatusInfo.vue?vue&type=script&lang=ts&"
import style0 from "./RepresentativeStatusInfo.vue?vue&type=style&index=0&id=2e4948b6&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e4948b6",
  null
  
)

export default component.exports