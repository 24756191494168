













import Vue from "vue";

export default Vue.extend({
  name: "VTabRadioButton",
  props: {
    activeTabName: { required: true },
    checkIfIsSelected: { required: true },
  },
});
