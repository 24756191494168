import Vue from 'vue';

// Fields
import './vuemarc-ui-kit';

// TODO: Inline svg Icons
// import VIcon from './icons/VIcon.vue';
// Vue.component('v-icon', VIcon);

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import OnelineVerifications from './OnelineVerifications.vue'
Vue.component("oneline-verifications", OnelineVerifications)