<template>
  <div v-if="AmlInfo && AmlInfo.status" class="aml-container">
    <span class="field-label">{{ title }}</span>
    <span class="capitalized" :class="`status_${AmlInfo.status}`">{{
      AmlInfo.status | clean_cap
    }}</span>
    <span class="hint">{{ AmlInfoUpdatedAt | date }}</span>
  </div>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  name: "PADetailAmlPepSumup",
  props: {
    AmlInfo: { required: true },
    AmlInfoUpdatedAt: { required: true },
    title: { required: false },
  },
});
</script>
<style lang="sass" scoped>
@import '~@/styles/global/theme.sass'
.aml-container
  &>span
    margin-right: $spacing-sm
  .aml-results
    display: flex
    flex-wrap: wrap
    padding: $spacing-md
    max-height: 250px
    overflow: auto
    .aml-results__result
      margin-bottom: $spacing-md
      margin-right: $spacing-md
      flex-basis: 50%

    h5
      margin-bottom: $spacing-sm/2
</style>