








































import Vue from "vue";

export default Vue.extend({
  name: "CDPListBlockDocumentsModal",
  props: {
    showBlockDocuments: { required: true, type: Boolean },
    blockedDocumentsLength: { required: true, type: Number },
  },
  watch: {
    showBlockDocuments() {
      this.blockedDocumentsNumber = "1";
    },
  },
  data() {
    return {
      blockedDocumentsNumber: "1",
      maxBlockedDocuments: 20,
    };
  },
  computed: {
    blockedDocumentsNumberOptions(): Array<any> {
      const start = 1;
      const availableSlots =
        this.maxBlockedDocuments - this.blockedDocumentsLength;

      return Array.from({ length: availableSlots }, (_, i) => ({
        value: (start + i).toString(),
      }));
    },
  },
  methods: {},
});
