








































import Vue from "vue";

export default Vue.extend({
  name: "VDragAndDropDocument",
  props: {
    loading: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      uploadedFiles: [] as File[],
    };
  },
  watch: {
    uploadedFiles: {
      handler(newVal) {
        this.$emit("dragAndDropSubmitted", newVal);
      },
      deep: true,
    },
  },
  methods: {
    handleDrop(event: DragEvent) {
      event.preventDefault();
      const files = event.dataTransfer?.files;
      if (files) {
        this.uploadFiles(files);
      }
    },
    handleFileChange(event: Event) {
      const input = event.target as HTMLInputElement;
      const files = input.files;
      if (files) {
        this.uploadFiles(files);
      }
    },
    uploadFiles(files: FileList) {
      for (let i = 0; i < files.length; i++) {
        this.uploadedFiles.push(files[i]);
      }
    },
    removeFile(index: number) {
      this.uploadedFiles.splice(index, 1);
    },
  },
});
