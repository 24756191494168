import Vue from "vue";
import dayjs from "dayjs";

export function emailErrors(email: string, scope: Vue) {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(String(email).toLowerCase()))
    return scope.$t("ERROR_INVALID_EMAIL") as string;
  //if (this.responseMessage) return this.responseMessage;
  return null;
}

export function isValidDateFormat(dateString:string) {
  const parsedDate = dayjs(dateString, { format: "DD/MM/YYYY"});

  return parsedDate.isValid();
}