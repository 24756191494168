






















import Vue from "vue";
import VPlaceholder from "../../vuemarc-ui-kit/placeholder/VPlaceholder.vue";
import { TranslateResult } from "vue-i18n";
import VTabSkeleton from "../components/VTabSkeleton.vue";

export default Vue.extend({
  components: { VPlaceholder, VTabSkeleton },
  name: "HomeAnalyticsCardSkeleton",
  props: {
    startDate: { required: true, type: [String, Date] },
    endDate: { required: true, type: [String, Date] },
  },
  computed: {
    computedTitle(): TranslateResult {
      if (this.$store.state.companyAppId === "all") {
        return this.$t("all_company_apps");
      } else if (this.$store.state.verificationFlow.flow_type === "KYB") {
        return this.$t("overview_total_new_companies_title");
      } else if (this.$store.state.verificationFlow.flow_type === "CDP") {
        return this.$t("overview_usage_this_month_title");
      } else {
        return this.$t("overview_total_new_users_title");
      }
    },
  },
});
