import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";
export interface Money {
  amount: number;
  currency: string;
}

interface Limits {
  limit?: 15 | 60 | 100 | 200 | 500 | 1000;
}

@Component({})
export default class VInputMixin extends Vue {
  @Model("change")
  value!: any;
  @Prop()
  label?: string;
  @Prop({ type: Boolean })
  required?: boolean;
  @Prop({ default: null })
  errors?: string;
  @Prop({ default: true })
  hideErrors?: boolean;
  @Prop()
  details?: string;
  @Prop({ type: Number, default: 0 })
  debounce?: number;
  @Prop({ type: Number, default: 100 })
  limit?: Limits['limit'];

  @Watch("value")
  onValueChange() {
    if (this.limit && this.value && this.value.length > this.limit)
      this.$emit("change", this.value.slice(0, this.limit));
  }

  dirty = false;
  focused = false;
  debounceTimer?: ReturnType<typeof setTimeout>;

  debounceInput(callback: Function) {
    if(this.debounceTimer) clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      callback();
    }, this.debounce || 0);
  };

  onFocus() {
    this.focused = true;
  }

  get isEmpty() {
    return !(this.value || this.value === 0);
  }


  onBlur() {
    this.$emit("blur");
    this.dirty = true;
    this.focused = false;
    if (this.$refs.vinput) (this.$refs.vinput as any).onBlur();
  }
}
