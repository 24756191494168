








import Vue from "vue";

export default Vue.extend({
  name: "VFlag",
  props: {
    isoCode: { required: true, type: String },
    countriesList: { required: true, type: Array as () => Array<any> },
  },
  computed: {
    countrySelected(): any {
      for (const country of this.countriesList) {
        if (country.value === this.isoCode) {
          return country;
        }
      }
    },
  },
});
