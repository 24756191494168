var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs-wrapper",class:_vm.resourcesAttemptsFiltered.length
      ? 'tabs-wrapper__resources'
      : 'tabs-wrapper__checks'},_vm._l((_vm.resourcesAttemptsFiltered.length
      ? _vm.resourcesAttemptsFiltered
      : _vm.checksAttemptsFiltered),function(processingAttempt,i){return _c('div',{key:processingAttempt.processing_attempt_id,staticClass:"attempts-card",class:[
      ("status_" + (processingAttempt.status)),
      processingAttempt.processing_attempt_id ===
      _vm.resourceDetail.processing_attempt_id
        ? 'active'
        : '' ],on:{"click":function($event){return _vm.$emit('getResourceDetail', processingAttempt)}}},[_c('h4',{staticClass:"attempts-header-type"},[_vm._v(" "+_vm._s(_vm.$t("users_detail_attempt_number", { number: _vm.resourcesAttemptsFiltered.length ? _vm.resourcesAttemptsFiltered.length - i : _vm.checksAttemptsFiltered.length - i, }))+" ")])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }