

































import Vue, { PropType } from "vue";

export default Vue.extend({
  components: {},
  name: "AppDetailEmailReminders",
  props: {
    emailRemindersPropValue: {
      required: true,
      type: null as unknown as PropType<string | number | null>,
    },
  },
  data() {
    return {
      emailRemindersOptions: [
        {
          value: null,
          text: this.$t(
            "verification_flow_settings_verification_requirements_kyb_reminder_emails_config_type_1"
          ),
        },
        {
          value: "1",
          text: this.$t(
            "verification_flow_settings_verification_requirements_kyb_reminder_emails_config_type_2"
          ),
        },
        {
          value: "3",
          text: this.$t(
            "verification_flow_settings_verification_requirements_kyb_reminder_emails_config_type_3"
          ),
        },
        {
          value: "7",
          text: this.$t(
            "verification_flow_settings_verification_requirements_kyb_reminder_emails_config_type_4"
          ),
        },
      ],
      emailRemindersValue: this.emailRemindersPropValue,
    };
  },
  computed: {},
  watch: {
    emailRemindersValue: {
      handler(newVal: Number) {
        this.$emit("emailRemindersValueUpdated", newVal);
      },
      immediate: true,
    },
  },
  methods: {},
  async mounted() {},
});
