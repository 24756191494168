import { render, staticRenderFns } from "./KYBIgnoreStatus.vue?vue&type=template&id=40cb2246&scoped=true&"
import script from "./KYBIgnoreStatus.vue?vue&type=script&lang=ts&"
export * from "./KYBIgnoreStatus.vue?vue&type=script&lang=ts&"
import style0 from "./KYBIgnoreStatus.vue?vue&type=style&index=0&id=40cb2246&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40cb2246",
  null
  
)

export default component.exports