



























































import Vue from "vue";

export default Vue.extend({
  name: "AppDetailIntegrationInfo",
  props: {
    companyApp: { required: true, type: Object },
  },
  data() {
    return {
      copiedHash: {} as any,
    };
  },
  methods: {
    copy(text: string) {
      navigator.clipboard.writeText(text).then(() => {
        this.copiedHash[text] = true;
      });
    },
  },
});
