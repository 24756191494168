export type VerificationStatus =
  | "VERIFIED"
  | "RUNNING"
  | "SUCCESS"
  | "VERIFICATION_ERROR"
  | "MANUAL_REVIEW"
  | "ERROR"
  | "BLOCKED";
export type VerificationStatusSimple = "SUCCESS" | "PENDING" | "ERROR";
export const VERIFICATION_STATUS_MAP = {
  PENDING: "PENDING",
  RUNNING: "RUNNING",
  SUCCESS: "SUCCESS",
  VERIFICATION_ERROR: "VERIFICATION_ERROR",
  MANUAL_REVIEW: "MANUAL_REVIEW",
  ERROR: "ERROR",
  BLOCKED: "BLOCKED",
};

export const FILE_STATUS = {
  CREATING: "CREATING",
  CREATED: "CREATED",
  READY: "READY",
  LOST: "LOST"
};

export const USER_STATUSES = {
  SUCCESS: "SUCCESS",
  PENDING: "PENDING",
  MANUAL_REVIEW: "MANUAL_REVIEW",
  ERROR: "ERROR",
};

export const CDP_STATUSES = {
  APPROVED: "APPROVED",
  PENDING: "PENDING",
  MANUAL_REVIEW: "MANUAL_REVIEW",
  ERROR: "ERROR",
  DISCARDED: "DISCARDED",
};

export const COMPANY_STATUSES = {
  SUCCESS: "SUCCESS",
  IN_PROGRESS: "IN_PROGRESS",
  MANUAL_REVIEW: "MANUAL_REVIEW",
  MANUAL_ERROR: "MANUAL_ERROR",
  SCREENING: "SCREENING"
};

export const PA_STATE_MAP = VERIFICATION_STATUS_MAP;