import { formatUri } from "@/api";
import { getToken, HTTP } from "@/lib/http/api";
import { actionsT } from "@/state/actions";
import store from "@/state/store";
import axios from "axios";
export default class UsersService {
  static async postResource(data: any) {
    if (!data) throw { error: "No data Provided" };
    const res = await HTTP.post(formatUri`v1/resources/`, data);
    return res;
  }

  static async getUser(userId = "self") {
    let headers = undefined;
    if (store.state.companyAppId && store.state.companyAppId !== "all") {
      headers = {
        headers: {
          "X-Company-App-Id": store.state.companyAppId,
        },
      };
    }
    const res = await HTTP.get(formatUri`v1/users/${userId}/`, headers);
    return res;
  }

  static async getUsersOfCompany(
    params: URLSearchParams = new URLSearchParams()
  ) {
    const paramsString = !!params.toString() ? "?" + params : "";
    if (store.state.companyAppId == "all") {
      return;
    } else {
      const res = await HTTP.get(
        formatUri`dashboard/company-apps/${store.state.companyAppId}/users/` +
          paramsString
      );
      return res.data;
    }
  }

  static async getTotalVerifications(
    params: URLSearchParams = new URLSearchParams()
  ) {
    const paramsString = !!params.toString() ? "?" + params : "";
    const res = await HTTP.get(
      formatUri`v1/company-apps/${store.state.companyAppId}/users/dashboard/` +
        paramsString
    );
    return res;
  }

  // Deactivated due to Backend performance issues
  // static async getTotalVerificationsAllCompanies(
  //   params: URLSearchParams = new URLSearchParams()
  // ) {
  //   const paramsString = !!params.toString() ? "?" + params : "";
  //   const { data } = await HTTP.get(
  //     formatUri`dashboard/companies/verifications/` + paramsString
  //   );

  //   return data;
  // }

  static async getTotalVerificationsAllCompanies(
    params: URLSearchParams = new URLSearchParams()
  ) {
    let requestBuffer = [];
    let resAccumulated: Array<any> = [];

    async function requestVerificationsFromCompanyBuffer(
      requestBuffer: Array<string>
    ) {
      const res = await Promise.all(
        requestBuffer.map((c) =>
          UsersService.getTotalVerificationsFromCompany(params, c)
        )
      );
      const responseResults = res.map((c) => {
        return {
          name: c.name || c.company,
          id: c.id,
          verifications: c.company_apps.reduce(
            (accumulator: Array<any>, currentValue: any) => {
              const verificationsArray = [];
              for (let verification of currentValue.verifications) {
                const accVerification =
                  accumulator &&
                  accumulator.find(
                    (accVerification: any) =>
                      verification.type === accVerification.type
                  );
                const from =
                  (accVerification &&
                    accVerification.time_periods &&
                    accVerification.time_periods[0] &&
                    accVerification.time_periods[0].from) ||
                  (verification.time_periods &&
                    verification.time_periods[0] &&
                    verification.time_periods[0].from);
                const to =
                  (accVerification &&
                    accVerification.time_periods &&
                    accVerification.time_periods[0] &&
                    accVerification.time_periods[0].to) ||
                  (verification.time_periods &&
                    verification.time_periods[0] &&
                    verification.time_periods[0].to);
                if (!from || !to) {
                  verificationsArray.push({
                    type: verification.type,
                    time_periods: [],
                  });
                } else {
                  verificationsArray.push({
                    type: verification.type,
                    time_periods: [
                      {
                        from,
                        to,
                        count:
                          ((accVerification &&
                            accVerification.time_periods &&
                            accVerification.time_periods[0] &&
                            accVerification.time_periods[0].count) ||
                            0) +
                          ((verification.time_periods &&
                            verification.time_periods[0] &&
                            verification.time_periods[0].count) ||
                            0),
                      },
                    ],
                  });
                }
              }
              return verificationsArray;
            },
            []
          ),
        };
      });
      return responseResults;
      // return res
      //   .map((c) =>
      //     c.company_apps.map((capp: any) => {
      //       return {
      //         name: c.company || capp.company_app,
      //         id: c.id,
      //         capp_id: capp.id,
      //         verifications: capp.verifications,
      //       };
      //     })
      //   )
      //   .flat();
    }

    for (let [index, company] of store.state.companies.entries()) {
      requestBuffer.push(company.id);
      if (
        requestBuffer.length > 5 ||
        index === store.state.companies.length - 1
      ) {
        const res = await requestVerificationsFromCompanyBuffer(requestBuffer);
        resAccumulated = [...resAccumulated, ...res];
        requestBuffer = [];
      }
    }

    return { companies: resAccumulated };
  }

  static async getTotalVerificationsFromCompany(
    params: URLSearchParams = new URLSearchParams(),
    companyId: string
  ) {
    const paramsString = !!params.toString() ? "?" + params : "";
    // TODO: Create HTTP & HTTPCapp because of X-Company-App-Id header
    const { data } = await axios.get(
      formatUri`dashboard/companies/${companyId}/company-apps/verifications/` +
        paramsString,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
    );

    return data;
  }

  static async getUserStatus(user_id: string, companyAppId?: string) {
    if (!user_id) throw new Error("No user id provided");
    if (store.state.companyAppId == "all") {
      // TODO: use Global HTTP instance when Backend accepts queries without X-Company-App-Id
      const res = await axios.get(
        formatUri`dashboard/users/${user_id}/status/`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "X-Company-App-Id": companyAppId,
          },
        }
      );
      return res;
    }
    const res = await HTTP.get(formatUri`dashboard/users/${user_id}/status/`);
    return res;
  }

  static async getUserProcessingAttempts(user_id: string) {
    if (!user_id) throw new Error("No user id provided");
    const res = await HTTP.get(
      formatUri`v1/users/${user_id}/processing-attempts`
    );
    return res;
  }
}
