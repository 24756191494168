



























































































import { Component, Vue, Watch } from "vue-property-decorator";
import ProcessingAttemptsService from "@/api/processingAttempts/processingAttemptsService";
import { VTableHeader } from "@/components/vuemarc-ui-kit/tables/VTable.vue";
import ResourcesService from "@/api/resources/resourcesService";
import {
  VerificationsMap,
  VERIFICATIONS_TYPES_MAP,
} from "@/models/verificationsTypes";
import { getUniqueErrorsArrayFromCoreModules } from "@/lib/parsers";
import PAModal from "./PAModal.vue";
import UsersService from "@/api/users/usersService";

@Component({ components: { PAModal } })
export default class ProcessingAttempts extends Vue {
  VERIFICATIONS_TYPES_MAP = VERIFICATIONS_TYPES_MAP;

  headers: VTableHeader[] = [
    { name: "owner_company_app_id", text: "CApp", class: "shrinked" },
    {
      name: "document_type",
      text: "Document Type",
      class: "shrinked",
      style: "min-width: 130px",
    },
    { name: "name", text: "Name", class: "capitalized" },
    { name: "manual_review", text: "Manual Review" },
    { name: "errors", text: "Errors" },
    { name: "updated_at", text: "Updated Date", class: "text-right" },
  ];

  resourceDetail: any = { id: null, files: null, data: null };
  showResourceDetail = false;
  loadingResourcePictures = false;
  only_manual_review = "0";
  searchParamsProp = new URLSearchParams();
  searchParamsPropString = "";
  processingAttempts: any[] = [];
  nextProcessingAttempt = {};
  previousProcessingAttempt = {};
  userDetail: any = null;

  VerificationsMap = VerificationsMap;

  @Watch("showResourceDetail")
  closeResourceModal() {
    if (!this.showResourceDetail)
      this.$router.push({ name: "processing-attempts" });
  }

  getUniqueErrorsArrayFromCoreModules(cm_exec: Array<string>) {
    return getUniqueErrorsArrayFromCoreModules(cm_exec);
  }

  switchManualReview() {
    if (this.only_manual_review == "0") {
      this.only_manual_review = "1";
    } else {
      this.only_manual_review = "0";
    }
    this.searchParamsProp.set("only_manual_review", this.only_manual_review);
    this.searchParamsPropString = this.searchParamsProp.toString();
  }

  async getProcessingAttempts(params: URLSearchParams) {
    const pas = await ProcessingAttemptsService.getProcessingAttempts(params);
    this.processingAttempts = pas.results;
    return pas;
  }

  async getUser(userid: string) {
    const user = await UsersService.getUserStatus(userid);
    return user.data;
  }

  async showAndGetResourceDetail(resource: any) {
    this.resourceDetail = resource;
    this.loadingResourcePictures = true;
    if (
      this.$store.state.companyAppId !== "all" &&
      this.$store.state.verificationFlow &&
      this.$store.state.verificationFlow.flow_type !== "CDP"
    ) {
      this.userDetail = await this.getUser(this.resourceDetail.owner_user.id);
    } else {
      if (this.resourceDetail && this.resourceDetail.owner_user)
        this.userDetail = {
          first_name: this.resourceDetail.owner_user.first_name,
          last_name: this.resourceDetail.owner_user.last_name,
        };
    }
    if (
      this.userDetail &&
      this.userDetail.processing_attempts &&
      this.userDetail.processing_attempts.length
    ) {
      this.resourceDetail = this.userDetail.processing_attempts.find(
        (processingAttept: any) =>
          processingAttept.processing_attempt_id === this.resourceDetail.id
      );
    }
    this.loadingResourcePictures = false;

    if (
      (this.resourceDetail && this.resourceDetail.processing_attempt_id) ||
      (this.resourceDetail &&
        this.resourceDetail.id !== this.$route.params.paid)
    ) {
      this.$router.push({
        name: "processing-attempts-detail",
        params: {
          paid: this.resourceDetail.processing_attempt_id
            ? this.resourceDetail.processing_attempt_id
            : this.resourceDetail.id,
        },
      });
    }
  }

  async getProcessingAttempt(paid: string) {
    const pa = await ProcessingAttemptsService.getProcessingAttemptFromId(paid);
    return pa;
  }

  async updateResource(resource: any) {
    const res = await ResourcesService.patchResourceStatus(
      resource.id,
      resource.status
    );
    resource.manual_review_status = res.data.manual_review_status;
    return res;
  }

  async mounted() {
    if (this.$store.state.companyAppId !== "all") {
      this.searchParamsProp.set(
        "company_app_id",
        this.$store.state.companyAppId
      );
      this.searchParamsPropString = this.searchParamsProp.toString();
    }
    if (this.$route.params.paid) {
      const pa = await this.getProcessingAttempt(this.$route.params.paid);
      this.showAndGetResourceDetail(pa);
    }
  }
}
