var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.KYBCompanyData.admin ||
      (_vm.KYBCompanyData.ubo_list && _vm.KYBCompanyData.ubo_list.length)
    )?_c('div',{ref:"main-branch",staticClass:"ubo-diagram context"},[_c('canvas',{ref:"diagram-canvas",staticClass:"diagram-canvas"}),_c('div',{staticClass:"ubo-node-wrapper"},[(_vm.KYBCompanyData)?_c('div',{ref:"sourceNode",staticClass:"ubo-node-label ubo-node-label-ubo-diagram",class:[
          _vm.KYBCompanyData.ignored
            ? 'BLOCKED'
            : _vm.KYBCompanyData.status === 'PENDING'
            ? 'IN_PROGRESS'
            : _vm.KYBCompanyData.status ],on:{"click":function($event){$event.stopPropagation();return _vm.$emit('unfocus')}}},[_c('i',{staticClass:"ubo-node-appended-icon",class:_vm.statusClass}),_c('span',[(
              (_vm.KYBCompanyData.details && _vm.KYBCompanyData.details.legal_name) ||
              (_vm.KYBCompanyData.details && _vm.KYBCompanyData.details.social_name)
            )?[_vm._v(" "+_vm._s(_vm._f("clean_cap")(_vm.KYBCompanyData.details.legal_name ? _vm.KYBCompanyData.details.legal_name : _vm.KYBCompanyData.details.social_name))+" ")]:_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"branch"},[(_vm.KYBCompanyData.admin)?[(
            !_vm.focusNode ||
            (_vm.KYBCompanyData.admin &&
              _vm.KYBCompanyData.admin &&
              _vm.focusNode === _vm.KYBCompanyData.admin.id)
          )?_c('UBODiagramNode',{ref:"tree-node-admin",class:{ active: _vm.focusNode === _vm.KYBCompanyData.admin.id },attrs:{"uboData":_vm.KYBCompanyData.admin,"isAdmin":true,"countriesList":_vm.countriesList,"type":"individual","focusNode":_vm.focusNode},on:{"showUBODetail":function($event){return _vm.$emit('showUBODetail', $event)}}}):_vm._e()]:_vm._e(),_vm._l((_vm.KYBCompanyData.ubo_list),function(ubo){return [(
            _vm.isUboInAnyChild(ubo, _vm.focusNode) ||
            !_vm.focusNode ||
            (ubo.admin && ubo.admin && _vm.focusNode === ubo.admin.id)
          )?_c('UBODiagramNode',{key:ubo.id,ref:"tree-node",refInFor:true,class:{
            active: _vm.focusNode === ubo.id || _vm.focusNode === ubo.id,
          },attrs:{"uboData":ubo,"countriesList":_vm.countriesList,"focusNode":_vm.focusNode},on:{"showUBODetail":function($event){return _vm.$emit('showUBODetail', $event)}}}):_vm._e()]})],2)]):_c('div',{staticClass:"context"},[_vm._v(" "+_vm._s(_vm.isScreeningKYBCompany ? _vm.$t("kyb_company_screen_blocked_default_description") : _vm.$t("kyb_company_not_filled_by_contact"))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }