
























































































import {
  priceAML,
  pricePEP,
  priceTableAMLPEP,
  priceTableKYB,
  priceTableKYC,
  rangeTableAMLPEP,
  rangeTableKYB,
  rangeTableKYC,
} from "@/lib/prices";
import Vue from "vue";
import VTab from "@/components/vuemarc-ui-kit/tabs/VTab.vue";

export default Vue.extend({
  name: "RulesList",
  components: { VTab },
  props: {
    hasKYC: { default: false },
    hasAML: { default: false },
    hasPEP: { default: false },
  },
  data() {
    return {
      priceEstimateVerifications: null,
      priceTableKYB: priceTableKYB,
      priceTableKYC: priceTableKYC,
      priceTableAMLPEP: priceTableAMLPEP,
      rangeTableKYB: rangeTableKYB,
      rangeTableKYC: rangeTableKYC,
      rangeTableAMLPEP: rangeTableAMLPEP,
      priceSplit: { kyc: 0, aml: 0, pep: 0, kyb: 0 },
    };
  },
  computed: {
    totalPrice(): { min: number; max: number } {
      let maxPrice = 0;
      let minPrice = 0;
      if (this.hasKYC) {
        maxPrice += priceTableKYC[1];
        minPrice += priceTableKYC[priceTableKYC.length - 1];
      }
      if (this.hasAML) {
        maxPrice += priceTableAMLPEP[0];
        minPrice += priceTableAMLPEP[priceTableAMLPEP.length - 1];
      }
      if (this.hasPEP) {
        maxPrice += priceTableAMLPEP[0];
        minPrice += priceTableAMLPEP[priceTableAMLPEP.length - 1];
      }
      return { max: maxPrice, min: minPrice };
    },

    priceEstimateCost(): number {
      if (!this.priceEstimateVerifications) {
        this.priceSplit = {
          aml: 0,
          kyb: 0,
          kyc: 0,
          pep: 0,
        };
        return 0;
      }
      let totalPrice = 0;
      const priceEstimate = this.priceEstimateVerifications || 0;
      this.priceSplit = { kyc: 0, aml: 0, pep: 0, kyb: 0 };
      if (this.hasKYC) {
        for (let i in priceTableKYC) {
          if (rangeTableKYC[i] && rangeTableKYC[i] < priceEstimate) {
            continue;
          }
          this.priceSplit.kyc = priceTableKYC[i] * priceEstimate;
          if (this.priceSplit.kyc === 0 || this.priceSplit.kyc < 99)
            this.priceSplit.kyc = 99;
          break;
        }
      }
      if (this.hasAML) {
        for (let i in priceTableAMLPEP) {
          if (rangeTableAMLPEP[i] && rangeTableAMLPEP[i] < priceEstimate) {
            continue;
          }
          this.priceSplit.aml += priceTableAMLPEP[i] * priceEstimate;
          break;
        }
      }
      if (this.hasPEP) {
        for (let i in priceTableAMLPEP) {
          if (rangeTableAMLPEP[i] && rangeTableAMLPEP[i] < priceEstimate) {
            continue;
          }
          this.priceSplit.pep += priceTableAMLPEP[i] * priceEstimate;
          break;
        }
      }
      totalPrice =
        this.priceSplit.kyc + this.priceSplit.aml + this.priceSplit.pep;
      return totalPrice;
    },
  },
});
