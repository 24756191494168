











































































































































































































































































































import Vue from "vue";
import VTab from "@/components/vuemarc-ui-kit/tabs/VTab.vue";
import AppDetail from "./AppDetail/AppDetail.vue";
import CompaniesService from "@/api/companies/companiesService";
import { mutationsT } from "@/state/mutations";
import KYBFormModal from "@/components/kyb/KYBFormModal.vue";
import { filteredSyncVerificationsAndChecks } from "@/lib/parsers";
import VToast from "@/components/vuemarc-ui-kit/toast/VToast.vue";
import OnelineVerifications from "@/components/OnelineVerifications.vue";
import { handleBeforeUnload } from "@/lib/utils";

export interface ActiveVerificationFlow {
  alias: string;
  api_token: string;
  app_id: string;
  company_name: string;
  created_at: string;
  logo: string;
  name: string;
  permissions: Array<any>;
  status_webhook_url: string;
  updated_at: string;
  verification_type: string;
  webhook_url: string;
  flow_type: string;
}

export default Vue.extend({
  name: "VerificationFlowSettings",
  components: { VTab, AppDetail, KYBFormModal, VToast, OnelineVerifications },
  data() {
    return {
      activeTabName: "verification-flow-info",
      loading: false,
      signup_url: process.env.VUE_APP_SILT_SIGNUP_URL,
      copiedHash: {} as any,
      showKYBFormModal: false,
      differences: {
        modified: {},
        original: {},
      } as any,
      showDifferencesList: false,
      verificationFlowUpdated: {} as ActiveVerificationFlow,
      filteredSyncVerificationsAndChecks: filteredSyncVerificationsAndChecks,
    };
  },
  watch: {
    verificationFlowUpdated: {
      handler() {
        this.differences = this.compareObjects(
          this.$store.state.verificationFlow,
          this.verificationFlowUpdated
        );
      },
      deep: true,
    },
    differences: {
      handler() {
        if (Object.keys(this.differences.modified).length)
          window.addEventListener("beforeunload", handleBeforeUnload);
        else window.removeEventListener("beforeunload", handleBeforeUnload);
      },
      deep: true,
    },
  },
  computed: {
    activeVerificationFlow(): ActiveVerificationFlow {
      return JSON.parse(JSON.stringify(this.$store.state.verificationFlow));
    },
  },
  methods: {
    resetDifferences() {
      this.differences = {
        modified: {},
        original: {},
      };
    },
    openKYCVerificationLink() {
      const url = `${this.signup_url}/?company_app_id=${this.activeVerificationFlow.app_id}&hide_redirect_button=true`;
      window.open(url, "_blank");
    },
    async refreshCompanies(loading?: boolean) {
      loading = true;
      this.getCompanyInfo();
      loading = false;
    },
    async getCompanyInfo() {
      const res = await CompaniesService.getCompany(
        this.$store.state.company?.id
      );
      this.$store.commit(mutationsT.UPDATE_COMPANY, res.data);
    },
    copy(text: string) {
      navigator.clipboard.writeText(text).then(() => {
        this.copiedHash[text] = true;
      });
    },
    onOpenKYBModal() {
      this.showKYBFormModal = true;
    },
    compareObjects(originalObject: any, modifiedObject: any) {
      const modifiedProperties = {} as any;
      const originalProperties = {} as any;

      const compareArrays = (
        originalArray: any[],
        modifiedArray: any[],
        path: string
      ) => {
        if (originalArray.length !== modifiedArray.length) {
          modifiedProperties[path] =
            modifiedArray && modifiedArray.length
              ? modifiedArray
              : this.$t("element_removed");
          originalProperties[path] =
            originalArray && originalArray.length
              ? originalArray
              : this.$t("element_removed");
          return;
        }

        for (let i = 0; i < originalArray.length; i++) {
          const originalItem = originalArray[i];
          const modifiedItem = modifiedArray[i];
          if (JSON.stringify(originalItem) !== JSON.stringify(modifiedItem)) {
            if (path.includes("permissions")) {
              modifiedProperties[path] = modifiedItem;
              originalProperties[path] = originalItem;
            } else compare(originalItem, modifiedItem);
          }
        }
      };

      const compare = (original: any, modified: any, path = "") => {
        for (let key in original) {
          if (original.hasOwnProperty(key)) {
            const originalValue = original[key];
            const modifiedValue = modified[key];

            if (Array.isArray(originalValue) && Array.isArray(modifiedValue)) {
              compareArrays(originalValue, modifiedValue, `${path}.${key}`);
            } else if (
              typeof originalValue === "object" &&
              typeof modifiedValue === "object"
            ) {
              compare(originalValue, modifiedValue, `${path}.${key}`);
            } else {
              if (
                modified.hasOwnProperty(key) &&
                originalValue !== modifiedValue
              ) {
                modifiedProperties[`${path}.${key}`] = modifiedValue;
                originalProperties[`${path}.${key}`] = originalValue;
              }
            }
          }
        }
      };

      const removeDots = (obj: any) => {
        const newObj = {} as any;
        for (let key in obj) {
          const lastIndex = key.lastIndexOf(".");
          let newKey =
            lastIndex !== -1
              ? key.slice(lastIndex + 1).replace(/\./g, "")
              : key.replace(/\./g, "");
          if (newKey.includes("admin_permissions"))
            newKey = "representative_verification";
          if (newKey.includes("permissions")) newKey = "requirements";
          let value =
            typeof obj[key] === "boolean"
              ? obj[key]
                ? this.$t("enabled")
                : this.$t("disabled")
              : obj[key];
          if (
            typeof value === "string" &&
            (value.toLowerCase() === "enabled" ||
              value.toLowerCase() === "disabled")
          ) {
            newKey = newKey.replace(/(_Enabled)/i, "");
          }
          if (newKey === "ubo_min_ownership_percentage") {
            value = value * 100 + "%";
          }
          newObj[newKey] = value;
        }
        return newObj;
      };

      const originalObjectClean =
        this.cleanKYCRequirementsOfVFlowSettings(originalObject);
      const updatedObjectClean =
        this.cleanKYCRequirementsOfVFlowSettings(modifiedObject);

      compare(originalObjectClean, updatedObjectClean);

      return {
        modified: removeDots(modifiedProperties),
        original: removeDots(originalProperties),
      };
    },
    cleanKYCRequirementsOfVFlowSettings(config: any) {
      const configClean = JSON.parse(JSON.stringify(config));
      if (configClean.config) {
        configClean.config.admin_permissions =
          this.filteredSyncVerificationsAndChecks(
            configClean.config.admin_permissions
          );
        configClean.config.ubo_permissions =
          this.filteredSyncVerificationsAndChecks(
            configClean.config.ubo_permissions
          );
        configClean.permissions = this.filteredSyncVerificationsAndChecks(
          configClean.permissions
        );
      } else {
        configClean.permissions = this.filteredSyncVerificationsAndChecks(
          configClean.permissions
        );
      }
      return configClean;
    },
  },
});
