

















































































import DeleteVerificationFlowModal from "@/views/verification-flow-settings/DeleteVerificationFlowModal.vue";
import { ActiveVerificationFlow } from "@/views/verification-flow-settings/VerificationFlowSettings.vue";
import Vue from "vue";
import DuplicateVerificationFlowModal from "./DuplicateVerificationFlowModal.vue";
import SettingsVerificationFlowInfo from "./SettingsVerificationFlowInfo.vue";

export default Vue.extend({
  name: "SettingsVerificationFlowsList",
  components: {
    DeleteVerificationFlowModal,
    DuplicateVerificationFlowModal,
    SettingsVerificationFlowInfo,
  },
  props: {
    loading: { required: true, type: Boolean },
  },
  computed: {
    KYCFlows(): Array<ActiveVerificationFlow> {
      return this.$store.state.company.company_apps.filter(
        (verificationFlow: any) => verificationFlow.flow_type === "UDV"
      );
    },
    KYBFlows(): Array<ActiveVerificationFlow> {
      return this.$store.state.company.company_apps.filter(
        (verificationFlow: any) => verificationFlow.flow_type === "KYB"
      );
    },
    CDPFlows(): Array<ActiveVerificationFlow> {
      return this.$store.state.company.company_apps.filter(
        (verificationFlow: any) => verificationFlow.flow_type === "CDP"
      );
    },
  },
  data() {
    return {
      verificationFlowClicked: {},
      showDuplicateDetail: false,
      showDeleteDetail: false,
    };
  },
  methods: {
    duplicateOrDeleteVerificationFlow(
      verificationFlow: ActiveVerificationFlow,
      action: string
    ) {
      if (action === "duplicate") this.showDuplicateDetail = true;
      else this.showDeleteDetail = true;

      this.verificationFlowClicked =
        this.$store.state.company.company_apps.find(
          (capp: ActiveVerificationFlow) =>
            capp.app_id === verificationFlow.app_id
        );
    },
  },
});
