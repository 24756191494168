export const rangeTableKYC = [200, 1000, 5000, 10000];
export const rangeTableKYB = [500, 1000];
export const rangeTableAMLPEP = [200, 1000, 5000, 10000];

export const priceTableKYC = [0, 0.5, 0.4, 0.3, 0.2];
export const priceTableKYB = [2.75, 2.55, 2.35];
export const priceTableAMLPEP = [0.150, 0.05, 0.042, 0.036, 0.032];


export const priceAML = 0.25;
export const pricePEP = 0.25;
