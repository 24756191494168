






import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "KYBModalDetailHeader",
  props: {
    uboDetail: { required: true, type: Object },
  },
  computed: {
    detailType(): TranslateResult {
      return this.uboDetail &&
        this.uboDetail.type &&
        this.uboDetail.type.includes("ADMIN")
        ? this.$t("companies_detail_representative_detail_title", {
            RepresentativeName: this.fullName,
          })
        : this.$t("companies_detail_ubo_diagram_ubo_detail_title", {
            UBOname: this.fullName,
          });
    },
    fullName(): string {
      if (this.uboDetail && this.uboDetail.name) {
        return this.uboDetail.name.toLowerCase();
      } else if (
        this.uboDetail &&
        this.uboDetail.user &&
        this.uboDetail.user.first_name
      ) {
        let fullName = this.uboDetail.user.first_name;
        if (this.uboDetail.user.last_name) {
          fullName += " " + this.uboDetail.user.last_name;
        }
        return fullName.toLowerCase();
      } else {
        return "";
      }
    },
  },
});
