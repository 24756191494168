import Vue from "vue";
import * as Sentry from "@sentry/vue";

if (typeof process.env.VUE_APP_HOSTING_TARGET !== 'undefined' && process.env.VUE_APP_HOSTING_TARGET !== "local") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    logErrors: true,
    environment: process.env.VUE_APP_ENVIRONMENT,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay(),
    ],
  })
}