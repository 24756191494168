





















































import Vue from "vue";

export default Vue.extend({
  name: "KYBIgnoreStatus",
  props: {
    uboData: { required: true, type: Object },
    comesFromParentKYBCompany: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isIgnored(): boolean {
      if (this.uboData && "ignored" in this.uboData) {
        return this.uboData.ignored;
      }
      return false;
    },
  },
});
