





























import Vue from "vue";
import VTab from "@/components/vuemarc-ui-kit/tabs/VTab.vue";

export default Vue.extend({
  name: "HomeHowToCard",
  components: { VTab },
  data() {
    return {
      leftSection: [
        {
          title: this.$t("overview_how_to_getting_started_title"),
          description: this.$t("overview_how_to_getting_started_subtitle"),
          route: "how-to",
        },
        {
          title: this.$t("overview_how_to_integration_title"),
          description: this.$t("overview_how_to_integration_subtitle"),
          route: "integration",
        },
      ],
      rightSection: [
        {
          title: this.$t("overview_how_to_overview_title"),
          description: this.$t("overview_how_to_overview_subtitle"),
          route: "overview",
        },
        {
          title: this.$t("overview_how_to_settings_title"),
          description: this.$t("overview_how_to_settings_subtitle"),
          route: "settings-description",
        },
        {
          title: this.$t("overview_how_to_users_and_companies_title"),
          description: this.$t("overview_how_to_users_and_companies_subtitle"),
          route: "users-and-companies",
        },
        {
          title: this.$t("overview_how_to_rules_title"),
          description: this.$t("overview_how_to_rules_subtitle"),
          route: "rules-description",
        },
      ],
    };
  },
  methods: {},
});
