import { formatUri } from "@/api";
import { HTTP } from "@/lib/http/api";

export default class RulesService {
  
  static async postRule(
    companyAppId: string,
    fieldName: string,
    fieldEntity: string,
    operator: string,
    operatorValue: string,
    action: string,
    actionValue: string
  ) {
    const res = await HTTP.post(
      formatUri`v1/rules/`,
      {
        company_app_id: companyAppId,
        field_name: fieldName,
        field_entity: fieldEntity,
        operator: operator,
        operator_value: operatorValue,
        action: action,
        action_value: actionValue
      }
    );
    return res;
  }

  static async getRules(
    companyAppId: string,
  ) {
    const res = await HTTP.get(
      formatUri`v1/company-apps/${companyAppId}/rules/`,
    );
    return res;
  }

  static async getRuleHits(
    companyAppId: string,
    ruleId:string
  ) {
    const res = await HTTP.get(
      formatUri`v1/company-apps/${companyAppId}/rules/${ruleId}/hits/`,
    );
    return res;
  }

  static async enableRule(
    companyAppId: string,
    ruleId:string
  ) {
    const res = await HTTP.patch(
      formatUri`v1/company-apps/${companyAppId}/rules/${ruleId}/enable/`,
    );
    return res;
  }

  static async disableRule(
    companyAppId: string,
    ruleId:string
  ) {
    const res = await HTTP.patch(
      formatUri`v1/company-apps/${companyAppId}/rules/${ruleId}/disable/`,
    );
    return res;
  }
}
