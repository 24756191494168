






































































import Vue from "vue";
import KYBFormModal from "@/components/kyb/KYBFormModal.vue";
import KYBModal from "./components/KYBModal.vue";
import KYBService from "@/api/kyb/kybService";
import {
  VTableHeader,
  VTableInstance,
} from "@/components/vuemarc-ui-kit/tables/VTable.vue";
import { COMPANY_STATUSES } from "@/models/verificationStatus";

export default Vue.extend({
  components: { KYBFormModal, KYBModal },
  name: "KYBCompaniesList",
  data() {
    return {
      showKYBFormModal: false,
      showKYBModal: false,
      loading: false,
      KYBCompanyDetail: {} as any,
      headers: [
        {
          name: "status",
          text: "status_title",
          style: "max-width: 180px",
        },
        {
          name: "company_name",
          text: "companies_company_name_title",
        },
        {
          name: "contact_email",
          text: "companies_contact_email_title",
        },
        {
          name: "created_at",
          text: "created_date_title",
          class: "text-right shrinked",
        },
      ] as VTableHeader[],
      searchParamsPropString: "",
      filterOptions: COMPANY_STATUSES,
      KYBCompanyDetailSanctions: {} as any,
      KYBCompanyDetailAdverseMedia: {} as any,
    };
  },
  computed: {
    isKYBFlow() {
      return this.$store.state.verificationFlow.flow_type === "KYB";
    },
  },
  methods: {
    onOpenKYBFormModal() {
      this.showKYBFormModal = true;
    },
    handleKYBFormModalEvent(event: boolean) {
      this.showKYBFormModal = event;
      if (!event) {
        const kybTableRef = this.$refs.KYBTable as VTableInstance;
        kybTableRef.getRows();
      }
    },
    getKYBCompanyPrefilled(KYBCompanyId: any) {
      this.KYBCompanyDetail =
        this.$refs.KYBTable &&
        (this.$refs.KYBTable as VTableInstance).rowsData.results.find(
          (KYBCompany: any) => {
            if (KYBCompany.id) {
              return KYBCompany.id === KYBCompanyId;
            }
          }
        );
      return {};
    },
    async getSanctionsAndAdverseMedia(KYBCompanyId: string) {
      try {
        if (
          this.$store.state.verificationFlow.config?.sanctions_screening_enabled
        ) {
          const KYBCompanyDetailSanctionsResponse =
            await KYBService.getKYBCompanySanctions(KYBCompanyId);
          this.KYBCompanyDetailSanctions =
            KYBCompanyDetailSanctionsResponse.data[0] ?? {};
        }

        if (
          this.$store.state.verificationFlow.config
            ?.adverse_media_screening_enabled
        ) {
          const KYBCompanyDetailAdverseMediaResponse =
            await KYBService.getKYBCompanyAdverseMedia(KYBCompanyId);

          this.KYBCompanyDetailAdverseMedia =
            KYBCompanyDetailAdverseMediaResponse.data[0] ?? {};
        }
      } catch (error) {
        this.$root.$emit("v-toast", {
          localizedKey: "unsucessful_feedback",
          type: "error-toast",
        });
      }
    },
    async showAndGetKYBCompany(KYBCompanyId: string) {
      if (KYBCompanyId) {
        this.getKYBCompanyPrefilled(KYBCompanyId);
        this.showKYBModal = true;
        this.loading = true;
        try {
          const currentKYBCompany = await KYBService.getKYBCompany(
            KYBCompanyId
          );
          currentKYBCompany.data.sanctions = this.KYBCompanyDetail.sanctions;
          currentKYBCompany.data.adverse_media =
            this.KYBCompanyDetail.adverse_media;
          this.KYBCompanyDetail = currentKYBCompany.data;
          if (KYBCompanyId && KYBCompanyId !== this.$route.params.companyId) {
            this.$router.push({
              name: "companies-detail",
              params: { companyId: KYBCompanyId },
            });
          }
          this.getSanctionsAndAdverseMedia(KYBCompanyId);
        } catch (error) {
          this.$root.$emit("v-toast", {
            localizedKey: "unsucessful_feedback",
            type: "error-toast",
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async getKYBCompanies(params?: URLSearchParams) {
      if (!this.isKYBFlow) return;
      const KYBCompaniesList = await KYBService.getKYBCompanies(
        this.$store.state.companyAppId,
        params
      );

      return KYBCompaniesList;
    },
  },
  async mounted() {
    if (this.$route.params.companyId) {
      this.KYBCompanyDetail = await KYBService.getKYBCompany(
        this.$route.params.companyId
      );
      await this.showAndGetKYBCompany(this.$route.params.companyId);
    }
  },
});
