













import Vue from "vue";

export default Vue.extend({
  name: "HowToIntegration",
  data() {
    return {};
  },
  methods: {
    resizeIframe() {
      setTimeout(() => {
        const iframe: any = document.getElementById("iframe");
        if (iframe) {
          const height = iframe.contentWindow.document.body.scrollHeight;
          iframe.style.height = height + "px";
        }
      }, 100);
    },
  },
});
