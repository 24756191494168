import { render, staticRenderFns } from "./SettingsVerificationFlowsList.vue?vue&type=template&id=c766e4cc&scoped=true&"
import script from "./SettingsVerificationFlowsList.vue?vue&type=script&lang=ts&"
export * from "./SettingsVerificationFlowsList.vue?vue&type=script&lang=ts&"
import style0 from "./SettingsVerificationFlowsList.vue?vue&type=style&index=0&id=c766e4cc&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c766e4cc",
  null
  
)

export default component.exports