






































































import Vue from "vue";

export default Vue.extend({
  name: "KYBRepresentativesList",
  props: {
    KYBCompanyPrincipals: {
      required: true,
      type: Array as () => Array<any>,
    },
    cmes: {
      required: false,
      type: Array as () => Array<any>,
    },
  },
  components: {},
  methods: {},
  computed: {},
  data() {
    return {};
  },
});
