
































import { Component, Mixins } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";

@Component({ inheritAttrs: false })
export default class VInput extends Mixins(VInputMixin) {
  inputWidth = 0;
  get expand(): boolean {
    if (this.label && this.value) return this.label.length > 25;
    if (this.value) return this.value.length > 30;
    if (!this.inputWidth) return false;
    return this.inputWidth <= 270;
  }

  getInputWidth() {
    const inputContainer = this.$refs.inputContainer as HTMLElement;
    if (inputContainer) {
      this.inputWidth = inputContainer.clientWidth;
    }
  }

  mounted() {
    this.getInputWidth();
  }
}
