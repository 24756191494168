








import Vue from "vue";

export default Vue.extend({
  name: "HowToRules",
  data() {
    return {};
  },
  methods: {},
});
