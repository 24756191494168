


























import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class VModal extends Vue {
  close() {
    this.$emit("close");
  }
  created() {
    document.addEventListener("keyup", (evt) => {
      if (evt.key === "Escape") {
        this.$emit("close");
      }
    });
  }
}
