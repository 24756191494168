import { mutationsT } from "./mutations";
import store from "./store";

export const actionsT = {
  FETCH_COMPANY: "FETCH_COMPANY",
  FETCH_USER: "FETCH_USER",
  FETCH_ALL: "FETCH_ALL",
  FETCH_VERIFICATION_FLOW: "FETCH_VERIFICATION_FLOW"
};

export const actions = {
  async FETCH_ALL({ commit, state }: any) {
    if (!state.user) commit(mutationsT.UPDATE_LOADING, true);
    await store.dispatch(actionsT.FETCH_COMPANY)
    if (!state.user) await store.dispatch(actionsT.FETCH_USER)
    await store.dispatch(actionsT.FETCH_VERIFICATION_FLOW)
  }
};
