























import Vue from "vue";
import KYBRecursiveList from "./KYBRecursiveList.vue";

export default Vue.extend({
  components: { KYBRecursiveList },
  name: "KYBExtraDocumentsPDFDetail",
  props: {
    pdfUrl: { required: true, type: String, default: "" },
    extraDocument: { required: true, type: Object },
  },
  data() {
    return {};
  },
  watch: {},
  methods: {},
  mounted() {},
});
