



























































import { Component, Vue, Prop } from "vue-property-decorator";
import AuthApi from "@/api/auth/authApi";
import { emailErrors } from "@/lib/validators";
import { TranslateResult } from "vue-i18n";

@Component({})
export default class RecoverPassword extends Vue {
  email = "";
  password = "";
  loading = false;
  responseMessage: string | TranslateResult = "";
  responseMessageTranslated = "";
  submitted = false;
  @Prop()
  emailProp?: string;

  get emailErrors() {
    return emailErrors(this.email, this);
  }

  async recoverPassword() {
    if (!this.email) {
      this.responseMessage = this.$t("log_in_password_forgotten_invalid_email");
      return;
    } else if (this.emailErrors) {
      this.responseMessage = this.emailErrors;
      return false;
    } else {
      this.responseMessage = "";
    }
    this.loading = true;
    try {
      this.submitted = false;
      await AuthApi.recoverPassword({
        email: this.email,
        redirect_url: `${process.env.VUE_APP_SILT_DASHBOARD_URL}`,
      });
      this.submitted = true;
    } catch (error) {
      if (error.response && error.response.data.error === "invalid_grant") {
        this.responseMessage = "";
        this.responseMessageTranslated = "invalid_grant";
      } else {
        this.responseMessageTranslated = "";
        this.responseMessage =
          (error.response && error.response.data.error_description) ||
          error.message;
      }
    }
    this.loading = false;
  }

  mounted() {
    if (this.emailProp) this.email = this.emailProp;
  }
}
