










































































import Vue from "vue";
import { VTableHeader } from "@/components/vuemarc-ui-kit/tables/VTable.vue";
import MisconductListService from "@/api/misconductList/misconductListService";
import ResourcesService from "@/api/resources/resourcesService";
import UsersService from "@/api/users/usersService";
import UserModal from "@/components/users/UserModal.vue";
import { sendSentryError } from "@/lib/utils";

interface IUserDetail {
  id: string;
  processing_attempts: Array<any>;
}

export default Vue.extend({
  name: "MisconductList",
  components: { UserModal },
  data() {
    return {
      headers: [
        {
          name: "severity",
          text: "misconduct_list_misconduct_severity_title",
        },
        {
          name: "user_first_name_and_last_name",
          text: "misconduct_list_name_&_surname_title",
          style: "flex: 1 1 50%",
        },
        {
          name: "type",
          text: "misconduct_list_misconduct_type_title",
          class: "tooltip",
          style: "flex: 2 1 70%",
        },
        {
          name: "description",
          text: "misconduct_list_description_title",
          class: "tooltip shrinked",
          style: "flex: 2 1 60%",
        },
      ] as VTableHeader[],

      userDetail: {} as IUserDetail,
      resourceDetail: {} as any,
      showResourceDetail: false,
      popoverPlacement: "top-start",
      showDescription: false,
      misconductId: "",
      users: [] as any[],
      loading: false,
    };
  },
  methods: {
    async getMisconducts() {
      const misconductListUpdated = {
        results: [],
      };

      const companyId = this.$store.state.company?.id;
      if (!companyId) {
        sendSentryError("MisconductList: Company id is undefined");
        return;
      }

      const res = await MisconductListService.getMisconducts(companyId);

      for (let misconduct of res.data) {
        if (misconduct.severity === "1") {
          misconduct.severity = this.$t(
            "misconduct_list_misconduct_severity_type_minor"
          );
        } else if (misconduct.severity === "2") {
          misconduct.severity = this.$t(
            "misconduct_list_misconduct_severity_type_medium"
          );
        } else if (misconduct.severity === "3") {
          misconduct.severity = this.$t(
            "misconduct_list_misconduct_severity_type_major"
          );
        }
      }

      misconductListUpdated.results = res.data;
      this.users = misconductListUpdated.results;

      return misconductListUpdated;
    },

    getSeverityClass(severityValue: string) {
      if (severityValue === "Medium") {
        return "pill-warning";
      } else if (severityValue === "Major") {
        return "pill-error";
      } else {
        return "pill-grey";
      }
    },

    async getUser(userid: string) {
      const user = await UsersService.getUserStatus(userid);

      return user.data;
    },

    async updateResource(resource: any) {
      const res = await ResourcesService.patchResourceStatus(
        resource.id,
        resource.status
      );

      resource.status = res.data.status;
      resource.manual_review_status = res.data.manual_review_status;

      return res;
    },

    async showAndGetResourceDetail(userId: string) {
      if (this.userDetail.id === userId) {
        this.showResourceDetail = true;
        return;
      }
      this.loading = true;

      this.userDetail = await this.getUser(userId);

      this.resourceDetail =
        this.userDetail.processing_attempts &&
        this.userDetail.processing_attempts[0];

      this.showResourceDetail = true;

      this.loading = false;

      this.$root.$emit("amplitude", "MisconductList - User Clicked");

      if (userId && userId != this.$route.params.userId) {
        this.$router.push({
          name: "misconduct-list-user-detail",
          params: { userId },
        });
      }
    },
  },
  mounted: async function () {
    if (this.$route.params.userId) {
      this.showAndGetResourceDetail(this.$route.params.userId);
    }
  },
});
