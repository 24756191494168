









































export default {
  name: "KYBRecursiveList",
  props: {
    output: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      collapsedSections: [] as number[],
    };
  },
  methods: {
    isObject(value: any) {
      return typeof value === "object" && value !== null;
    },
    toggleCollapse(index: number) {
      const self = this as any;
      const updatedSections: number[] = [...self.collapsedSections];

      if (updatedSections.includes(index)) {
        const indexToRemove = updatedSections.indexOf(index);
        updatedSections.splice(indexToRemove, 1);
      } else {
        updatedSections.push(index);
      }

      self.collapsedSections = updatedSections;
    },
  },
};
