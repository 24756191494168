











































































































import VPlaceholder from "@/components/vuemarc-ui-kit/placeholder/VPlaceholder.vue";
import Vue from "vue";

export default Vue.extend({
  components: { VPlaceholder },
  name: "SettingsVerificationFlowInfo",
  props: {
    verificationFlow: { required: true, type: Object },
    loading: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      signup_url: process.env.VUE_APP_SILT_SIGNUP_URL,
      copiedHash: {} as any,
    };
  },
  methods: {
    copy(text: string) {
      navigator.clipboard.writeText(text).then(() => {
        this.copiedHash[text] = true;
      });
    },
    openKYCVerificationLink() {
      const url = `${this.signup_url}/?company_app_id=${this.verificationFlow.app_id}&hide_redirect_button=true`;
      window.open(url, "_blank");
    },
  },
});
