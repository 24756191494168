




































import FilesService from "@/api/files/filesService";
import ProcessingAttemptsService from "@/api/processingAttempts/processingAttemptsService";
import Vue from "vue";
import SuccessOverlay from "../SuccessOverlay.vue";

export default Vue.extend({
  name: "CDPDashboardUpload",
  components: { SuccessOverlay },
  props: { showUploadDocument: { required: true, type: Boolean } },
  data() {
    return {
      loading: false,
      showSuccess: false,
    };
  },
  methods: {
    handleError() {
      this.$root.$emit("v-toast", {
        localizedKey: "unsucessful_feedback",
        type: "error-toast",
      });
    },
    async postFile(file: any) {
      const data = {
        type: "cdp_expense",
        file_name: file.name,
        file: file,
        multipart: true,
      };
      return await FilesService.postFile(data);
    },
    async postCDPProcessingAttempt(id: string) {
      this.loading = true;
      const parsedData = {
        files: [`${id}`],
        type: "COMPLEX_DOCUMENT_PROCESSING",
        permission_type: "COMPLEX_DOCUMENT_PROCESSING",
      };
      try {
        const res = await ProcessingAttemptsService.postProcessingAttempt(
          parsedData
        );
        this.showSuccess = true;
        this.$root.$emit("cdpProcessingAttemptUploadedSuccessfully");
        return res;
      } catch (error) {
        this.handleError();
      } finally {
        this.loading = false;
      }
    },
    async uploadFiles(file: any) {
      if (!Array.isArray(file)) file = [file.file];
      try {
        this.loading = true;

        const postFileResponse = await this.postFile(file[0]);
        await FilesService.closeMultipartFile(
          postFileResponse.data.file.parent_id
        );
        // await FilesService.getFile(postFileResponse.data.file_id); <-- Why is this needed?
        await this.postCDPProcessingAttempt(postFileResponse.data.file.parent_id);
      } catch (error) {
        this.handleError();
      } finally {
        this.loading = false;
      }
    },
    onCloseCDPDashboardUpload() {
      this.$emit("showUploadDocument", false);
      if (
        this.$router.currentRoute.name !== "complex-document-processor" &&
        this.showSuccess
      )
        this.$router.push({ name: "complex-document-processor" });

      this.showSuccess = false;
    },
  },
});
