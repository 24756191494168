



import Vue from "vue";

export default Vue.extend({
  name: "v-rule-one-liner",
  props: {
    rule: { required: true },
  },
  methods: {
    getOneLiner(rule: any): string {
      if (!rule || !rule.field || rule.field.name !== "COUNTRY") {
        return "";
      }

      const { operator, operator_value, action, action_value } = rule;

      const fieldTypeSessionCountry = this.$t(
        "rules_create_new_rule_field_type_session_country"
      );
      const inListMessage = this.$t(
        "rules_create_new_rule_operator_type_in_list"
      );
      const notInListMessage = this.$t(
        "rules_create_new_rule_operator_type_not_in_list"
      );
      const changeStatusAction = this.$t(
        "rules_create_new_rule_action_type_change_status"
      );
      const manualReviewStatus = this.$t("status_type_manual_review");

      const operatorType =
        operator !== "NOT_IN_LIST" ? inListMessage : notInListMessage;
      const actionType = action === "SET_PA_STATUS" ? changeStatusAction : "";
      const actionValue = action_value === "VE" ? manualReviewStatus : "";

      return `${this.$t(
        "rules_create_new_rule_if"
      )} ${fieldTypeSessionCountry} ${operatorType} ${
        operator_value ? operator_value : "..."
      } ${actionType} ${actionValue}`;
    },
  },
});
